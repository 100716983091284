<template lang="pug">
  .cp-unit-attribute-group-filter-label
    | {{prepend}}{{getLabel}}{{append}}
</template>

<style lang="sass" scoped>
</style>

<script>
import {FILTER_TYPE} from '@inreal/cockpit-library/src/js/enums';
import {identity} from '@inreal/core-library/src/js/functions';
import {createGsapNumberAnimator} from '@inreal/ui/src/js/animation/numbers';

export default {
  name: 'CpUnitAttributeGroupFilterLabel',
  components: {},
  props: {
    filterType: {
      type: String,
      required: true,
    },
    filterValue: {
      type: [Number, Array],
      required: true,
    },
    animationDuration: {
      type: Number,
      default: .25,
    },
    numberFormatter: {
      type: Function,
      default: identity,
    },
    separator: {
      type: String,
      default: ' - ',
    },
    logicalLabelProvider: {
      type: Function,
      default: null,
    },
    append: {
      type: String,
      default: '',
    },
    prepend: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      animator: null,
    };
  },
  computed: {
    getLabel () {
      switch (this.filterType) {
        case FILTER_TYPE.LOGICAL: {
          return this.logicalLabelProvider();
        }

        case FILTER_TYPE.NUMERICAL: {
          return `${this.numberFormatter(this.animator.animatedValue)}`;
        }

        case FILTER_TYPE.NUMERICAL_RANGE: {
          const fromValue = this.animator[0].animatedValue;
          const toValue = this.animator[1].animatedValue;

          return `${this.numberFormatter(fromValue)}${this.separator}${this.numberFormatter(toValue)}`;
        }

        default: {
          return '';
        }
      }
    },
  },
  watch: {
    filterValue: {
      deep: true,
      handler () {
        this.$_updateAnimations();
      },
    },
  },
  beforeMount () {
    if (this.filterType === FILTER_TYPE.NUMERICAL) {
      this.animator = createGsapNumberAnimator(0, this.animationDuration);
    } else if (this.filterType === FILTER_TYPE.NUMERICAL_RANGE) {
      this.animator = [
        createGsapNumberAnimator(0, this.animationDuration),
        createGsapNumberAnimator(0, this.animationDuration),
      ];
    }

    this.$_updateAnimations();
  },
  beforeDestroy () {
    if (Array.isArray(this.animator)) {
      this.animator.forEach(v => v.dispose());
    } else {
      this.animator?.dispose();
    }
  },
  methods: {
    $_updateAnimations () {
      if (this.filterType === FILTER_TYPE.NUMERICAL) {
        this.animator.to(this.filterValue);
      } else if (this.filterType === FILTER_TYPE.NUMERICAL_RANGE) {
        this.animator[0].to(this.filterValue[0]);
        this.animator[1].to(this.filterValue[1]);
      }
    },
  },
};
</script>
