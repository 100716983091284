<template lang="pug">
  component.cp-config-bar(
    :is="button ? 'button' : 'div'"
    :style="getStyles"
  )
    .inner-wrapper
      component.bar-start(
        :is="isStartButton ? 'button' : 'div'"
        :style="{'border-right-color': borderColor}"
        @click="$emit('click:start', $event)"
      )
        template(v-if="startIconSvg != null")
          CfMdiSvgIcon.icon(
            :class="{'rotate': startIconRotate}"
            :path="startIconSvg"
            :color="color"
            :size="startIconSize"
          )
        template(v-else)
          slot(name="start")
      .bar-center
        slot(name="center")
      component.bar-end(
        :is="isEndButton ? 'button' : 'div'"
        @click="$emit('click:end', $event)"
      )
        template(v-if="endIconSvg != null")
          CfMdiSvgIcon.icon(
            :class="{'rotate': endIconRotate}"
            :path="endIconSvg"
            :color="color"
            :size="endIconSize"
          )
        template(v-else)
          slot(name="end")
</template>

<style lang="sass" scoped>
$size: 46px

.inner-wrapper
  display: flex
  align-items: center
  height: $size

.bar-start,
.bar-end
  flex: none
  align-self: stretch

  width: $size
  overflow: hidden

  &:not(button)
    display: flex
    justify-content: center
    align-items: center

.bar-start
  box-sizing: content-box
  border-right-width: 1px
  border-right-style: solid

button.bar-start,
button.bar-end
  text-align: center

.bar-center
  margin-left: 12px
  margin-right: 12px
  font-size: 16px
  font-weight: normal
  letter-spacing: 0.02em
  text-transform: uppercase
  flex: 1 1 auto

button
  display: block
  width: 100%
  cursor: pointer

.icon
  display: inline-block
  transform: rotate(0)
  transition: transform .25s ease-out

  &.rotate
    transform: rotate(180deg)
</style>

<script>
import CfMdiSvgIcon from '@inreal/ui/src/vue/gfx/cf_mdi_svg_icon';

export default {
  name: 'CpConfigBar',
  components: {CfMdiSvgIcon},
  props: {
    backgroundColor: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    borderColor: {
      type: String,
      default: 'transparent',
    },
    inline: {
      type: Boolean,
      default: false,
    },
    startIconSvg: {
      type: String,
      default: null,
    },
    startIconSize: {
      type: String,
      default: 'xs',
    },
    startIconRotate: {
      type: Boolean,
      default: false,
    },
    endIconSvg: {
      type: String,
      default: null,
    },
    endIconSize: {
      type: String,
      default: 'xs',
    },
    endIconRotate: {
      type: Boolean,
      default: false,
    },
    /**
     * If the whole component is a button
     */
    button: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {};
  },
  computed: {
    getStyles () {
      const result = {
        'background-color': this.backgroundColor,
        'color': this.color,
      };

      if (this.inline) {
        result['display'] = 'inline-flex';
      }

      return result;
    },
    isStartButton () {
      return this.$listeners['click:start'] != null;
    },
    isEndButton () {
      return this.$listeners['click:end'] != null;
    },
  },
  watch: {},
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {},
};
</script>
