<template lang="pug">
  CpLayoutIframeDisplay.cp-logic-iframe-display(
    v-on="$listeners"
    :title="getIframeDisplayTitle"
    :close-label="$t('general.close')"
    :iframe-src="getIframeSrc"
  )
</template>

<style lang="sass" scoped>
@import '../styles/variables'
@import '~@inreal/flatyfind-ui/src/sass/variables'

.cp-logic-iframe-display
  user-select: none
  width: 100%
  max-height: 100%
  background-color: $color-box-background
  @media (min-width: $size-tablet-landscape)
    width: $infobox-width--tablet-landscape
  @media (min-width: $size-desktop)
    width: 1200px
  @media (min-width: $size-desktop-wide)
    width: 1600px
  @media (min-width: $size-desktop-4k)
    width: 2000px

  &::v-deep
    .box
      font-family: $font-main

    .title
      font-family: $font-secondary

    .icon
      color: black
</style>

<script>
import CpLayoutIframeDisplay from '@inreal/flatyfind-ui/src/vue/modals/CpLayoutIframeDisplay';
import {mapGetters} from 'vuex';
import {trackIframe} from '../common/TrackingService';

export default {
  name: 'CpLogicIframeDisplay',
  components: {
    CpLayoutIframeDisplay,
  },
  props: {
    unitId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('inreal/property', [
      'getUnitByGuid',
    ]),
    isPersistent () {
      return true;
    },
    unit () {
      return this.getUnitByGuid(this.unitId);
    },
    getIframeDisplayTitle () {
      return `${this.$t('ui.walkthroughModal.title')} ${this.unit.getMeta('numberOfRooms')}-${this.$t('unitTable.rooms')}`;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    getIframeSrc () {
      return 'https://biganto.com/tour/24318';
      // switch (this.getActivePanorama) {
      //   case 'walkthrough-unit':
      //     return 'https://biganto.com/tour/24318';
      // }
    },
  },
  created () {
    /**
     * Tracking: track iframe as media
     */
    trackIframe(this.unit);
  },
};
</script>
