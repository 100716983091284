import i18n from '../../lang';

export default {
  getters: {
    getSalutationOptions () {
      return [
        {
          value: i18n.t('navigator.intro.gender.male'),
          label: i18n.t('navigator.intro.gender.male'),
        },
        {
          value: i18n.t('navigator.intro.gender.female'),
          label: i18n.t('navigator.intro.gender.female'),
        },
        {
          value: i18n.t('navigator.intro.gender.family'),
          label: i18n.t('navigator.intro.gender.family'),
        },
        // {
        //   value: i18n.t('navigator.intro.gender.company'),
        //   label: i18n.t('navigator.intro.gender.company'),
        // },
      ];
    },
    getTitleOptions () {
      return [
        {
          value: '',
          label: i18n.t('navigator.intro.title.noTitle'),
        },
        {
          value: i18n.t('navigator.intro.title.dr'),
          label: i18n.t('navigator.intro.title.dr'),
        },
        {
          value: i18n.t('navigator.intro.title.prof'),
          label: i18n.t('navigator.intro.title.prof'),
        },
        {
          value: i18n.t('navigator.intro.title.profDr'),
          label: i18n.t('navigator.intro.title.profDr'),
        },
      ];
    },
  },
};
