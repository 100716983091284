<template lang="pug">
  CpFormLayoutPasswordReset.cp-form-logic-password-reset(
    :caption="$t('login.passwordResetCaption')"
    :label-email="$t('form.eMail')"
    :label-email-missing="$t('validation.missingEmail')"
    :label-email-wrong-format="$t('validation.wrongEmailFormat')"
    :text-success-box="$t('login.successBoxText')"
    :label-button-password-reset="$t('login.button.passwordReset')"
    :label-link-register-text="$t('login.linkRegisterText')"
    :label-link-login-text="$t('login.linkLoginText')"
    :is-loading="isLoading"
    :email-sent="emailSent"
    :user="$v.user"
    @click:password-reset="$_onPasswortResetClick"
    @change:displayed-component="$emit('change:displayed-component', $event)"
  )
</template>

<style lang="sass" scoped>
@import '../../styles/variables'

.cp-form-logic-password-reset
  display: flex
  flex-direction: column
  justify-content: center
  min-height: inherit

  &::v-deep
    .success-box
      font-family: $font-main
      border: 2px solid $color-form-success
      background-color: rgba($color-form-success, .5)
</style>

<script>
import {validationMixin} from 'vuelidate';
import {required, email} from 'vuelidate/lib/validators';
import {mapActions} from 'vuex';
import CpFormLayoutPasswordReset from '@inreal/flatyfind-ui/src/vue/forms/CpFormLayoutPasswordReset';

export default {
  name: 'CpFormLogicPasswordReset',
  components: {
    CpFormLayoutPasswordReset,
  },
  mixins: [validationMixin],
  data () {
    return {
      user: {
        email: '',
      },
      isLoading: false,
      emailSent: false,
    };
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    ...mapActions('inreal/auth', [
      'requestResetPassword',
    ]),
    async $_onPasswortResetClick () {
      this.emailSent = false;
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }

      this.isLoading = true;
      try {
        await this.requestResetPassword({email: this.user.email});
        await this.$_onPasswordResetSuccess();
      } catch (e) {
        await this.$_onPasswordResetSuccess();
      }
    },
    async $_onPasswordResetSuccess () {
      this.isLoading = false;
      this.emailSent = true;
      this.user.email = '';
      await this.$nextTick();
      this.$v.$reset();
    },
  },
};
</script>
