const projectConfig = {
  imprintLink: 'https://inreal-tech.com/impressum',
  privacyLink: 'https://inreal-tech.com/datenschutz',
  privacyDate: '17.07.2020',
  liveUrl: 'ff-service.de',
  projectUrl: '/',
  projectUrlTitle: 'Konfigurator',
  projectLogoAlt: 'Deutsche Wohnwerte',
  clientUrl: '/',
  clientUrlTitle: 'Konfigurator',
  clientLogoAlt: 'Deutsche Wohnwerte',
  hrefCustomerLabel: 'Datenschutz Inreal',
  footerColumns: {
    col1: {
      title: '',
      block: '',
    },
  },
};

export default {
  basePageTitle: 'Wohnungsfinder | FlatyFind',
  pageTitles: {
    login: 'Login',
    filter: 'Filter',
    home: 'FlatyFind',
    rooms: 'Räume',
    extras: 'Extras',
    welcome: 'Willkommen',
    order: 'Zusammenfassung',
    buildingDetail: 'Gebäudedetail',
    inquiryForm: 'Anfrageformular',
    surveyForm: 'Umfrageformular',
    privacy: 'Datenschutz',
    turntable: 'FlatyFind',
    unitselection: 'Wohnungen',
    unitconfiguration: 'Konfiguration',
  },
  general: {
    initApp: 'Wohnungen werden geladen...',
    close: 'Schliessen',
    success: 'Erfolg',
    gratitude: 'Vielen Dank!',
    areasize: '{size} m²',
    unitSoldOrReserved: 'bereits verkauft oder reserviert',
    unitSold: 'bereits verkauft',
    unitReserved: 'bereits reserviert',
    projectUrl: projectConfig.projectUrl,
    projectUrlTitle: projectConfig.projectUrlTitle,
    projectLogoAlt: projectConfig.projectLogoAlt,
    clientUrl: projectConfig.clientUrl,
    clientUrlTitle: projectConfig.clientUrlTitle,
    clientLogoAlt: projectConfig.clientLogoAlt,
    yes: 'Ja',
    no: 'Nein',
    noComment: 'Keine Angabe',
    favorites: 'Favoriten',
    noFavoritesText: 'Keine Favoriten vorhanden',
    appointment: 'Beratungstermin',
    removeUnit: 'Wohnung entfernen',
    start: 'Starten',
    footer: {
      columns: projectConfig.footerColumns,
    },
  },
  userAccount: {
    logout: 'Logout',
    userDetails: 'Account Informationen',
    userInquiries: 'Ihre Konfiguration/en',
    loadConfiguration: '» Konfiguration laden',
  },
  navigation: {
    imprintLabel: 'Impressum',
    imprintLink: projectConfig.imprintLink,
    privacyLabel: 'Datenschutz',
    privacyLink: projectConfig.privacyLink,
    privacyDate: projectConfig.privacyDate,
    liveUrl: projectConfig.liveUrl,
  },
  login: {
    registerCaption: 'Erhalten Sie den Zugang zum kompletten Wohnungsangebot. <br> <b>Alle Grundrisse und der Wohnungsfinder erwarten Sie:</b>',
    loginCaption: 'Bitte melden Sie sich an',
    passwordResetCaption: 'Bitte geben Sie Ihre E-Mail-Adresse an.',
    requestLoginLinkCaption: '<b>Erhalten Sie einen Login-Link für Ihr bestehendes Konto.</b><br>Bitte geben Sie Ihre E-Mail-Adresse an.',
    linkRegisterText: 'Account registrieren',
    linkLoginText: 'Login verwenden',
    requestLoginLinkText: 'Login-Link erhalten',
    linkPasswordResetText: 'Passwort vergessen?',
    requestLoginLinkButtonText: 'Login-Link erhalten',
    button: {
      register: 'Registrieren',
      login: 'Anmelden',
      passwordReset: 'Neues Passwort zuschicken',
    },
    successBoxText: 'Bitte überprüfen Sie Ihr E-Mail-Postfach.',
    unregisterAppuserText: 'Ihr Benutzerkonto wurde erfolgreich gelöscht.',
    unregisterInquiryAppuserText: 'Ihre Anfrage wurde erfolgreich gelöscht.',
    unregisterAppuserErrorText: 'Das verknüpfte Benutzerkonto wurde bereits gelöscht.',
    logoutAppuserText: 'Sie haben sich erfolgreich abgemeldet.',
  },
  navigator: {
    intro: {
      salutation: {
        male: 'Sehr geehrter',
        female: 'Sehr geehrte',
        family: 'Sehr geehrte',
        company: 'Sehr geehrte',
        unknown: 'Sehr geehrte/r',
        unknownMulti: 'Sehr geehrte',
      },
      gender: {
        male: 'Herr',
        female: 'Frau',
        family: 'Familie',
        misterAndMisses: 'Herr und Frau',
        missesAndMister: 'Frau und Herr',
        company: 'Firma',
        unknown: 'Herr/Frau',
        unknownMulti: ' Damen und Herren',
      },
      title: {
        noTitle: 'Kein Titel',
        dr: 'Dr.',
        prof: 'Prof.',
        profDr: 'Prof. Dr.',
      },
    },
    unitsLabel: 'Wohnung',
    buildingInfoLabelPrefix: 'Etage',
    unitType: '{rooms}-Zimmer-Wohnung',
    unitRooms: 'Zimmer:',
    unitFloor: 'Etage:',
    unitFloorValue: '{name}',
    unitBuilding: 'Gebäude:',
    unitBuildingValue: 'Gebäude: {name}',
    unitLabel: 'WOHNUNG {name}',
    unitSize: 'Wohnfläche:',
    unitSizeValue: 'ca. {size} m²',
    unitPrice: 'Kaufpreis:',
    unitSizeDataTable: '{size} m²',
    attributeLabel: '{group}: {name} ({count})',
    selectUnit: 'Wohnung auswählen',
    requestUnit: 'Wohnung anfragen',
    backBtnText: 'Zurück zur Übersicht',
    backBtnTextShort: 'zurück',
    instructionBuilding: '<strong>Interessiert?</strong> Klicken Sie auf ein Gebäude für mehr Details.',
    instructionUnit: '<strong>Interessiert?</strong> Klicken Sie auf eine Wohnung für mehr Details.',
    available: 'VERFÜGAR',
    found: 'GEFUNDEN',
    turntable: {
      toggleBtnText: 'Gebäude drehen',
    },
    filter: {
      heading: 'Wohnungsfilter',
      roomsHeading: 'Zimmeranzahl',
      sizeHeading: 'Größe in m²',
      priceHeading: 'Preis in €',
      parkingSpacesHeading: 'Stellplätze',
      floorHeading: 'Etage',
      buildingsHeading: 'Gebäude',
      all: 'Alle',
      reset: 'Filter zurücksetzen',
    },
  },
  configurator: {
    title: 'Konfiguration',
    projectLabel: 'PROJEKT',
    unitLabel: 'WOHNUNG',
    styleSelect: 'Stil Auswahl',
    extras: 'Extras',
    configurationLink: 'Konfigurationslink',
    editConfiguration: 'Konfiguration ändern',
    action: 'Zusammenfassung',
    back: 'Zur Auswahl',
    selectedUnit: 'Gewählte Wohnung:&nbsp;',
    jumpTo: 'Weiter zu {target}',
    order: 'Zusammenfassung',
    variantDetails: 'Varianten Information',
    orderDetails: 'Zusammenfassung',
    refUnit: 'Die abgebildete Visualisierung zeigt die gewählte Konfiguration anhand eines Musterraums.',
    disclaimer: 'Die genaue Leistungs- und Ausstattungsbeschreibung entnehmen Sie bitte der Baubeschreibung. Alle Darstellungen sind nur beispielhaft. Einrichtungsgegenstände sind nicht Gegenstand des Angebots. Die Website dient zur Information und stellt kein vertragliches Angebot dar. Änderung und Gestaltung bleiben vorbehalten. Für Richtigkeit und Vollständigkeit wird keine Haftung übernommen. Mögliche behördliche Auflagen können die Planung und Quadratmeterzahlen noch verändern. Bei den Fotos, Skizzen, Zeichnungen und Visualisierungen handelt es sich um unverbindliche Illustrationen. Es gelten ausschließlich die Bestimmungen des Kaufvertrages sowie die notariell beurkundete Baubeschreibung und notariell beurkundeten Pläne. Stand: April 2021.',
    floorplanLink: '<a href="{link}" target="_blank">PDF Grundriss</a>',
    labelCameraList: 'Ansichten',
    orderTitle: 'Ihre persönliche Konfiguration',
    orderLabelStyle: 'Stil:',
    orderLabelPriceTotal: 'Preis der gewählten Ausstattung',
    orderHint: 'Mit Abschluss der Konfiguration wird die Auswahl dem Kaufvertrag zugefügt. Der finale Gesamtpreis der Wohnung ist gesetzt und der Notartermin kann durchgeführt werden. Sie erhalten die Zusammenfassungen ebenfalls als PDF Datei zur Ansicht zugeschickt.',
    orderSendHint: 'Diese Konfiguration haben Sie am {date} für diese Wohnung an uns gesendet.',
    orderSendDifferentHint: 'Am {date} haben Sie bereits für diese Wohnung eine Konfiguration an uns gesendet.',
    orderSubmit: 'Abschliessen',
    orderSuccess: 'Geschafft',
    orderGratitude: 'Vielen Dank!',
    orderErrorText: 'Beim Absenden Ihrer Konfiguration ist ein Fehler aufgetreten. <br> Bitte versuchen Sie es in ein paar Minuten erneut.',
    orderSuccessText: 'Ihre Konfiguration wurde erfolgreich übermittelt. <br> Bitte überprüfen Sie Ihre E-Mails.',
  },
  welcome: {
    title: 'Wohnungskonfigurator',
    goodbye: 'Vielen Dank, dass Sie unseren Konfigurator verwendet haben. <br>Ihre Deutsche Wohnwerte.',
    salutation: '{salutation}, <br><br>nachfolgend können Sie die Ausstattung Ihrer Wohnung {unitName} im Projekt {projectName} konfigurieren.',
    salutationMale: 'Sehr geehrter Herr {name}, <br><br>nachfolgend können Sie die Ausstattung Ihrer Wohnung {unitName} im Projekt {projectName} konfigurieren.',
    salutationFemale: 'Sehr geehrte Frau {name}, <br><br>nachfolgend können Sie die Ausstattung Ihrer Wohnung {unitName} im Projekt {projectName} konfigurieren.',
    todos: [
      'Starten Sie mit der Auswahl der ersten Option in Ihrer Wohnung. Je Option stehen unterschiedliche Varianten zur Verfügung',
      'Gehen Sie alle Konfigurationsbereiche durch',
      'Schliessen Sie die Konfiguration ab und schicken Sie uns Ihre Auswahl',
    ],
    start: 'Und los',
    error: 'Der von Ihnen aufgerufene Link ist ungültig.<br>Bitte wenden Sie sich an die Deutschen Wohnwerte.',
  },
  pdf: {
    title: 'Ihre persönliche Konfiguration',
    titleClient: 'Käufer',
    labelProject: 'PROJEKT:',
    labelUnit: 'WOHNUNG:',
    labelStyle: 'STIL:',
    labelUnitType: '{rooms}-Zimmer-Wohnung',
    labelUnitRoomsValue: 'Zimmer: {rooms}:',
    labelUnitFloorValue: 'Etage: {floor}',
    labelUnitSizeValue: '{size} m²',
    footer: 'DEUTSCHE WOHNWERTE GMBH & CO. KG<br>Langer Anger 9 <br>69115 Heidelberg<br>',
  },
  unitTable: {
    nr: 'Nr.',
    building: 'Gebäude',
    floor: 'Etage',
    areasize: 'Wohnfläche',
    rooms: 'Zimmer',
    unittype: 'Typ',
    price: 'Preis',
    favorites: 'Favoriten ({count})',
  },
  form: {
    headline: 'Exposé Anfrage',
    addInfo: 'Schicken Sie uns und sich einfach die Konfiguration mit dem folgenden Formular. Wir antworten Ihnen mit einer Preisaufstellung zu der gewünschten Wohnung.<br><br>Die Konfiguration können Sie jederzeit wieder öffnen.',
    additionalInfo: 'Weitere Angaben',
    title: 'Titel',
    salutation: 'Anrede',
    firstName: 'Vorname',
    lastName: 'Nachname',
    eMail: 'E-Mail',
    street: 'Straße und Hausnummer',
    zip: 'PLZ',
    city: 'Ort',
    searchingFromDate: 'Ab wann suchen Sie eine Wohnung?',
    birthday: 'Geburtsdatum',
    comment: 'Bemerkung',
    whyInterested: 'Warum interessieren Sie sich für diese Wohnung?',
    phone: 'Telefonnr.',
    phoneMobile: 'Mobilnr.',
    password: 'Passwort',
    addPartner: 'Ehegatte/Lebensgefährte hinzufügen',
    movingInCount: 'Einziehende Personenzahl',
    movingInCountChildren: 'Anzahl Kinder',
    requestReservation: 'Wunsch auf Reservierung',
    requestAppointment: 'Beratungstermin gewünscht',
    hasFunding: 'Finanzierung vorhanden',
    incomeDisclaimer: 'Hiermit bestätige/n ich/wir, dass das Haushaltsnettoeinkommen mindestens das Dreifache der Kaltmiete beträgt.',
    wbs: 'WBS',
    dateOfIssue: 'ausgestellt am',
    wbsRooms: 'für wieviele Zimmer',
    wbsPersons: 'für wieviele Personen',
    hrefCustomerLabel: projectConfig.hrefCustomerLabel,
    dpp: 'Sie haben unsere <a class="privacy-link-customer" href="{hrefCustomer}" target="_blank" rel="noopener">Datenschutzbestimmungen</a> gelesen und akzeptiert.',
    privacyDisclaimer: 'Mit dem Absenden des Formulars durch Klick auf den Button „Abschicken“ werden Ihre Daten von uns zur Bearbeitung Ihres Anliegens verwendet. Allgemeine Hinweise zum Datenschutz bei uns finden Sie unter <a href="{hrefCustomer}" target="_blank" rel="noopener">{hrefCustomerLabel}</a>. Weitere Informationen und Hinweise zum Widerruf von im Formular abgegebenen Einwilligungen und zur Umsetzung des Datenschutzes im Konfigurator finden Sie unter <span class="privacy-link">Datenschutz</span>.',
    submitError: 'Beim Absenden Ihrer Anfrage ist ein Fehler aufgetreten. <br> Bitte versuchen Sie es in ein paar Minuten erneut. | Beim Absenden Ihrer Anfrage für Wohnung <strong>{unitName}</strong> ist ein Fehler aufgetreten. <br> Bitte versuchen Sie es in ein paar Minuten erneut. | Beim Absenden Ihrer Anfrage für die Wohnungen <strong>{unitName}</strong> ist ein Fehler aufgetreten. <br> Bitte versuchen Sie es in ein paar Minuten erneut.',
    submitSuccess: 'Ihre Anfrage wurde erfolgreich übermittelt. <br> Bitte überprüfen Sie Ihre E-Mails. | Ihre Anfrage für Wohnung <strong>{unitName}</strong> wurde erfolgreich übermittelt. <br> Bitte überprüfen Sie Ihre E-Mails. | Ihre Anfrage für die Wohnungen <strong>{unitName}</strong> wurde erfolgreich übermittelt. <br> Bitte überprüfen Sie Ihre E-Mails.',
    submit: 'Abschicken',
  },
  validation: {
    missingGender: 'Bitte geben Sie hier Ihre Anrede an',
    missingFirstName: 'Bitte geben Sie hier Ihren Vornamen an',
    missingName: 'Bitte geben Sie hier Ihren Nachnamen an',
    missingStreet: 'Bitte geben Sie hier Ihre Straße und Hausnummer an',
    missingZip: 'Bitte geben Sie hier Ihre Postleitzahl an',
    missingCity: 'Bitte geben Sie hier Ihren Ort an',
    missingPhone: 'Bitte geben Sie hier Ihre Telefonnummer an',
    missingEmail: 'Bitte geben Sie hier Ihre E-Mail-Adresse an',
    wrongEmailFormat: 'Das Format dieser E-Mail-Adresse ist falsch',
    missingPassword: 'Bitte geben Sie hier Ihr Passwort an',
    invalidBirthday: 'Kein gültiges Geburtsdatum',
    dpp: 'Sie müssen die Datenschutzbestimmungen gelesen und akzeptiert haben, bevor Sie das Formular abschicken können.',
    fieldRequired: 'Feld wird benötigt.',
  },
  ui: {
    errorHeading: 'Fehler',
    errorMsg: {
      general: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
      userNotFound: 'Einen Nutzer zu dieser Anfrage gibt es leider nicht.',
      unitNotFound: 'Das ausgewählte Haus wurde nicht gefunden.',
      configurationNotFound: 'Die ausgewählte Konfiguration wurde nicht gefunden.',
      emailTaken: 'Ein Benutzerkonto mit dieser<br>E-Mail-Adresse gibt es schon.',
      unauthorized: 'Die angegebenen Zugangsdaten sind nicht korrekt.',
      invalidDeeplink: 'Der angeforderte Link ist ungültig.',
      configurationLoginRequiredError: 'Bitte melden Sie sich an, um die Konfiguration anzusehen.',
      inputInvalid: 'Bei der Verarbeitung Ihrer Daten ist ein Fehler aufgetreten.<br>Bitte überprüfen Sie Ihre Eingaben.',
    },
    buttons: {
      requestBtnTextSingle: 'Exposé anfragen',
      requestBtnTextMultiple: '( {count} ) Anfragen',
      changeView: 'Ansicht wechseln',
      showList: 'Liste',
      showBuilding: 'Gebäude',
      pdfButtonText: 'PDF Grundriss',
      walkthroughText: 'Rundgang Musterwohnung',
      turnBuilding: 'Gebäude drehen',
    },
    unitDetails: {
      title: 'Wohnungsdetails',
      unitPrice: 'ca. {price} €',
      saveAsFavoriteBtnText: 'Als Favorit speichern',
      configureUnitBtnText: 'Ausstattung auswählen',
      configureUnitBtnTextShort: 'Ausstattung',
      floorRange: '{floorStart} bis {floorEnd}',
    },
    startFilter: {
      copy: 'Schnell und übersichtlich zu <br> Ihrer Wunschwohnung.',
      subcopy: 'Nach welcher Zimmerzahl oder <br> Größe suchen Sie?',
    },
    inquiryModal: {
      title: 'Exposéanfrage',
      titleRent: 'Mietanfrage',
      greeting: 'Gerne beraten wir Sie persönlich.',
      greetingRent: 'Wir freuen uns über Ihre Anfrage.',
      subcopy: 'Wenn Sie sich für diese Wohnungen interessieren oder den Kaufpreis anfragen möchten, dann nutzen Sie unser Kontaktformular - wir beraten Sie gern.',
      subcopyRent: 'Wenn Sie sich für diese Wohnungen interessieren, dann nutzen Sie bitte unser Kontaktformular.',
      subcopyRentNoPrice: 'Wenn Sie sich für diese Wohnungen interessieren oder den Mietpreis anfragen möchten, dann nutzen Sie bitte unser Kontaktformular.',
      selectedUnit: 'Keine Wohnung ausgewählt | Ausgewählte Wohnung: | Ausgewählte Wohnungen:',
    },
    sideMenu: {
      toggleTitle: 'Klicken zum Öffnen/Schließen',
    },
    notificationModal: {
      header: 'Information',
      btnText: 'Verstanden',
    },
    walkthroughModal: {
      title: 'Rundgang Musterwohnung',
    },
    slider: {
      prevImage: 'Vorheriger Grundriss',
      nextImage: 'Nächster Grundriss',
      firstImageMessage: '',
      lastImageMessage: '',
    },
  },
};
