const state = () => ({
  mode: null,
  consentTypes: null,
});

const mutations = {
  open (state, {mode}) {
    state.mode = mode;
  },
  setConsentTypes (state, consentTypes) {
    state.consentTypes = consentTypes;
  },
  close (state) {
    state.mode = null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
