import {applyI18nDefaults, CfConsentManagerI18n} from '@inreal/consent-manager';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import de from './de';
import en from './en';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: document.documentElement.lang,
  messages: applyI18nDefaults({
    de,
    en,
  }, CfConsentManagerI18n),
  dateTimeFormats: {
    de: {
      // https://kazupon.github.io/vue-i18n/guide/datetime.html
      // http://www.ecma-international.org/ecma-402/2.0/#sec-intl-datetimeformat-constructor
      shortDate: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
  numberFormats: {
    de: {
      // https://kazupon.github.io/vue-i18n/guide/number.html#custom-formatting
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
      integer: {
        style: 'decimal',
        maximumFractionDigits: 0,
      },
      float: {
        style: 'decimal',
        maximumFractionDigits: 2,
      },
      floatSingle: {
        style: 'decimal',
        maximumFractionDigits: 1,
      },
      percent: {
        style: 'percent',
        minimumFractionDigits: 1,
      },
      percentNoFraction: {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      currency: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      currencyNoFraction: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
    en: {
      // https://kazupon.github.io/vue-i18n/guide/number.html#custom-formatting
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
      integer: {
        style: 'decimal',
        maximumFractionDigits: 0,
      },
      float: {
        style: 'decimal',
        maximumFractionDigits: 2,
      },
      floatSingle: {
        style: 'decimal',
        maximumFractionDigits: 1,
      },
      percent: {
        style: 'percent',
        minimumFractionDigits: 1,
      },
      percentNoFraction: {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      currency: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      currencyNoFraction: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
  },
  missing: (locale, key) => {
    return `#${locale}:${key}#`;
  },
});

export default i18n;

export function updatePageTitle (titleName) {
  // console.log('updatePageTitle', titleName);
  document.title = `${i18n.t(`pageTitles.${titleName}`)} - ${i18n.t('basePageTitle')}`;
}

export function updateLocale (locale) {
  i18n.locale = locale;
  document.documentElement.lang = locale;
  localStorage.setItem('locale', locale);
}

export function getStorageLocale () {
  return localStorage.getItem('locale');
}
