<template lang="pug">
  CfScrollbar.cp-login(:show-y-start-indicator="false")
    transition(
      name="fade"
      mode="out-in"
    )
      template(
        v-if="getLoginComponentToDisplay === 'register'"
      )
        CpFormLogicRegister(
          @register-success="$emit('close', {type: 'start-filter'})"
          @change:displayed-component="$_onDisplayedComponentToggle($event)"
        )
      template(
        v-else-if="getLoginComponentToDisplay === 'login'"
      )
        CpFormLogicLogin(
          @login-success="$emit('close', {type: 'start-filter'})"
          @change:displayed-component="$_onDisplayedComponentToggle($event)"
        )
      template(
        v-else-if="getLoginComponentToDisplay === 'pw-reset'"
      )
        CpFormLogicPasswordReset(
          @change:displayed-component="$_onDisplayedComponentToggle($event)"
        )
      template(
        v-else-if="getLoginComponentToDisplay === 'request-login-link'"
      )
        CpFormLogicRequestLoginLink(
          @change:displayed-component="$_onDisplayedComponentToggle($event)"
        )
    CpSelectLanguage.lang-select(
      v-if="isMultiLanguage"
      :current="$i18n.locale"
      :languages="getLanguages"
      @update:language="$_onUpdateLanguage"
      color="#000"
    )
</template>

<style lang="sass" scoped>
@import "../styles/variables"

.cp-login
  width: 100%
  max-height: 100%
  max-width: 600px
  text-align: center
  background-color: white
  border-radius: 3px
  padding: 20px 5px

  &::v-deep
    .md-input
      width: 100%

  @media (min-width: $size-tablet)
    padding: 30px
    width: auto
    min-width: 550px

.lang-select
  padding-top: 8px
</style>

<script>
import CpSelectLanguage from '@inreal/flatyfind-ui/src/vue/selects/CpSelectLanguage';
import CfScrollbar from '@inreal/ui/src/vue/container/cf_scrollbar';
import {mapGetters, mapMutations} from 'vuex';
import CpFormLogicLogin from '../components/form/CpFormLogicLogin';
import CpFormLogicPasswordReset from '../components/form/CpFormLogicPasswordReset';
import CpFormLogicRegister from '../components/form/CpFormLogicRegister';
import CpFormLogicRequestLoginLink from '../components/form/CpFormLogicRequestLoginLink';
import {updateLocale, updatePageTitle} from '../lang';

export default {
  name: 'CpLogin',
  components: {
    CpSelectLanguage,
    CpFormLogicPasswordReset,
    CpFormLogicLogin,
    CpFormLogicRegister,
    CfScrollbar,
    CpFormLogicRequestLoginLink,
  },
  computed: {
    ...mapGetters([
      'getLoginComponentToDisplay',
    ]),
    ...mapGetters('inreal/projectSettings', [
      'isMultiLanguage',
      'getLanguages',
    ]),
    isPersistent () {
      return true;
    },
  },
  created () {
    updatePageTitle('login');
  },
  methods: {
    ...mapMutations([
      'setLoginComponentToDisplay',
    ]),
    $_onDisplayedComponentToggle ($event) {
      this.setLoginComponentToDisplay({componentName: $event});
    },
    $_onUpdateLanguage (language) {
      updateLocale(language);
    },
  },
};
</script>
