import { render, staticRenderFns } from "./CpLogicUserDetails.vue?vue&type=template&id=0dea4d87&scoped=true&lang=pug&"
import script from "./CpLogicUserDetails.vue?vue&type=script&lang=js&"
export * from "./CpLogicUserDetails.vue?vue&type=script&lang=js&"
import style0 from "./CpLogicUserDetails.vue?vue&type=style&index=0&id=0dea4d87&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dea4d87",
  null
  
)

export default component.exports