import Vue from 'vue';
import Router from 'vue-router';

import {getBrowserLanguage} from '@inreal/core-library/src/js/i18n';

import {DEEPLINK_TYPE} from '@inreal/cockpit-library/src/js/enums';
import {ROUTE_NAMES} from '@inreal/flatyfind-ui/src/js/constants/routes';
import {ERROR_TYPE} from '@inreal/flatyfind-ui/src/js/constants/error-types';

import {NOTIFICATION_TYPE} from '@inreal/flatyfind-ui/src/js/constants/notification-type';
import i18n, {getStorageLocale, updateLocale} from '../lang';
import store from '../store';

import CpUnit from '../pages/CpUnit';
import CpUnitConfiguration from '../pages/aside/CpUnitConfiguration';
import CpUnitSelection from '../pages/CpUnitSelection';
import CpWelcome from '../pages/CpWelcome';
import CpPdf from '../pages/CpPdf';
import {trackCustomUrlByPath} from '../common/TrackingService';
import CpGoodBye from '../pages/CpGoodBye';


Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.ROUTER_BASE,
  scrollBehavior (to, from, savedPosition) {
    return {x: 0, y: 0};
  },
  routes: [
    {
      path: '',
      name: ROUTE_NAMES.HOME,
      component: CpUnitSelection,
      beforeEnter: async (to, from, next) => {
        if (store.getters['inreal/projectSettings/isSpecialConfigurator']) {
          next({name: ROUTE_NAMES.WELCOME, params: {guid: '0'}});
        } else {
          if (!store.getters['inreal/auth/isLoggedIn'] && store.getters['inreal/projectSettings/hasLoginSystem']) {
            const loginModalSub = await store.dispatch('inreal/modalManager/openModal', {type: 'login'});
            loginModalSub.onClose.then(followup => {
              if (followup?.type != null) {
                store.dispatch('inreal/modalManager/openModal', {type: followup.type});
              }
            });
          } else {
            if (!store.getters['getDisplayedStartFilter']) {
              store.commit('setDisplayedStartFilter', true);
              await store.dispatch('inreal/modalManager/openModal', {type: 'start-filter'});
            }
          }
          next();
        }
      },
    },
    {
      path: '/direct/:hash',
      name: ROUTE_NAMES.DIRECT,
    },
    {
      path: '/pdf/:hash',
      name: ROUTE_NAMES.PDF,
      component: CpPdf,
    },
    {
      path: '/unregister/:hash',
      name: ROUTE_NAMES.UNREGISTER,
      beforeEnter: async (to, from, next) => {
        const hash = to.params.hash;
        try {
          await store.dispatch('inreal/auth/unregister', {hash});
          store.commit('setPendingModal', {
            type: 'notification',
            props: {
              type: store.getters['inreal/projectSettings/hasLoginSystem']
                ? NOTIFICATION_TYPE.UNREGISTER
                : NOTIFICATION_TYPE.UNREGISTER_INQUIRY,
            },
          });
        } catch (e) {
          store.commit('setPendingModal', {
            type: 'notification',
            props: {
              type: NOTIFICATION_TYPE.UNREGISTER_ERROR,
            },
          });
        }
        next({name: ROUTE_NAMES.HOME});
      },
    },
    {
      path: '/logout',
      name: ROUTE_NAMES.LOGOUT,
      beforeEnter: async (to, from, next) => {
        await store.dispatch('inreal/auth/logout');
        next({name: ROUTE_NAMES.HOME});
      },
    },
    {
      path: '/welcome/:guid',
      name: ROUTE_NAMES.WELCOME,
      component: CpWelcome,
      props: true,
    },
    {
      path: '/bye',
      name: ROUTE_NAMES.GOODBYE,
      component: CpGoodBye,
      props: false,
    },
    {
      path: '/unit/:guid',
      name: ROUTE_NAMES.UNIT,
      props: {
        default: true,
        aside: true,
      },
      components: {
        default: CpUnit,
        aside: CpUnitConfiguration,
      },
      beforeEnter: (to, from, next) => {
        if (!store.getters['inreal/auth/isLoggedIn'] && store.getters['inreal/projectSettings/hasLoginSystem']) {
          next({name: ROUTE_NAMES.HOME});
          return;
        }

        const unitGuid = to.params.guid;
        if (!store.getters['inreal/configuration/isUnitConfigured'](unitGuid)) {
          store.dispatch('inreal/configuration/setUnitDefaults', {unitGuid});
        }

        const camera = store.getters['inreal/configurationUi/getFirstCameraInMenuEntries'](unitGuid);
        store.commit('setCurrentCameraSceneParams', {
          cameraGuid: camera.guid,
          showInitialViewParams: true,
        });

        next();
      },
    },
    {
      path: '*',
      name: ROUTE_NAMES.CATCH_ALL,
      beforeEnter: ((to, from, next) => {
        next({name: ROUTE_NAMES.HOME});
      }),
    },
  ],
});

let isLoaderActive = false;
router.beforeEach((to, from, next) => {
  if (!isLoaderActive) {
    store.commit('loaderPush', 'route');
    isLoaderActive = true;
  }
  next();
});

// Language check
let checkLanguage = true;
router.beforeEach((to, from, next) => {
  if (checkLanguage === false) {
    next();
    return;
  }

  checkLanguage = false;

  if (store.getters['inreal/projectSettings/isMultiLanguage']) {
    const browserLanguage = getBrowserLanguage();
    const storageLocale = getStorageLocale();

    const languageParam = to.query.l;

    // Check for query param 'l' first
    if (languageParam != null
      && store.getters['inreal/projectSettings/isValidLanguage'](languageParam)) {
      updateLocale(languageParam);
      let query = Object.assign({}, to.query);
      delete query.l;
      next({...to, query});
      return;
    } else if (storageLocale != null
      && store.getters['inreal/projectSettings/isValidLanguage'](storageLocale)) {
      // Else use storage locale
      updateLocale(storageLocale);
    } else if (browserLanguage != null
      && store.getters['inreal/projectSettings/isValidLanguage'](browserLanguage)) {
      // Else use browser locale
      updateLocale(browserLanguage);
    } else {
      // Else use project default locale
      updateLocale(store.getters['inreal/projectSettings/getDefaultLanguage']);
    }
  }

  next();
});

// Global hook
router.beforeEach(async (to, from, next) => {
  const toLoad = [null, null];

  // Load frontend property if not already set
  if (!store.getters['inreal/property/hasData']) {
    toLoad[0] = store.dispatch('inreal/property/loadFrontendProperty', {cacheKey: process.env.FRONTEND_PROPERTY_CACHE_KEY});
  }

  // Load deeplink if needed
  let targetIsDeeplink = false;
  if (to.name === ROUTE_NAMES.DIRECT || to.name === ROUTE_NAMES.PDF) {
    targetIsDeeplink = true;
    const hash = to.params.hash;
    // autologin should always be true
    // as soon as a deeplink is created it should work
    // even if reg-form is activated or not
    const autoLogin = true;
    toLoad[1] = store.dispatch('inreal/auth/loadDeeplink', {
      hash,
      autoLogin,
    });

    /**
     * Tracking: set consent for all values to 1 for deeplink login
     */
    store.commit('setInitialConsent', 1);
  }

  // Set loader
  const hasDataToLoad = toLoad[0] != null || toLoad[1] != null;
  if (hasDataToLoad) {
    store.commit('loaderPush', 'page');
  }

  // Wait for property/deeplink
  let deeplink = null;
  try {
    const [_, deeplinkResponse] = await Promise.all(toLoad);
    if (deeplinkResponse) {
      deeplink = deeplinkResponse;
    }
  } catch (e) {
    // neets to be triggered - otherwise the loader is not going away
    // to show the error modal
    store.commit('loaderPop', 'page');
    store.commit('setInitialLoad', {initialLoad: false});

    if (deeplink) {
      await store.commit('setPendingError', ERROR_TYPE.INVALID_DEEPLINK_ERROR);
    } else {
      await store.commit('setPendingError', ERROR_TYPE.GENERAL_ERROR);
    }

    if (from.name === ROUTE_NAMES.HOME || from.name == null) {
      next();
      return;
    }
    next({name: ROUTE_NAMES.HOME});
    return;
  }

  // Init filter logic
  if (toLoad[0] != null) {
    await store.dispatch('inreal/unitFilter/initAttributeFilters', {locale: i18n.locale});
    store.commit('inreal/unitFilter/setStatusFilter', {statusFilter: store.getters['inreal/projectSettings/getStatusFilter']});
  }

  // Login initially if not already logged in
  if (process.env.HAS_AUTH_SESSION === true) {
    await store.dispatch('inreal/auth/loadUser');
    delete process.env.HAS_AUTH_SESSION;
  }

  store.commit('loaderPop', 'page');
  store.commit('setInitialLoad', {initialLoad: false});

  if (targetIsDeeplink) {
    // Handle deeplink content
    if (deeplink != null) {
      // TODO: handle deeplink content
      store.commit('inreal/deeplink/setDeeplink', {deeplink});
      switch (deeplink.type) {
        case DEEPLINK_TYPE.UNIT:
          if (store.getters['inreal/projectSettings/isSpecialConfigurator']) {
            next({name: ROUTE_NAMES.WELCOME, params: {guid: deeplink.data.unit[0].guid}});
          } else {
            next({name: ROUTE_NAMES.UNIT, params: {guid: deeplink.data.unit[0].guid}});
          }
          break;
        case DEEPLINK_TYPE.USER:
          next({name: ROUTE_NAMES.HOME});
          break;
        case DEEPLINK_TYPE.INQUIRY:
          // eslint-disable-next-line
          if (store.getters['inreal/projectSettings/isSpecialConfigurator']) {
            let targetUnitGuid = false;
            let targetUnitConfiguration = false;

            // configuration hash
            if (deeplink.data.configuration) {
              targetUnitGuid = deeplink.data.unit.guid;
              targetUnitConfiguration = deeplink.data.configuration;
            }

            if (deeplink.data.inquiry
              && deeplink.data.inquiry.inquiryItems.length > 0
              && deeplink.data.inquiry.inquiryItems[0].configuration) {
              targetUnitGuid = deeplink.data.inquiry.inquiryItems[0].unit.guid;
              targetUnitConfiguration = deeplink.data.inquiry.inquiryItems[0].configuration;
            }

            if (targetUnitConfiguration !== false) {
              await store.dispatch('inreal/configuration/setConfiguration', {unitGuid: targetUnitGuid, configuration: targetUnitConfiguration});
            }

            if (to.name === ROUTE_NAMES.DIRECT) {
              next({name: ROUTE_NAMES.UNIT, params: {guid: targetUnitGuid}});
            } else {
              next();
            }
          } else {
            next({name: ROUTE_NAMES.HOME});
          }
          break;
        case DEEPLINK_TYPE.INQUIRY_ITEM:
          // eslint-disable-next-line
          next({name: ROUTE_NAMES.HOME});
          break;
      }
    } else {
      // eslint-disable-next-line no-console
      await store.commit('setPendingError', ERROR_TYPE.INVALID_DEEPLINK_ERROR);
      next({name: ROUTE_NAMES.HOME});
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  store.commit('loaderPop', 'route');
  isLoaderActive = false;
  if (to.matched.length > 0) {
    /**
     * Tracking: track custom url as page view
     */
    trackCustomUrlByPath(to.path);
  }
});

export default router;
