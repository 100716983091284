<template lang="pug">
  CpModalBase.cp-logic-order-details(
    @close="$emit('close')"
    closeable
    scrollable
    :close-label="labelClose"
  )
    template(#header)
      | {{headerTitle}}
    template(#content)
      .details
        .unit
          .copy
            template(v-for="(elm, index) in infoCopy")
              span(:class="{'first': index === 0}" v-html="elm")
              span.divider(v-if="index !== infoCopy.length - 1" v-html="copyDivider")
          .price
            span(v-html="getLabelPriceTotal")
            span(v-html="getPriceTotal")
          template(v-if="configurationForUnit == null")
            template(v-if="!showFollowUp")
              .disclaimer(v-html="getHint")
              MdProgressBar.progress-bar(
                md-mode="indeterminate"
                class="md-primary"
                :class="{visible: isLoading}"
              )
              CpButtonAction(
                :is-submit="true"
                :label="labelButtonSubmit"
                :disabled="isLoading"
                :show-slot="true"
                @click.native="$_onClickSubmit()"
              )
                include ../assets/img/long-arrow.svg
            template(v-else)
              CpInquiryFollowUp(
                key="0"
                :success="inquirySuccess"
                :color="$color('brand')"
                :color-error="$color('form-error')"
                :text-success="$t('configurator.orderSuccess')"
                :text-gratitude="$t('configurator.orderGratitude')"
                :text-error="$t('ui.errorHeading')"
                :text-submit-success="$t('configurator.orderSuccessText')"
                :text-submit-error="$t('configurator.orderErrorText')"
              )
          template(v-else)
            template(v-if="configurationIsDifferent")
              .disclaimer
                |{{$_formatHintSendConfigurationIsDifferent(configurationForUnit)}}
                a.load(@click="$_loadConfiguration($event)") {{$t('userAccount.loadConfiguration')}}
            template(v-else)
              .disclaimer
                |{{$_formatHintSendConfiguration(configurationForUnit)}}
        .description
          .title(v-html="getTitleOrder")
          .copy
            // STYLE
            template(v-if="showStyle")
              .label(v-html="getLabelStyle")
              .value(v-html="getValueStyleSelected")
            // MENU ENTRIES WITH VALUES
            template(v-for="menuEntry in getMenuEntries(unitGuid, $i18n.locale)")
              .label.large(v-html="menuEntry.name")
              template(v-for="option in menuEntry.options")
                .option-label(v-html="option.name")
                .variant
                  span(v-html="$_getLabelOfSelectedVariantOfMenuEntry(option)")
                  span.variant-description(v-if="$_getDescriptionOfSelectedVariantOfMenuEntry(option)" v-html="$_getDescriptionOfSelectedVariantOfMenuEntry(option)")
                  span.variant-price(v-html="$_getPriceOfSelectedVariantOfMenuEntry(option)")
</template>

<style lang="sass" scoped>
@import '../styles/variables'

.progress-bar
  width: 100%
  position: sticky
  top: 0
  margin-top: 0px !important

.cp-logic-order-details
  max-width: initial
  max-height: 100%
  height: auto

  @media (min-width: $size-tablet)
    width: $orderbox-width--tablet

  @media (min-width: $size-tablet-landscape)
    width: $orderbox-width--tablet-landscape

  @media (min-width: $size-desktop)
    width: $orderbox-width

  @media (min-width: $size-desktop-wide)
    width: $orderbox-width--desktop-wide

  @media (min-width: $size-desktop-4k)
    width: $orderbox-width--desktop-4k

  .details
    display: flex
    flex-direction: column
    @media (min-width: $size-tablet)
      flex-direction: row
    .unit
      padding: 32px
      @media (min-width: $size-tablet)
        width: 38.2%
      span
        display: inline-block
        font-weight: 600
        line-height: 26px
        &::v-deep
          a
            font-weight: 300
            font-style: italic
            text-decoration: underline
            color: black
            &:hover
              color: $color-brand

      span.prefix,
      span.first,
      span:nth-child(7)
        font-weight: 300 !important

      span.divider
        display: block

      .copy, .headline
        text-align: left
        line-height: 26px

      .price
        padding-top: 32px
        span
          display: block
          font-weight: 300 !important
          &:first-child
            font-size: 0.8em
            text-transform: uppercase
          &:nth-child(2)
            font-size: 1.2em

      .disclaimer
        margin-bottom: 32px
        margin-top: 32px
        font-style: italic
        color: red
        user-select: none
        a
          cursor: pointer

    .description
      @media (min-width: $size-tablet)
        width: 38.2%
      padding: 32px

      .title
        font-style: italic
        margin-bottom: 8px

      .label
        text-transform: uppercase
        font-weight: 300

        &.large
          position: relative
          display: inline-block
          margin-bottom: 12px
          margin-top: 6px
          &:after
            content: ''
            height: 3px
            position: absolute
            left: 0
            right: 0
            bottom: 0
            background-color: black

      .variant
        margin-bottom: 8px
        &::v-deep
          span
            display: block
            &:first-child
              font-weight: 600
            &.variant-description
              font-style: italic
              font-size: 0.9em
              margin-bottom: 5px
            &.variant-price
              font-size: 1em

      h1, h2
        font-weight: 300
        text-transform: uppercase
        margin: 0
        padding: 0
        margin-bottom: 16px
      h1
        font-size: 21px
      h2
        font-size: 16px
.cp-inquiry-follow-up
  margin-top: 32px
  &::v-deep
    > .content
      border: 1px solid $color-brand
      padding: 15px 25px

</style>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import CfIcon from '@inreal/ui/src/vue/gfx/cf_icon';
import CfImageLoader from '@inreal/ui/src/vue/loader/cf_image_loader';
import CfAspectRatio from '@inreal/ui/src/vue/container/cf_aspect_ratio';
import CpModalBase from '@inreal/flatyfind-ui/src/vue/modals/CpModalBase';
import CpButtonAction from '@inreal/flatyfind-ui/src/vue/buttons/CpButtonAction';
import CpInquiryFollowUp from '@inreal/flatyfind-ui/src/vue/display/CpInquiryFollowUp';

export default {
  name: 'CpLogicOrderDetails',
  components: {
    CfIcon,
    CfAspectRatio,
    CfImageLoader,
    CpModalBase,
    CpButtonAction,
    CpInquiryFollowUp,
  },
  props: {
    unitGuid: {
      type: String,
      required: true,
    },
    infoCopy: {
      type: Array,
      required: true,
    },
    copyDivider: {
      type: String,
      default: '//',
    },
  },
  data () {
    return {
      showFollowUp: false,
      isLoading: false,
      inquirySuccess: null,
      // Inquiry Schema
      // https://dev.azure.com/Inreal-Web/cloud-schemas/_git/cloud-inquiry-schema?path=%2Fschema%2Fcloud-inquiry-schema.json

      // example request as json
      // https://dev.azure.com/Inreal-Web/cloud-schemas/_git/cloud-inquiry-schema?path=%2Fschema%2Fcloud-inquiry-schema.example.json
      inquiry: {
        salutation: 'Herr',
        firstname: '',
        lastname: '',
        street: '',
        zip: '',
        city: '',
        phone: '',
        email: '',
        message: '',
        dpp: true,
      },
    };
  },
  computed: {
    ...mapGetters('inreal/auth', [
      'getUser',
      'isLoggedIn',
    ]),
    ...mapGetters('inreal/property', [
      'getVariantByGuid',
      'getVariantImageUrl',
      'getOptionByGuid',
      'getVariantGroupForVariant',
    ]),
    ...mapGetters('inreal/projectSettings', [
      'hasLoginSystem',
      'isMultiLanguage',
      'getLanguages',
    ]),
    ...mapGetters('inreal/configuration', [
      'getSelectedStyleInUnit',
      'getConfigurationInUnit',
    ]),
    ...mapGetters('inreal/configurationUi', [
      'getMenuEntries',
      'getStylesOfUnit',
    ]),
    ...mapGetters('inreal/inquiryItems', [
      'getInquiryItems',
    ]),
    ...mapGetters('inreal/prices', [
      'getPricesOfVariants',
      'getPriceValueOfVariant',
      'getPriceFormattedOfVariant',
    ]),
    labelClose () {
      return this.$t('general.close');
    },
    headerTitle () {
      return this.$t('configurator.orderDetails');
    },
    labelButtonSubmit () {
      return this.$t('configurator.orderSubmit');
    },
    showStyle () {
      return this.getStylesOfUnit(this.unitGuid) > 1;
    },
    getHint () {
      return this.$t('configurator.orderHint');
    },
    getTitleOrder () {
      return this.$t('configurator.orderTitle');
    },
    getLabelStyle () {
      return this.$t('configurator.orderLabelStyle');
    },
    getValueStyleSelected () {
      const style = this.getSelectedStyleInUnit(this.unitGuid);
      return `- ${style.getMeta('name')}`;
    },
    getInquiryItemsNames () {
      return this.getInquiryItems.map(inquiryItem => inquiryItem.unit.name);
    },
    getPriceTotal () {
      let total = 0;
      this.getMenuEntries(this.unitGuid, this.$i18n.locale).forEach(menuEntry => {
        menuEntry.options.forEach(option => {
          total += this.getPriceValueOfVariant(option.selectedVariant.guid);
        });
      });
      return this.$n(total / 100, 'currency');
    },
    getLabelPriceTotal () {
      return this.$t('configurator.orderLabelPriceTotal');
    },
    configurationForUnit () {
      let result = null;
      this.getUser.inquiries.forEach(inquiry => {
        inquiry.inquiryItems.forEach(inquiryItem => {
          if (inquiryItem.unit.guid === this.unitGuid) {
            result = {
              createdAt: inquiry.createdAt,
              configuration: inquiryItem.configuration,
            };
          }
        });
      });
      return result;
    },
    configurationIsDifferent () {
      let isDifferent = false;
      const current = this.getConfigurationInUnit(this.unitGuid);
      const sended = this.configurationForUnit.configuration;
      if (current.rooms.length !== sended.rooms.length ||
          current.extras.length !== sended.extras.length) {
        return true;
      }
      current.rooms.forEach(room => {
        let sendedRoom = sended.rooms.find(sRoom => sRoom.guid === room.guid);
        if (sendedRoom == null || JSON.stringify(room.options) !== JSON.stringify(sendedRoom.options)) {
          isDifferent = true;
        }
      });
      current.extras.forEach(extra => {
        let sendedExtra = sended.extras.find(sExtra => sExtra.guid === extra.guid);
        if (sendedExtra == null || JSON.stringify(extra.options) !== JSON.stringify(sendedExtra.options)) {
          isDifferent = true;
        }
      });
      console.log('isDifferent', isDifferent);
      return isDifferent; // JSON.stringify(current) !== JSON.stringify(this.configurationForUnit.configuration);
    },
  },
  mounted () {
    if (this.getUser != null) {
      this.$_fillFormWithUser();
      this.showFollowUp = false;
    }
  },
  methods: {
    ...mapMutations('inreal/inquiryItems', [
      'clearInquiryItems',
    ]),
    ...mapActions('inreal/configuration', [
      'setConfiguration',
    ]),
    ...mapActions([
      'sendInquiry',
    ]),
    $_formatHintSendConfigurationIsDifferent (inquiry) {
      return this.$t('configurator.orderSendDifferentHint', {date: this.$d(new Date(inquiry.createdAt), 'shortDate')});
    },
    $_formatHintSendConfiguration (inquiry) {
      return this.$t('configurator.orderSendHint', {date: this.$d(new Date(inquiry.createdAt), 'shortDate')});
    },
    $_formatPrice (price) {
      return this.$n(price / 100, 'currency');
    },
    $_getLabelOfSelectedVariantOfMenuEntry (option) {
      const groupOfSelectedVariant = this.getVariantGroupForVariant(option.selectedVariant.guid);
      return groupOfSelectedVariant ? `${groupOfSelectedVariant.getMeta('name', this.$i18n.locale)} : ${option.selectedVariant.name}` : option.selectedVariant.name;
    },
    $_getDescriptionOfSelectedVariantOfMenuEntry (option) {
      return option.selectedVariant.model.getMeta('description', this.$i18n.locale);
    },
    $_getPriceOfSelectedVariantOfMenuEntry (option) {
      return this.getPriceFormattedOfVariant(option.selectedVariant.guid, this.$_formatPrice, 'default');
    },
    $_loadConfiguration (event) {
      event.preventDefault();
      this.setConfiguration({unitGuid: this.unitGuid, configuration: this.configurationForUnit.configuration});
    },
    $_fillFormWithUser () {
      this.inquiry.salutation = this.getUser.salutation ?? '';
      this.inquiry.firstname = this.getUser.firstname ?? '';
      this.inquiry.lastname = this.getUser.lastname;
      this.inquiry.email = this.getUser.email;
      this.inquiry.phone = this.getUser.phone ?? '';
      this.inquiry.street = this.getUser.street ?? '';
      this.inquiry.zip = this.getUser.zip ?? '';
      this.inquiry.city = this.getUser.city ?? '';
    },
    $_onClickSubmit () {
      this.$_postForm();
    },
    async $_postForm () {
      this.$emit('submit');
      this.isLoading = true;
      this.isMultiLanguage ? this.inquiry.locale = this.$i18n.locale : this.inquiry.locale = this.getLanguages[0];
      this.inquiry.inquiryItems = this.getInquiryItems;

      try {
        // Inquiry Schema
        // https://dev.azure.com/Inreal-Web/cloud-schemas/_git/cloud-inquiry-schema?path=%2Fschema%2Fcloud-inquiry-schema.json
        // example request as json
        // https://dev.azure.com/Inreal-Web/cloud-schemas/_git/cloud-inquiry-schema?path=%2Fschema%2Fcloud-inquiry-schema.example.json
        await this.sendInquiry({data: this.inquiry});
        this.inquirySuccess = true;

        // TODO: recheck: do we need seperate config tracking?
        // trackConfig('send', responseData.data.inquiry.hash);

      } catch (e) {

        this.isLoading = false;
        this.inquirySuccess = false;
        this.showFollowUp = true;

      } finally {

        this.isLoading = false;
        this.showFollowUp = true;
        if (this.inquirySuccess) {
          this.clearInquiryItems();
        } else {
          this.$emit('submit:error');
        }
      }
    },
  },
};
</script>
