import {onDocumentLoaded} from '@inreal/core-library/src/js/document';

function promisify (api) {
  return (...args) => new Promise((resolve, reject) => api(...args, resolve, reject));
}

let ppms;
if (typeof window !== 'undefined') {
  ppms = onDocumentLoaded(document).then(() => {
    if (window.ppms != null) {
      return window.ppms;
    } else {
      throw new Error('Piwik/PPMS not available, maybe due to ad blocking');
    }
  });
} else {
  ppms = Promise.reject(new Error('Piwik/PPMS is not available outside the browser'));
}

export async function getApi () {
  return promisify((await ppms).cm.api);
}

// Wrapper functions for low-level API functionality

export function getComplianceSettings (api) {
  return api('getComplianceSettings');
}

export function getComplianceTypes (api) {
  return api('getComplianceTypes');
}

export function setComplianceSettings (api, settings) {
  return api('setComplianceSettings', settings);
}

export function setInitialComplianceSettings (api, settings) {
  return api('setInitialComplianceSettings', settings);
}

export function getNewComplianceTypes (api) {
  return api('getNewComplianceTypes');
}
