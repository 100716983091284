<template lang="pug">
  CpLayoutErrorBox.cp-logic-error-box(
    v-on="$listeners"
    :error-title="$t('ui.errorHeading')"
    :error-text="errorText"
  )
</template>

<style lang="sass" scoped>
@import '../styles/variables'

.cp-error-box::v-deep
  .title
    font-family: $font-main

  .text
    font-family: $font-main
</style>

<script>
import {mapGetters} from 'vuex';
import {ERROR_TYPE} from '@inreal/flatyfind-ui/src/js/constants/error-types';
import CpLayoutErrorBox from '@inreal/flatyfind-ui/src/vue/modals/CpLayoutErrorBox';

export default {
  name: 'CpLogicErrorBox',
  components: {
    CpLayoutErrorBox,
  },
  computed: {
    isPersistent () {
      return true;
    },
    ...mapGetters([
      'getError',
    ]),
    errorText () {
      switch (this.getError) {
        case ERROR_TYPE.USER_NOT_FOUND:
          return this.$t('ui.errorMsg.userNotFound');

        case ERROR_TYPE.UNIT_NOT_FOUND:
          return this.$t('ui.errorMsg.unitNotFound');

        case ERROR_TYPE.CONFIGURATION_NOT_FOUND:
          return this.$t('ui.errorMsg.configurationNotFound');

        case ERROR_TYPE.EMAIL_TAKEN_ERROR:
          return this.$t('ui.errorMsg.emailTaken');

        case ERROR_TYPE.UNAUTHORIZED_ERROR:
          return this.$t('ui.errorMsg.unauthorized');

        case ERROR_TYPE.INVALID_DEEPLINK_ERROR:
          return this.$t('ui.errorMsg.invalidDeeplink');

        case ERROR_TYPE.CONFIGURATION_LOGIN_REQUIRED_ERROR:
          return this.$t('ui.errorMsg.configurationLoginRequiredError');

        case ERROR_TYPE.INPUT_INVALID:
          return this.$t('ui.errorMsg.inputInvalid');

        case ERROR_TYPE.GENERAL_ERROR:
        case ERROR_TYPE.SERVER_ERROR:
        case ERROR_TYPE.FORMAT_ERROR:
          return this.$t('ui.errorMsg.general');

        default:
          return this.$t('ui.errorMsg.general');
      }
    },
  },
};
</script>
