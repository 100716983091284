<template lang="pug">
  .cp-unit
    .left-side
      CpBoxInfoUnit(
        :copy="getBoxInfoUnitCopy"
        :headline="getBoxInfoUnitHeadline"
        :prefix="getBoxInfoUnitPrefix"
        :copy-divider="''"
        :label-button-action="getBoxInfoUnitLabelButtonAction"
        :show-slot="false"
        :show-button-action="false"
        )
      .camera-list
        .label(v-html="getLabelCameraList")
        template(v-for="camera in getAllUnitCams")
          button.camera(
            :class="{'active': getCurrentCamera && camera.guid === getCurrentCamera.guid}"
            @click="setCurrentCameraSceneParams({cameraGuid: camera.guid, showInitialViewParams: true})"
          )
            | {{camera.getMeta('name', $i18n.locale)}}
      CpButtonAction.button-order(
        :label="getLabelOrder"
        :is-light="true"
        :show-slot="true"
        @click.native="$_onClickOrder()"
      )
        include ../assets/img/long-arrow.svg
    CpLabelCamTitle(
      v-if="getCurrentCamera != null"
      :label="getCurrentCamera.getMeta('name', $i18n.locale)"
    )
    .image-wrapper
      CpWrapperImageView(
        v-if="getCurrentCameraSceneParams != null"
        :scenes="getScenes"
        :scene-params="getSceneParams"
        :is-still-image="isCurrentCameraStillImage"
        :scene-image-url-provider="getVisualImage"
        :image-ratio="1"
        @click:pano-hotspot="$_onClickHotspot"
        @change:pano-params="$_onParamsChange"
      )
      CpButtonAction.jump-to-menu(
        v-if="getNextConfigMenuRef(guid) != null"
        :label="getJumpTargetLabel"
        :is-light="true"
        :show-slot="true"
        @click.native="jumpToNextMenuDropdown({unitGuid: guid})"
      )
        include ../assets/img/long-arrow.svg
    CpListButtons(
      v-if="getCameraList.length > 1"
      :buttons="getCameraList"
      @click="$_onCameraListClick"
    )
    CpLabelRefUnit(:label="getLabelRefUnit")
    p.disclaimer(v-html="getDisclaimer")
    .dev(v-if="getIsDevMode && getCurrentCamera != null")
      .title DEV MODE CAMS
      p.config config: {name: {{getVisualImageData(guid, getCurrentCamera.guid).name}}, hash: {{getVisualImageData(guid, getCurrentCamera.guid).hash}}}
      p.params params: {yaw: {{currentParams && currentParams.yaw ? currentParams.yaw : ''}}, pitch: {{currentParams && currentParams.pitch ? currentParams.pitch : ''}}, fov: {{currentParams && currentParams.fov ? currentParams.fov : ''}}}
      template(v-for="camera in getAllUnitCams")
        button(
          :class="{'active': camera.guid === getCurrentCamera.guid}"
          @click="setCurrentCameraSceneParams({cameraGuid: camera.guid, showInitialViewParams: true})"
        )
          | {{camera.id}}: {{camera.getMeta('name', $i18n.locale)}}
</template>

<style lang="sass" scoped>
@import "../styles/variables"

.dev
  position: relative
  border: 1px solid red
  margin-top: 54px
  padding-top: 28px
  text-align: center
  padding-bottom: 8px
  color: white

  .title
    position: absolute
    left: 0
    top: 0
    padding: 6px 8px
    background-color: red
    color: white

  button
    cursor: pointer
    display: inline-block
    margin-left: 5px
    margin-bottom: 5px
    border: 1px solid black
    padding: 4px 8px

    &.active
      background-color: black
      color: white

.cp-unit
  position: relative
  padding-top: 25px
  @media (min-width: $size-desktop)
    padding-top: 25px

  .image-wrapper
    position: relative
    max-width: 100%
    margin: 0 auto
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3)
    @media (min-width: $size-tablet-landscape)
      max-width: 500px
      transform: translateX(80px)
    @media (min-width: $size-desktop)
      transform: translateX(0px)
      max-width: 650px
    @media (min-width: $size-desktop-wide)
      max-width: 850px
    @media (min-width: $size-desktop-4k)
      max-width: 1024px

  .jump-to-menu
    position: absolute
    left: 50%
    top: 100%
    transform: translate(-50%, -50%)

  .disclaimer
    text-align: center
    font-size: 0.8em
    opacity: 0.7
    max-width: 880px
    margin: 0 auto
    color: white

  .left-side
    @media (min-width: $size-tablet-landscape)
      position: absolute
      top: 25px
      left: 0
      z-index: 10
    @media (min-width: $size-desktop-4k)
      left: 10%
      .cp-box-info-unit
        margin-bottom: 130px
    .camera-list
      max-width: 300px
      margin-bottom: 32px
      @media (min-width: $size-desktop)
        max-width: 350px
      @media (min-width: $size-desktop-4k)
        margin-bottom: 64px
      .label
        color: white
        text-transform: uppercase
        margin-bottom: 8px
      .camera
        cursor: pointer
        display: inline-block
        margin-right: 5px
        margin-bottom: 5px
        border: 1px solid rgba(255, 255, 255, 0.25)
        border-radius: 3px
        padding: 4px 8px
        color: white
        transition: all 0.45s ease-out
        &:hover, &.active
          background-color: black
          color: white
          border: 1px solid black

  .cp-label-ref-unit
    margin-top: 62px
    margin-bottom: 48px
    color: white

  &::v-deep
    .hotspot
      cursor: pointer
      width: 36px
      height: 36px

  .cp-label-cam-title
    color: white
    opacity: 1
    text-transform: uppercase
    font-size: 0.8em
    letter-spacing: 0.05em
    font-weight: 600

    @media (min-width: $size-tablet-landscape)
      width: 50%
      left: 25%
      transform: translateX(80px)
    @media (min-width: $size-desktop)
      transform: translateX(0px)

  .cp-list-buttons
    margin-left: -32px

</style>

<script>
import CpButtonAction from '@inreal/flatyfind-ui/src/vue/buttons/CpButtonAction';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import CpWrapperImageView from '@inreal/flatyfind-ui/src/vue/wrappers/CpWrapperImageView';
import CpBoxInfoUnit from '@inreal/flatyfind-ui/src/vue/display/CpBoxInfoUnit';
import CpLabelRefUnit from '@inreal/flatyfind-ui/src/vue/labels/CpLabelRefUnit';
import CpLabelCamTitle from '@inreal/flatyfind-ui/src/vue/labels/CpLabelCamTitle';
import CpListButtons from '@inreal/flatyfind-ui/src/vue/lists/CpListButtons';
import {FEATURE_MODULE} from '@inreal/cockpit-library/src/js/enums';
import {ROUTE_NAMES} from '@inreal/flatyfind-ui/src/js/constants/routes';
import {createArraySorter} from '@inreal/core-library/src/js/array';
import {createUnitInfo} from '@inreal/cockpit-library/src/js/utils';
import {updatePageTitle} from '../lang';
import {views} from '../config/panos';

const cameraSorter = createArraySorter('sortIndex');

export default {
  name: 'CpUnit',
  components: {
    CpButtonAction,
    CpLabelRefUnit,
    CpLabelCamTitle,
    CpWrapperImageView,
    CpBoxInfoUnit,
    CpListButtons,
  },
  props: {
    guid: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      currentParams: null,
    };
  },
  computed: {
    ...mapGetters([
      'getIsDevMode',
      'getCurrentCameraSceneParams',
      'getCurrentCamera',
      'isCurrentCameraStillImage',
      'getNextConfigMenuRef',
    ]),
    ...mapGetters('inreal/projectSettings', [
      'getStaticAssetsBaseUrl',
      'getFeatureModules',
    ]),
    ...mapGetters('inreal/auth', [
      'getApolloClient',
      'getUser',
    ]),
    ...mapGetters('inreal/property', [
      'getCamerasOfUnit',
      'getUnitByGuid',
      'getUnitAttributeByGuid',
      'getUnitAttributeGroupByGuid',
      'getCamerasOfRoom',
      'getCameraById',
      'getCameraByGuid',
    ]),
    ...mapGetters('inreal/configuration', [
      'getVisualImageData',
      'getVisualImageUrl',
    ]),
    getBoxInfoUnitHeadline () {
      return '';
    },
    getBoxInfoUnitPrefix () {
      return '';
    },
    getLabelCameraList () {
      return this.$t('configurator.labelCameraList');
    },
    getBoxInfoUnitLabelButtonAction () {
      return this.$t('configurator.action');
    },
    getBoxInfoUnitLabelButtonBack () {
      return this.$t('configurator.back');
    },
    getBoxInfoUnitCopy () {
      const copy = [];
      const link = `${this.getStaticAssetsBaseUrl}/floorplans/${this.getUnit.getMeta('name')}.pdf`;
      copy.push({index: 0, value: this.$t('configurator.projectLabel')});
      copy.push({index: 1, value: this.getUnit.getMeta('project')});
      copy.push({index: 2, value: ''});
      copy.push({index: 3, value: this.$t('configurator.unitLabel')});
      copy.push({index: 4, value: this.getUnit.getMeta('name')});
      copy.push({index: 5, value: this.$t('navigator.unitType', {rooms: this.getUnit.getMeta('numberOfRooms')})});
      copy.push({index: 6, value: this.$t('navigator.unitSizeValue', {size: this.getUnit.getMeta('areaSize')})});
      copy.push({index: 7, value: ''});
      copy.push({index: 8, value: this.$t('configurator.floorplanLink', {link})});
      copy.sort((a, b) => {
        let keyA = a.index, keyB = b.index;
        if (keyA < keyB) {
          return -1;
        }
        if (keyA > keyB) {
          return 1;
        }
        return 0;
      });
      return copy.map(item => item.value);
    },
    getLabelRefUnit () {
      const unit = this.getUnit;
      return this.$t('configurator.refUnit', {rooms: unit.getMeta('numberOfRooms'), size: unit.getMeta('areaSize')});
    },
    getLabelOrder () {
      return this.$t('configurator.order');
    },
    getUnit () {
      return this.getUnitByGuid(this.guid);
    },
    getCameraList () {
      const currentCamera = this.getCurrentCamera;

      return this.getCamerasOfRoom(currentCamera?.primaryRoomGuid)
        .map(camera => {
          return {
            camera,
            active: currentCamera.guid === camera.guid,
            label: camera.getMeta('name', this.$i18n.locale),
          };
        });
    },
    getReferenceUnitGuid () {
      return this.getUnit.unitReferenceGuid;
    },
    getAllUnitCams () {
      let allUnitCams = this.getCamerasOfUnit(this.guid) == null || this.getCamerasOfUnit(this.guid).length === 0 ? this.getCamerasOfUnit(this.getReferenceUnitGuid) : this.getCamerasOfUnit(this.guid);
      return allUnitCams.sort(cameraSorter);
    },
    getSettingFeatureNavigator () {
      return this.getFeatureModules.includes(FEATURE_MODULE.NAVIGATOR);
    },
    getDisclaimer () {
      return this.$t('configurator.disclaimer');
    },
    getScenes () {
      return Object
        .entries(views)
        .reduce((accumulator, [key, value]) => {
          const cameraId = String(key);
          const cameraGuid = cameraId.length === 36 ? cameraId : this.getCameraById(cameraId).guid;
          const scene = {
            initialViewParameters: {
              yaw: value.yaw,
              pitch: value.pitch,
              fov: value.fov,
            },
          };

          if (value.hotspots != null) {
            scene.hotspots = value.hotspots.map(hotspot => {
              return {
                id: String(hotspot.id).length === 36 ? String(hotspot.id) : this.getCameraById(String(hotspot.id)).guid,
                type: hotspot.type ?? 'SCENE_LINK',
                imageSrc: `${this.getStaticAssetsBaseUrl}/${hotspot.imageFile}`,
                position: {
                  yaw: hotspot.position.yaw ?? 0,
                  pitch: hotspot.position.pitch ?? 0,
                  perspective: {
                    radius: hotspot.position.perspective?.radius ?? 700,
                  },
                },
              };
            });
          }

          accumulator[cameraGuid] = scene;
          return accumulator;
        }, {});
    },
    getSceneParams () {
      const params = this.getCurrentCameraSceneParams;

      return {
        id: params.cameraGuid,
        showInitialViewParams: params.showInitialViewParams,
      };
    },
    getVisualImage () {
      return cameraGuid => {
        return this.getVisualImageUrl(this.guid, cameraGuid);
      };
    },
    getJumpTargetLabel () {
      const ref = this.getNextConfigMenuRef(this.guid);
      const parts = [ref.label];
      if (ref.append != null) {
        parts.push(ref.append);
      }

      return this.$t('configurator.jumpTo', {target: parts.join(' - ')});
    },
  },
  watch: {},
  created () {
    updatePageTitle('unitconfiguration');
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    ...mapMutations([
      'setCurrentCameraSceneParams',
      'setConfigMenuState',
    ]),
    ...mapActions([
      'jumpToNextMenuDropdown',
    ]),
    ...mapActions('inreal/modalManager', [
      'openModal',
    ]),
    ...mapActions('inreal/inquiryItems', [
      'addInquiryItem',
    ]),
    ...mapActions('inreal/auth', [
      'loadUser',
    ]),
    async $_onClickOrder () {

      const unitInfo = createUnitInfo(this.getUnit, this.$i18n.locale);
      this.addInquiryItem({unit: unitInfo});

      const oderModal = await this.openModal({
        type: 'order-details',
        props: {
          unitGuid: this.guid,
          infoCopy: this.getBoxInfoUnitCopy,
          copyDivider: '',
        },
      });

      oderModal.onClose.then(data => {
        this.$_refetchUser();
      });
    },
    async $_refetchUser () {
      await this.getApolloClient.clearStore();
      await this.loadUser({force: true});
    },
    $_onCameraListClick (button) {
      this.setCurrentCameraSceneParams({
        cameraGuid: button.camera.guid,
        showInitialViewParams: true,
      });
    },
    $_onClickBack () {
      this.$router.push({
        name: ROUTE_NAMES.HOME,
      });
    },
    $_onClickHotspot (hotspot) {
      const camera = this.getCameraByGuid(hotspot.id);

      this.setCurrentCameraSceneParams({cameraGuid: camera.guid});
    },
    $_onParamsChange (params) {
      this.currentParams = params;
    },
  },
};
</script>
