<template lang="pug">
  .cp-goodbye
    .box
      .headline(v-html="getWelcomeHeadline")
      .copy(v-html="getGoodbyeCopy")

</template>

<style lang="sass" scoped>
@import "../styles/variables"

.cp-goodbye
  display: flex
  padding-top: 32px

  .box
    margin: 0 auto
    @media (min-width: $size-desktop)
      position: absolute
      top: 50%
      left: 50%
      transform: translateX(-50%) translateY(-50%)
    max-width: 600px
    color: white
    border-top: 1px solid rgba(255, 255, 255, 0.25)
    padding-top: 21px
    text-align: right

  .headline
    text-align: right
    font-size: 28px
    font-weight: 600
    text-transform: uppercase
  .copy
    text-align: right
    margin-top: 42px
    margin-bottom: 21px
    text-transform: uppercase


</style>

<script>
import {ROUTE_NAMES} from '@inreal/flatyfind-ui/src/js/constants/routes';
import {mapGetters} from 'vuex';
import {updatePageTitle} from '../lang';

export default {
  name: 'CpGoodBye',
  components: {
  },
  props: {
  },
  data () {
    return {
    };
  },
  computed: {
    getWelcomeHeadline () {
      return this.$t('welcome.title');
    },
    getGoodbyeCopy () {
      return this.$t('welcome.goodbye');
    },
  },
  watch: {},
  created () {
    updatePageTitle('goodbye');
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
  },
};
</script>
