<template lang="pug">
  .cp-app-loader
    .loader(v-if="isInitialPageLoading && getIsStandalone")
      div
        img(:src="getLogo")
        CfSpinner.loader-spinner(preset="small" :color="getColorBrand")
    .loader(v-else-if="isInitialPageLoading && !getIsStandalone")
      div
        .loader-text {{$t('general.initApp')}}
        CfSpinner.loader-spinner(preset="small" :color="getColorBrand")
    .app(v-if="!isInitialPageLoading" ref="app")
      CpApp
</template>

<style lang="sass" scoped>
@import '~@inreal/flatyfind-ui/src/sass/variables'
@import '~@inreal/flatyfind-ui/src/sass/components/app-loader'
@import 'styles/variables'

.loader
  background-color: $color-loader-background

</style>

<script>
import {mapGetters} from 'vuex';

import CfSpinner from '@inreal/ui/src/vue/loader/cf_spinner';
import variables from '../src/styles/_export.sass';
import CpApp from './CpApp';

export default {
  name: 'CpAppLoader',
  components: {
    CpApp,
    CfSpinner,
  },
  data () {
    return {
      variables,
    };
  },
  computed: {
    ...mapGetters([
      'isInitialPageLoading',
      'hasError',
      'getIsStandalone',
    ]),
    getColorBrand () {
      return this.variables['color-brand'];
    },
    getLogo () {
      return require('./assets/img/project-logo.png');
    },
  },
  mounted () {
    document.documentElement.classList.remove('md-theme-default');
  },
};
</script>
