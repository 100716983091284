<template lang="pug">
  .cp-unit-selection(:class="[{'standalone': getIsStandalone}, {'iframe': !getIsStandalone}]")
    transition(name="fade")
      CpButtonAction.button-to-overview(
        v-if="buildingSelected"
        :label="$t('navigator.backBtnTextShort')"
        isLight
        @click.native="$_resetBuildingFilter"
      )
    .wrapper(:class="{'has-hover-unit': getItemFocus, 'hidden': viewType === 'list'}")
      CpWrapperTurntable(
        :key-svg="getKeySvg"
        @clicked:svg-group="$_onClickedSvgGroup"
        @focus:item="$_onFocusItemInTurntable"
        :breakpoints="getBreakpoints"
        :item-focus-id="getItemFocusGuid"
        :items-displayed="getItemsDisplayedGuids"
        :items-highlighted="getItemsHighlighted"
        :aspect-ratio="getAspectRatio"
        :file-base-name="getFileBaseName"
        :file-base-path="getFileBasePath"
        :color-icon-hint="variables['color-hint']"
        :label-button-toggle="$t('ui.buttons.turnBuilding')"
        :label-instruction="getLabelInstruction"
        :label-building-formatter="$_formatLabelBuilding"
      )
        template(v-slot:arrowLeft)
          include ../assets/icons/icon-arrow-left.svg
        template(v-slot:arrowRight)
          include ../assets/icons/icon-arrow-right.svg
    .wrapper-filterbar(v-if="hasAttributeFilter")
      CpUnitFilter
    .wrapper-viewstyle
      button.toggle-viewstyle(@click="$_onViewToggleClick" :title="$t('ui.buttons.changeView')")
        span(v-if="viewType === 'list'") {{$t('ui.buttons.showBuilding')}}
        span(v-else) {{$t('ui.buttons.showList')}}
    .wrapper-list(:class="{'hidden': viewType === 'building'}")
      CpWrapperList(
        @clicked:row="$_onClickedRow"
        @clicked:tab-favorite="$_onClickedTabFavorites"
        @clicked:star-favorite="$_onClickedStarFavorites"
        @clicked:list="$_onClickedList"
        @focus:row="$_onFocusItemInList"
        :headers="getHeadersList"
        :rows="getRowsList"
        :variables="variables"
        :items-highlighted="getItemsHighlighted"
        :item-focus-id="getItemFocusId"
        :is-standalone="getIsStandalone"
        :is-side-menu-open="isSideMenuOpen"
        :is-unit-favorite="isUnitFavorite"
        :is-unit-available="isUnitAvailable"
        :count-total="getDisplayedUnits.length"
        :show-filter-count="hasAttributeFilter"
        :label-favorites="$t('unitTable.favorites', {count: getFavoritesCount})"
      )
    .wrapper-privacy--mobile(v-if="!getIsStandalone")
      CpButtonPrivacy.meta-element(:label="$t('navigation.privacyLabel')")
</template>

<style lang="sass" scoped>
@import '~@inreal/core-library/src/sass/poly-fluid-sizing'
@import '~@inreal/flatyfind-ui/src/sass/variables'
@import "~@inreal/flatyfind-ui/src/sass/components/unit-selection"
@import "../styles/variables"

.lang-select
  position: absolute
  top: 16px
  left: 16px

.button-to-overview
  position: absolute
  top: 0
  left: 0
  z-index: 1
</style>

<script>
import {mapActions, mapMutations, mapGetters} from 'vuex';
import {pushIfNotPresent} from '@inreal/core-library/src/js/array';
import CpButtonPrivacy from '@inreal/flatyfind-ui/src/vue/buttons/CpButtonPrivacy';

import CpWrapperTurntable from '@inreal/flatyfind-ui/src/vue/wrappers/CpWrapperTurntable';
import CpWrapperList from '@inreal/flatyfind-ui/src/vue/wrappers/CpWrapperList';
import {UNIT_STATUS_TYPE, UNIT_ATTRIBUTE_GROUP_TYPE, DISPLAY_TYPE} from '@inreal/cockpit-library/src/js/enums';
import CpButtonAction from '@inreal/flatyfind-ui/src/vue/buttons/CpButtonAction';
import variables from '../styles/_export.sass';
import {trackFilterEvent, trackUnitByAction, pushLeadToDataLayer} from '../common/TrackingService';

import CpUnitFilter from './aside/CpUnitFilter';

export default {
  name: 'CpUnitSelection',
  components: {
    CpUnitFilter,
    CpWrapperList,
    CpWrapperTurntable,
    CpButtonPrivacy,
    CpButtonAction,
  },
  data () {
    return {
      variables,
      viewType: 'building',
    };
  },
  computed: {
    ...mapGetters([
      'isSideMenuOpen',
      'getIsStandalone',
      'getItemFocus',
      'getPendingModal',
      'getPendingError',
    ]),
    ...mapGetters('inreal/favorites', [
      'isUnitFavorite',
      'getFavoritesCount',
      'getFavoriteUnitGuids',
    ]),
    ...mapGetters('inreal/projectSettings', [
      'getStatusFilter',
      'isMultiLanguage',
      'getSettingByKey',
    ]),
    ...mapGetters('inreal/unitFilter', [
      'getDisplayedUnits',
      'getFilteredUnits',
      'getAttributeFilterValues',
      'getAttributeFilterDefinitions',
      'getActiveLogicalAttributeFilterLabel',
      'getDisplayTypeOfUnit',
      'hasAttributeFilter',
    ]),
    ...mapGetters('inreal/property', [
      'getUnitByGuid',
      'getUnitById',
      'getUnitByName',
      'getUnitAttributeGroupByGuid',
      'getUnitAttributeByGuid',
      'getUnitAttributesOfUnitByGroupType',
      'getFilteredUnitsByUnitAttribute',
    ]),
    getKeySvg () {
      return this.getBuildingFilterValue;
    },
    getFileBaseName () {
      return 'dww';
    },
    getLocaleFilteredUnits () {
      return this.getFilteredUnits(this.$i18n.locale);
    },
    getAspectRatio () {
      switch (this.getBuildingFilterValue) {
        case 'total':
          return 1600 / 750;
        default:
          return 1600 / 900;
      }
    },
    getItemFocusGuid () {
      const item = this.getItemFocus;
      return item ? item.guid : null;
    },
    getItemFocusId () {
      const item = this.getItemFocus;
      return item ? item.id : null;
    },
    getItemsDisplayedGuids () {
      // collect unit guids, attribute floor guids und attribute building guids
      const allGuids = [];
      this.getLocaleFilteredUnits.forEach(unit => {
        pushIfNotPresent(allGuids, unit.guid);
        unit.unitAttributeGuids.forEach(guid => {
          const attr = this.getUnitAttributeByGuid(guid);
          const attrGroup = this.getUnitAttributeGroupByGuid(attr.unitAttributeGroupGuid);
          if (attrGroup.type === UNIT_ATTRIBUTE_GROUP_TYPE.FLOOR ||
              attrGroup.type === UNIT_ATTRIBUTE_GROUP_TYPE.BUILDING ||
              attrGroup.type === UNIT_ATTRIBUTE_GROUP_TYPE.SECTION) {
            pushIfNotPresent(allGuids, guid);
          }
        });
      });
      // add hotspots or other click elements in svg
      // pushIfNotPresent(allGuids, 'zf9dcb208-2b4f-47b7-bc1d-163a126fd883');
      return allGuids;
    },
    getItemsHighlighted () {
      let highlighFilterResult = [];
      let classNames = {[DISPLAY_TYPE.FLAGGED]: 'is-flagged', [DISPLAY_TYPE.DISABLED]: 'is-disabled'};
      const settings = this.getStatusFilter;
      settings.forEach(setting => {
        if (setting.displayType === DISPLAY_TYPE.FLAGGED || setting.displayType === DISPLAY_TYPE.DISABLED) {
          if (highlighFilterResult[setting.displayType] == null) {
            highlighFilterResult[setting.displayType] = [];
          }
          if (setting.unitStatus === UNIT_STATUS_TYPE.SOLD) {
            highlighFilterResult[setting.displayType].push(...this.getLocaleFilteredUnits.filter(unit => unit.status === UNIT_STATUS_TYPE.SOLD));
          }
          if (setting.unitStatus === UNIT_STATUS_TYPE.RESERVED) {
            highlighFilterResult[setting.displayType].push(...this.getLocaleFilteredUnits.filter(unit => unit.status === UNIT_STATUS_TYPE.RESERVED));
          }
        }
      });

      Object.keys(highlighFilterResult).forEach(type => {
        highlighFilterResult[type] = highlighFilterResult[type].map(unit => {
          let returnUnit = {...unit};
          returnUnit.name = returnUnit.guid;
          if (returnUnit.status === UNIT_STATUS_TYPE.SOLD) {
            returnUnit.highlightClasses = [classNames[type], `${classNames[type]}-sold`];
          }
          if (returnUnit.status === UNIT_STATUS_TYPE.RESERVED) {
            returnUnit.highlightClasses = [classNames[type], `${classNames[type]}-reserved`];
          }
          return returnUnit;
        });
      });

      let result = [];
      Object.values(highlighFilterResult).forEach(arr => {
        result = [].concat.apply(result, arr);
      });

      // Add maybe building classes if building are fully sold

      return result;
    },
    getLabelInstruction () {
      switch (this.getBuildingFilterValue) {
        case 'total':
          return this.$t('navigator.instructionBuilding');
        default:
          return this.$t('navigator.instructionUnit');
      }
    },
    getBuildingFilterValue () {
      const filterDefinitionEntry = Object.entries(this.getAttributeFilterDefinitions)
        .find(([guid, filterDefinition]) => {
          return filterDefinition.unitAttributeGroup.type === UNIT_ATTRIBUTE_GROUP_TYPE.BUILDING;
        });

      const unitAttributeGroupGuid = filterDefinitionEntry?.[0];
      const activeUnitAttributeGuid = this.getAttributeFilterValues[unitAttributeGroupGuid]?.[0];

      return activeUnitAttributeGuid ?? 'total';
    },
    buildingSelected () {
      return this.getBuildingFilterValue !== 'total';
    },
    getFileBasePath () {
      return `${this.getSettingByKey('static_assets_base_url')}/turntable/total/`;
    },
    getBreakpoints () {
      return [
        {key: 'west', value: 5, include: require('../assets-inline/total/05.svg')},
        {key: 'south', value: 30, include: require('../assets-inline/total/30.svg')},
        {key: 'east', value: 55, include: require('../assets-inline/total/55.svg')},
        {key: 'north', value: 80, include: require('../assets-inline/total/80.svg')},
      ];
    },
    getRowsList () {
      return this.getLocaleFilteredUnits.map(unit => {
        return {
          guid: unit.guid,
          id: unit.id,
          nr: unit.getMeta('name'),
          areasize: unit.getMeta('areaSize'),
          rooms: unit.getMeta('numberOfRooms'),
          floor: this.getUnitAttributesOfUnitByGroupType(unit, UNIT_ATTRIBUTE_GROUP_TYPE.FLOOR)[0],
        };
      });
    },
    getHeadersList () {
      return [
        {
          key: 'nr',
          label: this.$t('unitTable.nr'),
        },
        {
          key: 'areasize',
          label: this.$t('unitTable.areasize'),
          formatter: value => this.$t('general.areasize', {size: this.$n(value, 'float')}),
        },
        {
          key: 'rooms',
          label: this.$t('unitTable.rooms'),
        },
        {
          key: 'floor',
          sortBy: 'floor.sortIndex',
          label: this.$t('unitTable.floor'),
          formatter: value => value.getMeta('name'),
        },
        {
          key: 'favorite',
          sortable: false,
        },
        // {
        //   key: 'price',
        //   label: this.$t('unitTable.price'),
        // },
        // {
        //   key: 'unittype',
        //   label: this.$t('unitTable.unittype'),
        // },
      ];
    },
    isUnitAvailable () {
      return (unitGuid) => {
        const unit = this.getUnitByGuid(unitGuid);
        return unit.status === UNIT_STATUS_TYPE.AVAILABLE;
      };
    },
  },
  async mounted () {
    if (this.getPendingModal != null) {
      await this.openModal(this.getPendingModal);
      this.setPendingModal(null);
    }

    if (this.getPendingError != null) {
      await this.showError({type: this.getPendingError});
      this.setPendingError(null);
    }
  },
  methods: {
    ...mapMutations([
      'setItemFocus',
      'setPendingModal',
      'setPendingError',
      'setUnitSelectedFromSvg',
      'setSideMenuOpenStatus',
    ]),
    ...mapMutations('inreal/favorites', [
      'toggleFavoriteUnit',
    ]),
    ...mapMutations('inreal/unitFilter', [
      'setAttributeFilterValue',
    ]),
    ...mapActions('inreal/modalManager', [
      'openModal',
    ]),
    ...mapActions([
      'showError',
    ]),
    $_formatLabelBuilding (item) {
      if (this.getUnitByGuid(item.id)) {
        return this.$t('navigator.unitLabel', {name: this.getUnitByGuid(item.id).meta.default.name});
      } else if (this.getUnitAttributeByGuid(item.id)) {
        const attr = this.getUnitAttributeByGuid(item.id);
        const attrGroup = this.getUnitAttributeGroupByGuid(attr.unitAttributeGroupGuid);
        const unitsByAttribute = this.getFilteredUnitsByUnitAttribute(this.getLocaleFilteredUnits, item.id);
        return this.$t('navigator.attributeLabel', {group: attrGroup.getMeta('name'), name: attr.getMeta('name'), count: unitsByAttribute.length});
      } else {
        return item.id;
      }
    },
    $_onViewToggleClick () {
      if (this.viewType === 'list') {
        this.viewType = 'building';
      } else {
        this.viewType = 'list';
      }
    },
    $_onFocusItemInList (row) {
      if (row) {
        let itemFocus = this.getUnitByGuid(row.guid);

        // if first step is viewing buildings not units
        if (this.getBuildingFilterValue === 'total') {
          let buildingGuid = itemFocus.unitAttributeGuids.find(guid => {
            const attr = this.getUnitAttributeByGuid(guid);
            const attrGroup = this.getUnitAttributeGroupByGuid(attr.unitAttributeGroupGuid);
            return attrGroup.type === UNIT_ATTRIBUTE_GROUP_TYPE.BUILDING;
          });
          itemFocus = this.getUnitAttributeByGuid(buildingGuid);
        }
        this.setItemFocus(itemFocus);
      } else {
        this.setItemFocus(null);
      }
    },
    $_onFocusItemInTurntable (item) {
      if (item && item.focus) {
        let itemFocus = this.getUnitByGuid(item.id);
        if (this.getBuildingFilterValue === 'total') {
          itemFocus = this.getUnitAttributeByGuid(item.id);
        }
        this.setItemFocus(itemFocus);
      } else {
        this.setItemFocus(null);
      }
    },
    $_onClickedList () {
      this.setSideMenuOpenStatus(false);
    },
    $_onClickedStarFavorites (row) {
      /**
       * Tracking: track favorite unit
       */
      if (this.getFavoriteUnitGuids.indexOf(row.guid) !== -1) {
        trackUnitByAction(this.getUnitByGuid(row.guid), 'favorite');
      }
      this.toggleFavoriteUnit({unitGuid: row.guid});
    },
    $_onClickedTabFavorites () {
      this.openModal({type: 'favorites'});
    },
    $_onClickedSvgGroup (svg) {
      const id = svg.getAttribute('id');
      if (this.getUnitByGuid(id)) {
        this.$_onRowClick({id: this.getUnitByGuid(id).id}, true);
      } else if (this.getUnitAttributeByGuid(id)) {
        this.$_onAttributeClick(this.getUnitAttributeByGuid(id));
      } else {
        // iframe or extern
      }
    },
    $_onClickedRow (row) {
      this.$_onRowClick(row, false);
    },
    async $_onRowClick (row, callFromSvg = false) {
      const unit = this.getUnitById(row.id);

      /**
       * Tracking: track show unit and set from value for tracking
       */
      if (callFromSvg === true) {
        this.setUnitSelectedFromSvg(true);
      } else {
        this.setUnitSelectedFromSvg(null);
      }
      trackUnitByAction(unit, 'show');

      pushLeadToDataLayer();

      this.openUnit = unit;
      const infoBoxSub = await this.openModal({
        type: 'unit-details',
        props: {
          unitId: unit.guid,
        },
      });
      infoBoxSub.onClose.then(data => {
        if (data?.openModal != null) {
          this.openModal({type: data.openModal});
          this.openUnit = null;
        } else {
          this.openUnit = null;
        }
      });
    },
    $_onAttributeClick (attribute) {
      const unitAttributeGroup = this.getUnitAttributeGroupByGuid(attribute.unitAttributeGroupGuid);
      if (unitAttributeGroup.type === UNIT_ATTRIBUTE_GROUP_TYPE.BUILDING) {
        this.setAttributeFilterValue({unitAttributeGroupGuid: unitAttributeGroup.guid, value: [attribute.guid]});
        /**
         * Tracking: track filter event
         */
        const eventValue = attribute.getMeta('name');
        trackFilterEvent(unitAttributeGroup, eventValue, 'filter');
      }
    },
    $_resetBuildingFilter () {
      const filterDefinitionEntry = Object.entries(this.getAttributeFilterDefinitions)
        .find(([guid, filterDefinition]) => {
          return filterDefinition.unitAttributeGroup.type === UNIT_ATTRIBUTE_GROUP_TYPE.BUILDING;
        });

      const unitAttributeGroupGuid = filterDefinitionEntry?.[0];
      this.setAttributeFilterValue({unitAttributeGroupGuid, value: []});
    },
  },
};
</script>
