<template lang="pug">
  CpFormLayoutRegister.cp-form-logic-register(
    :salutation-options="getSalutationOptions"
    :title-options="getTitleOptions"
    :caption="$t('login.registerCaption')"
    :label-salutation="$t('form.salutation')"
    :label-title="$t('form.title')"
    :label-first-name="$t('form.firstName')"
    :label-first-name-missing="$t('validation.missingFirstName')"
    :label-last-name="$t('form.lastName')"
    :label-last-name-missing="$t('validation.missingName')"
    :label-phone="$t('form.phone')"
    :label-phone-missing="$t('validation.missingPhone')"
    :label-email="$t('form.eMail')"
    :label-email-missing="$t('validation.missingEmail')"
    :label-email-wrong-format="$t('validation.wrongEmailFormat')"
    :label-link-login-text="$t('login.requestLoginLinkText')"
    :text-privacy-disclaimer="$t('form.privacyDisclaimer', {hrefCustomer: $t('navigation.privacyLink'), hrefCustomerLabel: $t('form.hrefCustomerLabel'), hrefConfiguratorLabel: $t('navigation.privacyLabel')})"
    :label-button-register="$t('login.button.register')"
    :is-loading="isLoading"
    :user="$v.user"
    @click:privacy="$_onPrivacyClick",
    @click:register="$_onRegisterClick"
    @change:displayed-component="$emit('change:displayed-component', $event)"
  )
</template>

<style lang="sass" scoped>
@import '../../styles/variables'

.cp-form-logic-register
  &::v-deep
    .btn-disabled
      background-color: desaturate($color-brand, 100)

      &:hover
        background-color: desaturate($color-brand, 100)

    .privacy-link, a
      color: $color-brand
</style>

<script>
import {
  getAnalyticsVisitorId,
  hideLayer,
  setAppUserData,
  setComplianceSettings,
} from '@inreal/flatyfind-ui/src/js/tracking';
import {validationMixin} from 'vuelidate';
import {required, email} from 'vuelidate/lib/validators';
import {mapActions, mapGetters} from 'vuex';
import CpButtonAction from '@inreal/flatyfind-ui/src/vue/buttons/CpButtonAction';
import CpFormLayoutRegister from '@inreal/flatyfind-ui/src/vue/forms/CpFormLayoutRegister';
import {pushLeadToDataLayer} from '../../common/TrackingService';

export default {
  name: 'CpFormLogicRegister',
  components: {
    CpFormLayoutRegister,
    CpButtonAction,
  },
  mixins: [validationMixin],
  data () {
    return {
      user: {
        firstname: '',
        lastname: '',
        salutation: '',
        title: '',
        phone: '',
        email: '',
      },
      isLoading: false,
    };
  },
  validations: {
    user: {
      firstname: {
        required,
      },
      salutation: {
        required,
      },
      title: {},
      lastname: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters([
      'getTitleOptions',
      'getSalutationOptions',
    ]),
    ...mapGetters('inreal/projectSettings', [
      'isMultiLanguage',
      'getLanguages',
    ]),
  },
  created () {
    this.$_fillFormFields();
  },
  methods: {
    ...mapActions('inreal/auth', [
      'register',
    ]),
    ...mapActions([
      'registerAppuser',
      'showError',
    ]),
    $_fillFormFields () {
      this.user.salutation = this.getSalutationOptions[0].value;
      this.user.title = this.getTitleOptions[0].value;
    },
    $_onPrivacyClick () {
      this.$store.commit('inreal/consentManager/open', {mode: 'full-text'});
    },
    async $_onRegisterClick () {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      this.isLoading = true;
      this.user.piwikuserid = getAnalyticsVisitorId(this.$store);

      this.isMultiLanguage
        ? this.user.locale = this.$i18n.locale
        : this.user.locale = this.getLanguages[0];

      try {
        await this.register({...this.user});
        this.$emit('register-success');
      } catch (e) {
        const errorType = await this.$handleGraphQLErrors(e);
        await this.showError({type: errorType});
      } finally {
        /**
         * Tracking: set compliance, set user data and push event to dataLayer
         */
        await setComplianceSettings(this.$store);
        await setAppUserData(this.user.email, this.user.firstname, this.user.lastname, '', '', '', '', this.user.salutation, null, this.$store);
        pushLeadToDataLayer();
        // TODO: change to direct store mutation (see old flatyfind frontend)
        hideLayer();
        this.isLoading = false;
      }
    },
  },
};
</script>
