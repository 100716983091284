import {CAMERA_TYPE} from '@inreal/cockpit-library/src/js/enums';
import {clamp} from '@inreal/core-library/src/js/math';
import Vue from 'vue';
import i18n from '../../lang';

export default {
  state: () => ({
    isDevMode: false,
    isStandalone: false,
    initialLoad: true,
    displayedStartFilter: false,
    loginComponentToDisplay: 'register',
    floorplanRatio: 800 / 600,
    loaderQueues: {},
    error: null,
    pendingError: null,
    pendingModal: null,
    isSideMenuOpen: false,
    itemFocus: null,
    /**
     * @type {?{
     *   cameraGuid: string,
     *   showInitialViewParams: boolean,
     * }}
     */
    currentCameraSceneParams: null,
    /**
     * @type {?{
     *   index: number,
     *   open: boolean,
     *   scrollTo: boolean,

     * }}
     */
    activeConfigMenuPosition: {
      index: -1,
      open: false,
      scrollTo: false,
    },
    configMenuOpen: true,
  }),
  actions: {
    setPageMode ({commit, state, dispatch}) {
      if (window.location !== window.parent.location) {
        commit('setIsStandalone', false);
      } else {
        commit('setIsStandalone', true);
      }
    },
    async showError ({commit, dispatch, getters, rootGetters, state, rootState}, {type}) {
      commit('setError', type);
      const subscription = await dispatch('inreal/modalManager/openModal', {
        type: 'error-box',
      });

      subscription.onClose.then(() => {
        commit('setError', null);
      });
    },
    setActiveConfigMenuPosition ({commit, dispatch, getters, rootGetters, state, rootState}, {unitGuid, refName}) {
      const index = getters.getConfigMenuRefs(unitGuid)
        .findIndex(element => {
          return element.refName === refName;
        });

      state.activeConfigMenuPosition = {
        index,
        open: false,
        scrollTo: false,
      };
    },
    jumpToNextMenuDropdown ({commit, dispatch, getters, rootGetters, state, rootState}, {unitGuid}) {
      commit('setConfigMenuState', {open: true});

      const dropdowns = getters.getConfigMenuRefs(unitGuid);
      const index = clamp(
        state.activeConfigMenuPosition.index + 1,
        0,
        dropdowns.length - 1,
      );

      state.activeConfigMenuPosition = {
        index,
        open: true,
        scrollTo: true,
      };
    },
  },
  mutations: {
    setIsStandalone (state, mode) {
      state.isStandalone = mode;
    },
    setLoginComponentToDisplay (state, {componentName}) {
      state.loginComponentToDisplay = componentName;
    },
    setInitialLoad (state, {initialLoad}) {
      state.initialLoad = initialLoad;
    },
    loaderPush (state, id) {
      if (state.loaderQueues[id] == null) {
        Vue.set(state.loaderQueues, id, 0);
      }
      state.loaderQueues[id]++;
    },
    loaderPop (state, id) {
      if (state.loaderQueues[id] != null
        && state.loaderQueues[id] > 0) {
        state.loaderQueues[id]--;
      }
    },
    setError: (state, id) => {
      state.error = id;
    },
    setPendingError: (state, id) => {
      state.pendingError = id;
    },
    setPendingModal: (state, modalData) => {
      state.pendingModal = modalData;
    },
    setSideMenuOpenStatus: (state, status) => {
      state.isSideMenuOpen = status;
    },
    setFloorplanRatio: (state, ratio) => {
      state.floorplanRatio = ratio;
    },
    setDisplayedStartFilter: (state, status) => {
      state.displayedStartFilter = status;
    },
    setItemFocus: (state, payload) => {
      state.itemFocus = payload;
    },
    setCurrentCameraSceneParams (state, {cameraGuid, showInitialViewParams = false}) {
      state.currentCameraSceneParams = {
        cameraGuid,
        showInitialViewParams,
      };
    },
    setConfigMenuState (state, {open}) {
      state.configMenuOpen = open;
    },
    toggleConfigMenu (state) {
      state.configMenuOpen = !state.configMenuOpen;
    },
  },
  getters: {
    getIsDevMode: state => {
      return state.isDevMode;
    },
    getIsStandalone: state => {
      return state.isStandalone;
    },
    getDisplayedStartFilter: state => {
      return state.displayedStartFilter;
    },
    getLoginComponentToDisplay (state) {
      return state.loginComponentToDisplay;
    },
    isLoading: (state, getters, rootState, rootGetters) => id => {
      return state.loaderQueues[id] != null
        && state.loaderQueues[id] > 0;
    },
    isInitialPageLoading (state, getters, rootState, rootGetters) {
      return getters.isLoading('page') || state.initialLoad === true;
    },
    hasError: state => {
      return state.error != null;
    },
    getError: state => {
      return state.error;
    },
    getPendingError: state => {
      return state.pendingError;
    },
    getPendingModal: state => {
      return state.pendingModal;
    },
    isSideMenuOpen: state => {
      return state.isSideMenuOpen;
    },
    getFloorplanRatio: state => {
      return state.floorplanRatio;
    },
    getItemFocus (state) {
      return state.itemFocus;
    },
    getCurrentCameraSceneParams: (state, getters, rootState, rootGetters) => {
      return state.currentCameraSceneParams;
    },
    getCurrentCamera: (state, getters, rootState, rootGetters) => {
      return rootGetters['inreal/property/getCameraByGuid'](state.currentCameraSceneParams?.cameraGuid);
    },
    isCurrentCameraStillImage: (state, getters, rootState, rootGetters) => {
      const camera = rootGetters['inreal/property/getCameraByGuid'](getters.getCurrentCameraSceneParams.cameraGuid);

      return camera.type === CAMERA_TYPE.STILL;
    },
    getConfigMenuRefs: (state, getters, rootState, rootGetters) => unitGuid => {
      const result = [];

      if (rootGetters['inreal/configurationUi/isUnitStyleSelectVisible'](unitGuid)) {
        result.push({
          refName: unitGuid,
          label: i18n.t('configurator.styleSelect'),
        });
      }

      rootGetters['inreal/configurationUi/getMenuEntries'](unitGuid, i18n.locale)
        .forEach(menuEntry => {
          if (menuEntry.isStyleSelectVisible) {
            result.push({
              refName: menuEntry.guid,
              label: i18n.t('configurator.styleSelect'),
              append: menuEntry.name,
            });
          }

          menuEntry.options.forEach(option => {
            result.push({
              refName: option.guid,
              label: option.name,
              append: menuEntry.name,
            });
          });
        });

      return result;
    },
    getActiveConfigMenuPosition: (state, getters, rootState, rootGetters) => {
      return state.activeConfigMenuPosition;
    },
    getActiveConfigMenuRef: (state, getters, rootState, rootGetters) => unitGuid => {
      return getters.getConfigMenuRefs(unitGuid)[state.activeConfigMenuPosition.index];
    },
    getNextConfigMenuRef: (state, getters, rootState, rootGetters) => unitGuid => {
      return getters.getConfigMenuRefs(unitGuid)[state.activeConfigMenuPosition.index + 1];
    },
    getConfigMenuState: (state, getters, rootState, rootGetters) => {
      return state.configMenuOpen;
    },
  },
};
