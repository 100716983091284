import {createApolloClient} from '@inreal/cockpit-library/src/js/apollo';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {GRAPHQL_ERROR_CATEGORY} from '@inreal/cockpit-library/src/js/enums';
import store from '../store';
import i18n from '../lang';

Vue.use(VueApollo);

export const apolloClient = createApolloClient({
  apolloOptions: {
    uri: process.env.GRAPHQL_API_ENDPOINT,
    credentials: 'same-origin',
  },
  onGraphQLErrors (graphQLErrors, operation) {
    graphQLErrors.forEach(({message, locations, path, extensions}) => {
      if (extensions.category.toLowerCase() === GRAPHQL_ERROR_CATEGORY.AUTHENTICATION) {
        if (store.getters['inreal/auth/isLoggedIn']) {
          // this may get called multiple times
          // TODO: show session expired error
        }
        store.dispatch('inreal/auth/kick');
        return;
      }

      // TODO: sentry reporting
      // eslint-disable-next-line
      console.trace(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  },
  onNetworkError (networkError, operation) {
    // TODO: sentry reporting
    // eslint-disable-next-line
    console.log(`[Network error]: ${networkError}`);
  },
  getLanguage () {
    return i18n.locale;
  },
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
