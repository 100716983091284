import axios from 'axios';
import {ERROR_TYPE} from '@inreal/flatyfind-ui/src/js/constants/error-types';
import store from '../store';
import i18n from '../lang';

export const InquiryService = {
  sendInquiry (payload) {
    return axios.post('api/v2/inquiry', {inquiry: payload});
  },
};

export const PriceService = {
  requestPriceVariants (payload) {
    return axios.post('api/v2/fetch/prices/variants', payload);
  },
};

axios.interceptors.request.use(config => {
  config.headers['Content-Language'] = i18n.locale;

  return config;
});

axios.interceptors.response.use(response => {
  const {headers} = response;
  if (headers['content-type'] !== 'application/json') {
    store.dispatch('showError', {type: ERROR_TYPE.FORMAT_ERROR});
    return;
  }
  return response;
}, error => {
  const {status} = error.response;
  if (status >= 500) {
    store.dispatch('showError', {type: ERROR_TYPE.SERVER_ERROR});
  }
  return Promise.reject(error);
});
