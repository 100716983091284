<template lang="pug">
  CpLayoutUnitDetails.cp-logic-unit-details(
    v-on="$listeners"
    :close-label="$t('general.close')"
    :unit="unit"
    :unit-sold-or-reserved="isUnitSoldOrReserved"
    :is-unit-disabled="isUnitDisabled"
    :is-unit-configurable="isUnitConfigurable"
    :header-title="$t('ui.unitDetails.title')"
    :label-button="$t('ui.buttons.requestBtnTextSingle')"
    :images="floorPlanPreview"
    :color="$color('brand')"
    :color-inactive="$color('brand-inactive')"
    :show-pagination="floorPlanPreview.length > 1"
    :show-navigation="floorPlanPreview.length > 1"
    :ratio="getFloorplanRatio"
    :prevSlideMessage="$t('ui.slider.prevImage')"
    :nextSlideMessage="$t('ui.slider.nextImage')"
    :firstSlideMessage="$t('ui.slider.firstImageMessage')"
    :lastSlideMessage="$t('ui.slider.lastImageMessage')"
    @click:request-unit="$_onRequestUnitClick"
    @click:configurate-unit="$_onConfigurateUnitClick"
  )
    template(#unit-name)
      | {{$t('navigator.unitLabel', {name: unit.getMeta('name', $i18n.locale)})}}
    template(#unit-sold-or-reserved)
      | {{getUnitUnavailableText()}}
    template(#unit-details)
      CpListUnitDetails
        template(#unit-rooms)
          .key {{$t('navigator.unitRooms')}}
          .value {{unit.getMeta('numberOfRooms')}}
        template(#unit-floor)
          .key {{$t('navigator.unitFloor')}}
          .value {{getFloorName}}
        template(#unit-size)
          .key {{$t('navigator.unitSize')}}
          .value {{$t('navigator.unitSizeValue', {size: $n(unit.getMeta('areaSize'), 'float')}) }}
        template(#unit-price v-if="showPrice")
          .key {{$t('navigator.unitPrice')}}
          .value {{$n(unit.basePrice, 'currency')}}
        template(#unit-attributes-general v-if="showGenerals")
          CpTag(
            v-for="general of getUnitAttributesGeneral"
            :key="general.guid"
          ) {{general.meta.default.name}}
    template(#unit-actions)
      a.floorplan-link(
        v-if="hasPDFDisplay"
        :href="floorPlanLink"
        target="_blank"
        rel="noopener"
        @click="$_onFloorplanClick"
        )
        .logo
          CfIcon(id="pdf")
        .text {{$t('ui.buttons.pdfButtonText')}}
      button.walkthrough-btn(
        v-if="showIframeDisplay"
        @click="$_openWalkthroughClick"
      )
        .logo
          cf-icon(id="eye")
        .text {{$t('ui.buttons.walkthroughText')}}
</template>

<style lang="sass" scoped>
@import '../styles/variables'

.cp-logic-unit-details
  max-width: none
  max-height: 100%
  height: auto

  @media (min-width: $size-tablet)
    width: $infobox-width--tablet

  @media (min-width: $size-tablet-landscape)
    width: $infobox-width--tablet-landscape

  @media (min-width: $size-desktop)
    width: $infobox-width

  @media (min-width: $size-desktop-wide)
    width: $infobox-width--desktop-wide

  @media (min-width: $size-desktop-4k)
    width: $infobox-width--desktop-4k

  .floorplan-link
    padding-bottom: 6px
    justify-content: center
    @media (min-width: $size-tablet)
      justify-content: flex-start

  a.floorplan-link,
  .walkthrough-btn
    display: flex
    color: black
    text-decoration: none
    transition: box-shadow .45s ease
    border-radius: .6em
    align-items: center
    font-size: 16px

    &:hover
      cursor: pointer

      .text
        text-decoration: underline

    .text
      margin-left: 10px
      text-transform: uppercase
      font-weight: 600
</style>

<script>
import {mapGetters, mapActions} from 'vuex';
import CpLayoutUnitDetails from '@inreal/flatyfind-ui/src/vue/modals/CpLayoutUnitDetails';
import CpListUnitDetails from '@inreal/flatyfind-ui/src/vue/lists/CpListUnitDetails';
import {DISPLAY_TYPE, UNIT_ATTRIBUTE_GROUP_TYPE, UNIT_STATUS_TYPE, FEATURE_MODULE} from '@inreal/cockpit-library/src/js/enums';
import CfIcon from '@inreal/ui/src/vue/gfx/cf_icon';
import {ROUTE_NAMES} from '@inreal/flatyfind-ui/src/js/constants/routes';
import CpTag from '@inreal/flatyfind-ui/src/vue/display/CpTag';
import {createUnitInfo} from '@inreal/cockpit-library/src/js/utils';
import {trackUnitBySelect, trackFloorplan} from '../common/TrackingService';

export default {
  name: 'CpLogicUnitDetails',
  components: {
    CpLayoutUnitDetails,
    CpListUnitDetails,
    CfIcon,
    CpTag,
  },
  props: {
    unitId: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      UNIT_STATUS_TYPE,
    };
  },
  computed: {
    ...mapGetters('inreal/property', [
      'getUnitByGuid',
      'getUnitById',
      'getUnitAttributesOfUnitByGroupType',
    ]),
    ...mapGetters('inreal/unitFilter', [
      'getDisplayTypeOfUnit',
    ]),
    ...mapGetters('inreal/projectSettings', [
      'hasPriceDisplay',
      'getSettingByKey',
    ]),
    ...mapGetters('inreal/favorites', [
      'getFavoriteUnits',
    ]),
    ...mapGetters([
      'getUnitSelectedFromSvg',
      'getFloorplanRatio',
    ]),
    isPersistent () {
      return true;
    },
    unit () {
      return this.getUnitByGuid(this.unitId);
    },
    isUnitConfigurable () {
      return this.getSettingByKey('feature.modules').indexOf(FEATURE_MODULE.CONFIGURATOR) !== -1;
    },
    isUnitDisabled () {
      return this.getDisplayTypeOfUnit(this.unit.guid) === DISPLAY_TYPE.DISABLED;
    },
    isUnitFlagged () {
      return this.getDisplayTypeOfUnit(this.unit.guid) === DISPLAY_TYPE.FLAGGED;
    },
    isUnitSoldOrReserved () {
      return (this.unit.status === UNIT_STATUS_TYPE.SOLD || this.unit.status === UNIT_STATUS_TYPE.RESERVED) && (this.isUnitDisabled || this.isUnitFlagged);
    },
    hasPDFDisplay () {
      return this.getSettingByKey('feature.pdf_display');
    },
    getUnitUnavailableText () {
      return (showGeneralText = false) => {
        return showGeneralText
          ? this.$t('general.unitSoldOrReserved')
          : this.unit.status === UNIT_STATUS_TYPE.SOLD
            ? this.$t('general.unitSold')
            : this.$t('general.unitReserved');
      };
    },
    floorPlanPreview () {
      const floorAttributes = this.getUnitAttributesOfUnitByGroupType(this.unit, UNIT_ATTRIBUTE_GROUP_TYPE.FLOOR);
      let returnArray = [];
      if (floorAttributes.length > 1) {
        for (let i = 0; i < floorAttributes.length; i++) {
          returnArray.push(`${this.getSettingByKey('static_assets_base_url')}/floorplans/preview/${this.unit.getMeta('name', this.$i18n.locale)}-${i}.png`);
        }
      } else {
        returnArray.push(`${this.getSettingByKey('static_assets_base_url')}/floorplans/preview/${this.unit.getMeta('name', this.$i18n.locale)}.png`);
      }
      // The above code works for PDFs that are named only after the unit's name.
      returnArray = [
        'https://flatyfind-projects-static.s3.eu-central-1.amazonaws.com/cloud_pflugfelder_vogelsang/floorplans/preview/1.01.jpg',
        'https://flatyfind-projects-static.s3.eu-central-1.amazonaws.com/cloud_pflugfelder_vogelsang/floorplans/preview/1.02.jpg',
      ];
      return returnArray;
    },
    floorPlanLink () {
      return `${this.getSettingByKey('static_assets_base_url')}/floorplans/${this.unit.getMeta('name')}.pdf`;
    },
    getFloorName () {
      const floorAttributes = this.getUnitAttributesOfUnitByGroupType(this.unit, UNIT_ATTRIBUTE_GROUP_TYPE.FLOOR);
      if (floorAttributes.length === 1) {
        return floorAttributes[0].getMeta('name', this.$i18n.locale);
      } else {
        return `${floorAttributes[0].getMeta('name', this.$i18n.locale)} - ${floorAttributes[floorAttributes.length - 1].getMeta('name', this.$i18n.locale)}`;
      }
    },
    getUnitAttributesGeneral () {
      return this.getUnitAttributesOfUnitByGroupType(this.unit, UNIT_ATTRIBUTE_GROUP_TYPE.GENERAL);
    },
    showPrice () {
      return this.unit.basePrice != null && this.unit.basePrice > 0 && !this.isUnitDisabled && this.hasPriceDisplay;
    },
    showGenerals () {
      return this.getUnitAttributesGeneral.length > 0;
    },
    showIframeDisplay () {
      return this.getSettingByKey('feature.iframe_display');
    },
  },
  methods: {
    ...mapActions('inreal/inquiryItems', [
      'addInquiryItem',
    ]),
    ...mapActions('inreal/modalManager', [
      'openModal',
    ]),
    $_openWalkthroughClick () {
      this.openModal({type: 'iframe-display', props: {unitId: this.unitId}});
    },
    $_onRequestUnitClick () {
      /**
       * Tracking: track unit select
       */
      trackUnitBySelect(this.unit, this.getUnitSelectedFromSvg);

      const unit = createUnitInfo(this.unit, this.$i18n.locale);
      this.addInquiryItem({unit});
      this.$emit('close', {openModal: 'inquiry'});
    },

    $_onConfigurateUnitClick () {
      /**
       * Tracking: track unit select
       */
      trackUnitBySelect(this.unit, this.getUnitSelectedFromSvg);

      this.$emit('close');
      this.$router.push({
        name: ROUTE_NAMES.UNIT,
        params: {
          guid: this.unit.guid,
        },
      });
    },
    $_onFloorplanClick () {
      /**
       * Tracking: track floorplan view
       */
      trackFloorplan(this.unit);
    },
  },
};
</script>
