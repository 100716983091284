<template lang="pug">
  CpLayoutFavorites.cp-logic-favorites(
    v-on="$listeners"
    :close-label="$t('general.close')"
    :header-title="$t('general.favorites')"
    :color="$color('brand')"
    :no-favorites-text="$t('general.noFavoritesText')"
    :button-label="$t('general.appointment')"
    :favorite-units="getFavoriteUnits"
    :headers="headers"
    :rows="rows"
    :is-unit-favorite="isUnitFavorite"
    @click:appointment="$_onAppointmentClick"
    @click:star="$_onStarClick($event)"
  )
</template>

<style lang="sass" scoped>
@import '../styles/variables'

.cp-layout-favorites
  &::v-deep
    .thead
      .th
        background-color: $color-data-table-background
        border-bottom: 1px solid $color-data-table-header-border

      .th .label
        color: $color-brand

    .tbody .tr
      .is-even
        background-color: $color-ui-background-light

      &:hover,
      &.is-hover,
      &.is-selected
        background-color: rgba($color-brand, .4) !important

    .tbody .tr:nth-child(even)
      background-color: rgba($color-brand, 0.1)
</style>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';
import {UNIT_ATTRIBUTE_GROUP_TYPE} from '@inreal/cockpit-library/src/js/enums';
import CpLayoutFavorites from '@inreal/flatyfind-ui/src/vue/modals/CpLayoutFavorites';
import {createUnitInfo} from '@inreal/cockpit-library/src/js/utils';
import {updatePageTitle} from '../lang';
import {trackCustomUrlByPath} from '../common/TrackingService';

export default {
  name: 'CpLogicFavorites',
  components: {
    CpLayoutFavorites,
  },
  computed: {
    ...mapGetters('inreal/property', [
      'getUnitAttributesOfUnitByGroupType',
    ]),
    ...mapGetters('inreal/favorites', [
      'getFavoriteUnitGuids',
      'getFavoriteUnits',
      'isUnitFavorite',
    ]),
    headers () {
      return [
        {
          key: 'nr',
          label: this.$t('unitTable.nr'),
        },
        {
          key: 'areasize',
          label: this.$t('unitTable.areasize'),
          formatter: value => this.$t('general.areasize', {size: this.$n(value, 'float')}),
        },
        {
          key: 'rooms',
          label: this.$t('unitTable.rooms'),
        },
        {
          key: 'floor',
          sortBy: 'floor.sortIndex',
          label: this.$t('unitTable.floor'),
          formatter: value => value.getMeta('name'),
        },
        {
          key: 'favorite',
          sortable: false,
        },
      ];
    },
    rows () {
      return this.getFavoriteUnits.map(unit => {
        return {
          guid: unit.guid,
          id: unit.id,
          nr: unit.getMeta('name'),
          areasize: unit.getMeta('areaSize'),
          rooms: unit.getMeta('numberOfRooms'),
          floor: this.getUnitAttributesOfUnitByGroupType(unit, UNIT_ATTRIBUTE_GROUP_TYPE.FLOOR)[0],
        };
      });
    },
  },
  created () {
    updatePageTitle('favorites');
    /**
     * Tracking: track custom url as page view
     */
    trackCustomUrlByPath(this.$route.path);
  },
  methods: {
    ...mapMutations('inreal/favorites', [
      'toggleFavoriteUnit',
    ]),
    ...mapActions('inreal/inquiryItems', [
      'addInquiryItem',
    ]),
    ...mapActions('inreal/modalManager', [
      'openModal',
    ]),
    $_onStarClick (row) {
      this.toggleFavoriteUnit({unitGuid: row.guid});
    },
    $_onAppointmentClick () {
      this.getFavoriteUnits.forEach((favoriteUnit) => {
        const unit = createUnitInfo(favoriteUnit, this.$i18n.locale);
        this.addInquiryItem({unit});
      });

      this.$emit('close');
      this.openModal({type: 'inquiry'});
    },
  },
};
</script>
