<template lang="pug">
  CpFormLayoutLogin.cp-form-logic-login(
    :caption="$t('login.loginCaption')"
    :label-email="$t('form.eMail')"
    :label-email-missing="$t('validation.missingEmail')"
    :label-email-wrong-format="$t('validation.wrongEmailFormat')"
    :label-password="$t('form.password')"
    :label-password-missing="$t('validation.missingPassword')"
    :label-button-login="$t('login.button.login')"
    :label-link-register-text="$t('login.linkRegisterText')"
    :label-link-password-reset="$t('login.linkPasswordResetText')"
    :is-loading="isLoading"
    :user="$v.user"
    @click:login="$_onLoginClick"
    @login-success="$emit('close', {type: 'start-filter'})"
    @change:displayed-component="$emit('change:displayed-component', $event)"
  )
</template>

<style lang="sass" scoped>
</style>

<script>
import {mapActions} from 'vuex';
import {validationMixin} from 'vuelidate';
import {email, required} from 'vuelidate/lib/validators';
import CpFormLayoutLogin from '@inreal/flatyfind-ui/src/vue/forms/CpFormLayoutLogin';

export default {
  name: 'CpFormLogicLogin',
  components: {
    CpFormLayoutLogin,
  },
  mixins: [validationMixin],
  data () {
    return {
      user: {
        email: '',
        password: '',
      },
      isLoading: false,
    };
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions([
      'showError',
    ]),
    ...mapActions('inreal/auth', [
      'login',
    ]),
    async $_onLoginClick () {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      try {
        this.isLoading = true;
        await this.login({
          email: this.user.email,
          password: this.user.password,
          remember: true,
        });
        this.$emit('login-success');
      } catch (e) {
        const errorType = await this.$handleGraphQLErrors(e);
        await this.showError({type: errorType});
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
