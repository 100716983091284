import CpLogicUnitDetails from './CpLogicUnitDetails';
import CpLogicNotification from './CpLogicNotification';
import CpStartFilter from './CpStartFilter';
import CpLogicErrorBox from './CpLogicErrorBox';
import CpInquiry from './CpInquiry';
import CpLogin from './CpLogin';
import CpLogicIframeDisplay from './CpLogicIframeDisplay';
import CpLogicFavorites from './CpLogicFavorites';
import CpLogicVariantDetails from './CpLogicVariantDetails';
import CpLogicOrderDetails from './CpLogicOrderDetails';
import CpLogicUserDetails from './CpLogicUserDetails';

export const registry = {
  'login': CpLogin,
  'notification': CpLogicNotification,
  'error-box': CpLogicErrorBox,
  'unit-details': CpLogicUnitDetails,
  'start-filter': CpStartFilter,
  'inquiry': CpInquiry,
  'favorites': CpLogicFavorites,
  'iframe-display': CpLogicIframeDisplay,
  'variant-details': CpLogicVariantDetails,
  'order-details': CpLogicOrderDetails,
  'user-details': CpLogicUserDetails,
};
