<template lang="pug">
  .cp-unit-configuration(:class="{'opened': getConfigMenuState}")
    CpConfigBar.title-bar(
      :background-color="$color('background-dark')"
      :color="$color('text-light')"
      :border-color="'#333333'"
      :start-icon-svg="$options.icons.mdiTune"
      @click:start="$_onMenuToggle"
      :end-icon-svg="$options.icons.mdiClose"
      @click:end="$_onMenuToggle"
    )
      template(#center)
        | {{$t('configurator.title')}}
    CfScrollbar.scrollbar(ref="scrollbar")
      .scroll-content
        // Unit styles
        .menu-entry(v-if="isUnitStyleSelectVisible(unitGuid)")
          CfDropdown(
            keep-dom
            sticky
            :ref="unitGuid"
          )
            template(#head="{toggle, isOpen}")
              CpConfigBar(
                :background-color="$color('background-highlight')"
                :color="$color('text-light')"
                :border-color="$color('text-light')"
                :end-icon-svg="$options.icons.mdiMenuDown"
                :end-icon-rotate="isOpen"
                button
                @click.native="toggle(); setActiveConfigMenuPosition({unitGuid, refName: unitGuid})"
              )
                template(#start)
                  | 1.
                template(#center)
                  | {{$t('configurator.styleSelect')}}
            template(#body)
              // Styles
              .style-container
                button(
                  v-for="style of getStylesOfUnit(unitGuid)"
                  :key="style.guid"
                  @click="$_onUnitStyleClick(style.guid)"
                )
                  CpConfigVariant(
                    :selected="isStyleInUnitSelected(unitGuid, style.guid)"
                    :is-default="isStyleInUnitDefault(unitGuid, style.guid)"
                    :image-src="getThumbnailUrl(style.guid)"
                    @click:info="$_onStyleInfoClick(style)"
                  )
                    | {{style.getMeta('name', $i18n.locale)}}
        // Rooms/Boundoptions/Extras
        .menu-entry(
          v-for="menuEntry of getMenuEntries(unitGuid, $i18n.locale)"
          :key="menuEntry.guid"
        )
          // Bar
          CpConfigBar(
            :background-color="$color('background-dark-semi')"
            :color="$color('text-light')"
            :border-color="$color('text-light')"
            v-bind="getMenuEntryConfigBarProps(menuEntry)"
            @click.native="$_onEyeClick(menuEntry)"
          )
            template(#start)
              | {{menuEntry.count}}.
            template(#center)
              | {{menuEntry.name}}
          // Style
          CfDropdown(
            v-if="menuEntry.isStyleSelectVisible"
            sticky
            keep-dom
            :ref="menuEntry.guid"
          )
            template(#head="{toggle, isOpen}")
              CpConfigBar(
                :background-color="$color('background-highlight')"
                :color="$color('text-light')"
                :border-color="'#E8E2DC'"
                :end-icon-svg="$options.icons.mdiMenuDown"
                :end-icon-rotate="isOpen"
                button
                @click.native="toggle(); setActiveConfigMenuPosition({unitGuid, refName: menuEntry.guid})"
              )
                template(#start)
                  | TODO
                template(#center)
                  | {{$t('configurator.styleSelect')}}
            template(#body)
              // Styles
              .style-container
                button(
                  v-for="style of menuEntry.styles"
                  :key="style.guid"
                  @click="$_onMenuEntryStyleClick(menuEntry, style.guid)"
                )
                  CpConfigVariant(
                    info-button
                    :selected="style.isSelected"
                    :is-default="style.isDefault"
                    :image-src="style.thumbnailUrl"
                    @click:info="$_onStyleInfoClick(style.model)"
                  )
                    | {{style.name}}
          // Options
          CfDropdown.option-dropdown(
            v-for="option of menuEntry.options"
            :key="option.guid"
            keep-dom
            sticky
            :ref="option.guid"
          )
            template(#head="{toggle, isOpen}")
              CpConfigBar(
                :background-color="$color('background-light')"
                :color="$color('text-dark')"
                :border-color="'rgba(161, 123, 87, 0.1)'"
                :end-icon-svg="$options.icons.mdiMenuDown"
                :end-icon-rotate="isOpen"
                end-icon-size="s"
                button
                @click.native="toggle(); setActiveConfigMenuPosition({unitGuid, refName: option.guid})"
              )
                template(#start)
                  CfIcon(:id="getIconIdForOption(option.guid)")
                template(#center)
                  | {{option.name}}
            template(#body)
              // Variants
              .variant-container
                template(
                  v-for="variant of option.variants"
                )
                  template(v-if="variant.isGroup")
                    CfDropdown.group-dropdown(keep-dom)
                      template(#head="{toggle, isOpen}")
                        CpConfigBar.group-button(
                          @click.native="toggle()"
                          button
                          :end-icon-svg="$options.icons.mdiMenuDown"
                          :end-icon-rotate="isOpen"
                          end-icon-size="s"
                        )
                          template(#center)
                            .group-label
                              | {{variant.name}}
                      template(#body)
                        .variant-group-container
                          button(
                            v-for="variantInGroup of variant.variants"
                            :key="variantInGroup.guid"
                            @click="$_onVariantClick(menuEntry, option.guid, variantInGroup.guid)"
                          )
                            CpConfigVariant(
                              info-button
                              :selected="variantInGroup.isSelected"
                              :is-default="variantInGroup.isDefault"
                              :image-src="variantInGroup.thumbnailUrl"
                              @click:info="$_onVariantInfoClick(variantInGroup.model)"
                            )
                              span.label
                                | {{variantInGroup.name}}
                              span.price(v-if="getPriceValueOfVariant")
                                | {{getPriceFormattedOfVariant(variantInGroup.guid, $_formatPrice, 'default')}}
                  template(v-else)
                    button(
                      :key="variant.guid"
                      @click="$_onVariantClick(menuEntry, option.guid, variant.guid)"
                    )
                      CpConfigVariant(
                        info-button
                        :selected="variant.isSelected"
                        :is-default="variant.isDefault"
                        :image-src="variant.thumbnailUrl"
                        @click:info="$_onVariantInfoClick(variant.model)"
                      )
                        span.label
                          | {{variant.name}}
                        span.price(v-if="getPriceValueOfVariant")
                          | {{getPriceFormattedOfVariant(variant.guid, $_formatPrice, 'default')}}
</template>

<style lang="sass" scoped>
@import "../../styles/variables"

.cp-unit-configuration
  display: flex
  flex-direction: column
  background-color: $color-background-dark
  overflow: hidden

.title-bar
  flex: none

.scrollbar
  position: relative
  flex: 1 1 auto
  min-height: 0

.menu-entry
  margin-bottom: 20px
  background-color: $color-background-light

.style-container,
.variant-container,
.variant-group-container
  padding: 10px
  transition: padding .3s ease-out

  & > *
    margin-bottom: 10px
    transition: margin-bottom .3s ease-out

    &:last-child
      margin-bottom: 0

.group-dropdown
  margin-left: -10px
  margin-right: -10px
  border-top: 1px solid #E8E2DC
  border-bottom: 1px solid #E8E2DC

.group-button::v-deep .inner-wrapper
  height: 40px

  .bar-start
    display: none

  .bar-center
    margin-left: 66px
    text-transform: none

  .bar-end
    margin-right: 16px

.option-dropdown
  border-top: 1px solid #E8E2DC
  border-bottom: 1px solid #E8E2DC

.cp-config-variant
  &::v-deep
    span.price
      display: block
      font-weight: 600
      font-style: italic
      font-size: 0.9em

.cp-unit-configuration:not(.opened)
  .style-container,
  .variant-container,
  .variant-group-container
    padding: 0

    & > *
      margin-bottom: 4px

      &:last-child
        margin-bottom: 0

  .variant-group-container
    margin-left: 10px

button
  display: block
  width: 100%
  cursor: pointer
</style>

<script>
import {MENU_ENTRY_TYPE} from '@inreal/cockpit-library/src/js/enums';
import CfDropdown from '@inreal/ui/src/vue/container/cf_dropdown';
import CfScrollbar from '@inreal/ui/src/vue/container/cf_scrollbar';
import CfMdiSvgIcon from '@inreal/ui/src/vue/gfx/cf_mdi_svg_icon';
import CfIcon from '@inreal/ui/src/vue/gfx/cf_icon';
import {ICONS} from '@inreal/ui/src/js/gfx/icons';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {mdiTune, mdiClose, mdiEye, mdiMenuDown, mdiHomePlus} from '@mdi/js';
import gsap from 'gsap';
import CpConfigBar from '../../components/CpConfigBar';
import CpConfigVariant from '../../components/CpConfigVariant';
import {PriceService} from '../../common/ApiService';


export default {
  name: 'CpUnitConfiguration',
  components: {CfDropdown, CfScrollbar, CpConfigVariant, CpConfigBar, CfIcon, CfMdiSvgIcon},
  props: {
    guid: {
      type: String,
      required: true,
    },
  },
  icons: {
    mdiTune,
    mdiClose,
    mdiEye,
    mdiMenuDown,
    mdiHomePlus,
  },
  MENU_ENTRY_TYPE,
  data () {
    return {
      scrollAnimation: null,
    };
  },
  computed: {
    ...mapGetters([
      'getActiveConfigMenuRef',
      'getActiveConfigMenuPosition',
      'getConfigMenuState',
      'getCurrentCamera',
    ]),
    ...mapGetters('inreal/property', [
      'getUnitByGuid',
      'getThumbnailUrl',
      'getReferenceUnitOfUnit',
      'getRoomsOfUnit',
      'getExtrasOfUnit',
    ]),
    ...mapGetters('inreal/prices', [
      'getPricesOfVariants',
      'getPriceValueOfVariant',
      'getPriceFormattedOfVariant',
    ]),
    ...mapGetters('inreal/configurationUi', [
      'isUnitStyleSelectVisible',
      'getStylesOfUnit',
      'isStyleInUnitDefault',
      'isStyleInUnitSelected',
      'getMenuEntries',
      'isCameraInMenuEntryForOption',
    ]),
    unitGuid () {
      return this.guid;
    },
    getMenuEntryConfigBarProps () {
      return menuEntry => {
        const props = {};

        if (menuEntry.type === MENU_ENTRY_TYPE.BOUND_OPTION
          || menuEntry.type === MENU_ENTRY_TYPE.ROOM) {
          props['end-icon-svg'] = mdiEye;
          props['button'] = true;
        }

        return props;
      };
    },
    activeMenuRef () {
      return this.getActiveConfigMenuRef(this.unitGuid);
    },
  },
  watch: {
    activeMenuRef: {
      handler (menuRef) {
        if (menuRef != null) {
          this.$_jumpToMenuRef(menuRef);
        }
      },
    },
  },
  mounted () {
    if (this.activeMenuRef != null) {
      this.$_jumpToMenuRef(this.activeMenuRef);
    }
    this.$_loadPricesOfVariants();
    // TODO: show howto modal
  },
  beforeDestroy () {
  },
  methods: {
    ...mapMutations([
      'setCurrentCameraSceneParams',
      'toggleConfigMenu',
    ]),
    ...mapMutations('inreal/prices', [
      'setPricesOfVariants',
    ]),
    ...mapActions([
      'setActiveConfigMenuPosition',
    ]),
    ...mapActions('inreal/configurationUi', [
      'toggleStyleInUnit',
      'toggleVariantInMenuEntry',
      'toggleStyleInMenuEntry',
    ]),
    ...mapActions('inreal/modalManager', [
      'openModal',
    ]),
    getIconIdForOption (guid) {
      switch (guid) {
        case 'bce7b175-4989-ee03-6631-03a3649c3180':
        case '395f46f4-4497-dd63-0936-3e972f2678fa':
          return ICONS.FLOOR;
        case '3bd558a1-46e8-3b6c-8006-e7ae54e5e727':
        case '1642682c-9917-4ee8-aa01-48cc56782444':
        case 'bd16b717-a97d-4139-ae46-c9173db717c9':
          return ICONS.SKIRTING;
        case '9684558e-42b6-47a9-12d9-d9b2b8b5743d':
          return ICONS.CUPBOARD;
        case '21b9afd0-486e-01b5-4c88-92ac20dd8f30':
          return ICONS.SHOWER;
        case '3255ae23-4cea-5d52-b5b8-c19d7f2c8fe7':
        case 'c17b2e88-84bf-487a-b38c-d2f4a67a613f':
          return ICONS.FLOOR_TILES;
        case '7c5663e0-4145-0538-103b-0881cada18c9':
          return ICONS.WALL_TILES;
        case '493976d2-85f0-419c-a932-83d67ceb59d7':
          return ICONS.SHOWER_BOX;
        case '8e53c314-453c-408f-9d45-c92744283544':
          return ICONS.WC;
        case 'f59a95d8-5545-4dde-af33-79cd1bdf49e2':
        case '976955bd-2fcd-4332-a978-63e1d8396530':
        case '25659249-e40f-4a0e-867e-13efb41b265e':
        case 'bebc0759-2fca-43ab-91dd-7fda4aa73dba':
          return ICONS.POWER_SOCKET;
        case '8dd46c6e-035d-40b0-be9e-cef6d8550ffa':
          return ICONS.PARAPET;
        default:
          return ICONS.CERAMIC;
      }
    },
    $_formatPrice (price) {
      return this.$n(price / 100, 'currency');
    },

    $_stringToSlug (str) {
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
      const to = 'aaaaaaeeeeiiiioooouuuunc------';

      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes
        .replace(/^-+/, '') // trim - from start of text
        .replace(/-+$/, ''); // trim - from end of text

      return str;
    },
    $_loadPricesOfVariants () {
      const refUnit = this.getReferenceUnitOfUnit(this.unitGuid);
      const unit = this.getUnitByGuid(this.unitGuid);
      const roomsOfRefUnit = this.getRoomsOfUnit(refUnit.guid);
      // Ref unit does not have extras.
      // const extrasOfRefUnit = this.getExtrasOfUnit(refUnit.guid);
      const roomsOfUnit = this.getRoomsOfUnit(this.unitGuid);
      const extrasOfUnit = this.getExtrasOfUnit(this.unitGuid);

      let variants = [];
      roomsOfRefUnit.forEach(room => {
        variants = variants.concat(room.variantGuids);
      });
      // extrasOfRefUnit.forEach(extra => {
      //   variants = variants.concat(extra.variantGuids);
      // });
      roomsOfUnit.forEach(room => {
        variants = variants.concat(room.variantGuids);
      });
      extrasOfUnit.forEach(extra => {
        variants = variants.concat(extra.variantGuids);
      });

      const unitKey = this.$_stringToSlug(`${unit.getMeta('project')}-${unit.getMeta('numberOfRooms')}-zimmer`);

      const priceRequest = {
        unitGuid: unitKey,
        variants,
        locale: this.$i18n.locale,
      };
      PriceService.requestPriceVariants(priceRequest).then(response => {
        const variants = {};
        if (response.data && response.data.length > 0) {
          response.data.forEach(item => {
            variants[item.variantGuid] = item;
            if (variants[item.variantGuid].label.default == null) {
              variants[item.variantGuid].label = JSON.parse(variants[item.variantGuid].label);
            }
          });
        }
        this.setPricesOfVariants({variants});
      }).catch(error => {
        // eslint-disable-next-line
        console.warn(error);
      });
    },
    $_jumpToMenuRef (menuRef) {
      if (this.scrollAnimation != null) {
        this.scrollAnimation.kill();
      }

      const dropdown = this.$refs[menuRef.refName]?.[0];
      const {open, scrollTo} = this.getActiveConfigMenuPosition;

      // If the dropdown should be opened
      if (open === true) {
        const wasOpen = dropdown.getActualValue;

        dropdown.open();

        // If it should be scrolled in
        if (scrollTo === true) {
          // If it was already open, scroll immediately
          if (wasOpen === true) {
            this.$_scrollToMenuElement(dropdown.$el);
          } else {
            // Otherwise wait for opened event, then scroll
            dropdown.$once('opened', () => {
              this.$_scrollToMenuElement(dropdown.$el);
            });
          }
        }
      } else if (scrollTo === true) {
        this.$_scrollToMenuElement(dropdown.$el);
      }
    },
    $_scrollToMenuElement (element) {
      if (this.scrollAnimation != null) {
        this.scrollAnimation.kill();
      }

      const scrollbar = this.$refs.scrollbar;

      this.scrollAnimation = gsap.to(scrollbar.scroller.getScrollElement(), {
        duration: .35,
        scrollTo: {y: element},
      });
    },
    $_onMenuToggle () {
      this.toggleConfigMenu();
    },
    $_onEyeClick (menuEntry) {
      const camera = menuEntry.camera;
      if (camera != null) {
        this.setCurrentCameraSceneParams({
          cameraGuid: camera.guid,
          showInitialViewParams: true,
        });
      }
    },
    $_onUnitStyleClick (styleGuid) {
      this.toggleStyleInUnit({
        unitGuid: this.unitGuid,
        styleGuid,
      });
    },
    $_onMenuEntryStyleClick (menuEntry, styleGuid) {
      const camera = menuEntry.camera;
      if (camera != null
        && !this.isCameraInMenuEntryForOption(this.getCurrentCamera.guid, menuEntry)) {
        this.setCurrentCameraSceneParams({cameraGuid: camera.guid});
      }

      this.toggleStyleInMenuEntry({
        unitGuid: this.unitGuid,
        menuEntry,
        styleGuid,
      });
    },
    $_onVariantClick (menuEntry, optionGuid, variantGuid) {
      const camera = menuEntry.camera;
      if (camera != null
        && !this.isCameraInMenuEntryForOption(this.getCurrentCamera.guid, menuEntry, optionGuid)) {
        this.setCurrentCameraSceneParams({cameraGuid: camera.guid});
      }

      this.toggleVariantInMenuEntry({
        unitGuid: this.unitGuid,
        menuEntry,
        optionGuid,
        variantGuid,
      });
    },
    $_onStyleInfoClick (style) {
      // TODO: show info modal
    },
    async $_onVariantInfoClick (variant) {
      await this.openModal({
        type: 'variant-details',
        props: {
          variant,
        },
      });
    },
  },
};
</script>
