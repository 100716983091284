import './public-path';
import './styles.sass';

import Vue from 'vue';
import {sync} from 'vuex-router-sync';
import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';

// plugins
import * as CfConsentManager from '@inreal/consent-manager';
import {CockpitLibraryPlugin} from '@inreal/cockpit-library/src/js/plugin';
import {FlatyfindUiPlugin} from '@inreal/flatyfind-ui/src/js/plugin';
import {graphQLErrorsMixin} from '@inreal/flatyfind-ui/src/js/mixins/graph-ql-errors';
import {colorMixin} from './mixins/color';
import './plugins/modal-manager';
import {apolloClient, apolloProvider} from './plugins/apollo';

import CpAppLoader from './CpAppLoader.vue';
import router from './router';
import store from './store';
import i18n from './lang';
import {registry} from './modal';

gsap.registerPlugin(ScrollToPlugin);

window.BUILD_TIME = new Date(process.env.BUILD_TIMESTAMP);

Vue.config.productionTip = false;

Vue.use(CockpitLibraryPlugin, {store});
Vue.use(CfConsentManager, {store});
Vue.use(FlatyfindUiPlugin);

Vue.mixin(colorMixin);
Vue.mixin(graphQLErrorsMixin);

sync(store, router);

store.commit('inreal/service/setApolloClient', {apolloClient});
store.commit('inreal/projectSettings/setSettings', {settings: process.env.PROJECT_SETTINGS});

store.commit('inreal/modalManager/setRegistry', {registry});

store.dispatch('setPageMode');
// store.commit('setFloorplanRatio', 1 / 1);

Vue.config.errorHandler = (err, vm, info) => {
  if (process.env.NODE_ENV !== 'production') {
    // Buggy MdSelect from vue-material produces this error.
    // Functionally everything works but this error always comes up
    // even on their own documentation page for MdSelect.
    if (err.message !== 'Cannot read property \'badInput\' of undefined') {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
};

new Vue({
  i18n,
  router,
  store,
  apolloProvider,
  render: h => h(CpAppLoader),
})
  .$mount('#app')
  .$material.locale.dateFormat = 'dd.MM.yyyy';

/**
 * Tracking: Init Tracking if feature_tracking === 1
 */
if (store.getters['inreal/projectSettings/isTrackingEnabled'] === true) {
  store.dispatch('initTracking', 10000);
}
