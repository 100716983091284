/**
 * @type {Object<number, {
 *   label: string,
 *   fov: number,
 *   yaw: number,
 *   hotspots: Array<{
 *     id: number,
 *     imageFile: string,
 *     position: {
 *       yaw: number,
 *       pitch: number,
 *     },
 *   }>
 * }>}
 */
export const views = {
  // Cameras for REF
  '9974929e-464c-95a7-75f9-2baf20bd3108': {
    label: 'Wohnen',
    fov: 90,
    yaw: 0,
    hotspots: [],
  },
  '08b3f3f0-45ff-c92f-30e6-bca973b5e91c': {
    label: 'Masterbad',
    fov: 90,
    yaw: 0,
    hotspots: [],
  },
  'aeff028c-4d55-6453-9b99-4e956e36495c': {
    label: 'Wannenbad',
    fov: 90,
    yaw: 0,
    hotspots: [],
  },
  '890fcaed-46d7-0b91-a6b9-5fb537f9e61c': {
    label: 'Duschbad',
    fov: 90,
    yaw: 0,
    hotspots: [],
  },
  '474488bf-4452-761a-917f-26813a907497': {
    label: 'WC',
    fov: 90,
    yaw: 0,
    hotspots: [],
  },
  'f0e3a2b8-4de4-7193-eded-e1a36aa0aa4f': {
    label: 'Flur',
    fov: 90,
    yaw: 0,
    hotspots: [],
  },
};
