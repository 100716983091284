export default {
  title: 'Privacy',
  intro: 'We use Cookies on our website. Your agreement helps us to improve this website and your user experience. ' +
    'Your privacy is protected by the General Data Protection Regulation (GDPR).',
  consentTypes: {
    analytics: 'Analytics tools',
    remarketing: 'Remarketing',
  },
  agreeToAll: 'Agree to all',
  agreeToSelection: 'Agree to selection',
  agree: 'Agree',
  declineAll: 'Decline all',
  editSelection: 'Edit',
  closeButtonTitle: 'Close',
  showDetailsLinkText: 'More information',
};
