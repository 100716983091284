<template lang="pug">
  CpFormLayoutInquiryRent.cp-form-logic-inquiry-rent(
    v-on="$listeners"
    :label-male="$t('navigator.intro.gender.male')"
    :label-female="$t('navigator.intro.gender.female')"
    :label-family="$t('navigator.intro.gender.family')"
    :label-first-name="$t('form.firstName')"
    :label-first-name-missing="$t('validation.missingFirstName')"
    :label-last-name="$t('form.lastName')"
    :label-last-name-missing="$t('validation.missingName')"
    :label-street="$t('form.street')"
    :label-street-missing="$t('validation.missingStreet')"
    :label-zip="$t('form.zip')"
    :label-zip-missing="$t('validation.missingZip')"
    :label-city="$t('form.city')"
    :label-city-missing="$t('validation.missingCity')"
    :label-phone="$t('form.phone')"
    :label-phone-missing="$t('validation.missingPhone')"
    :label-phone-mobile="$t('form.phoneMobile')"
    :label-email="$t('form.eMail')"
    :label-email-missing="$t('validation.missingEmail')"
    :label-email-wrong-format="$t('validation.wrongEmailFormat')"
    :label-birthday="$t('form.birthday')"
    :label-birthday-invalid="$t('validation.invalidBirthday')"
    :label-comment="$t('form.whyInterested')"
    :label-dpp="$t('form.dpp', {hrefCustomer: $t('navigation.privacyLink')})"
    :label-field-required="$t('validation.fieldRequired')"
    :text-privacy-disclaimer="$t('form.privacyDisclaimer', {hrefCustomer: $t('navigation.privacyLink'), hrefCustomerLabel: $t('form.hrefCustomerLabel'), hrefConfiguratorLabel: $t('navigation.privacyLabel')})"
    :label-add-partner="$t('form.addPartner')"
    :label-additional-info="$t('form.additionalInfo')"
    :label-moving-in-count="$t('form.movingInCount')"
    :label-moving-in-count-children="$t('form.movingInCountChildren')"
    :label-searching-from-date="$t('form.searchingFromDate')"
    :text-income-disclaimer="$t('form.incomeDisclaimer')"
    :label-wbs="$t('form.wbs')"
    :label-yes="$t('general.yes')"
    :label-no="$t('general.no')"
    :label-no-comment="$t('general.noComment')"
    :label-date-of-issue="$t('form.dateOfIssue')"
    :label-wbs-rooms="$t('form.wbsRooms')"
    :label-wbs-persons="$t('form.wbsPersons')"
    :inquiry.sync="$v.inquiry"
    :hasPriceDisplay="hasPriceDisplay"
    @focus="onFocus"
    @click:privacy="$_onPrivacyClick"
    @change:add-partner="$_onAddParterChange"
    @submit:inquiry-form="$_validateForm"
  )
    template(#inquiry-items)
      CpInquiryItemsInfo(
        :selected-unit-text="$tc('ui.inquiryModal.selectedUnit', getInquiryItems.length)"
        :color-text="$color('text-config-menu')"
        :color-pill="$color('brand')"
        :title-close-icon="$t('general.removeUnit')"
        :inquiry-items="getInquiryItems"
        @remove-inquiry-item="$_onRemoveInquiryItem"
      )
        template(#text-tooltip="{ inquiryItem }")
          p {{$t('navigator.unitType', {rooms: inquiryItem.unit.numberOfRooms})}}
          p {{$t('navigator.unitSizeDataTable', {size: $n(inquiryItem.unit.areaSize, 'float')})}}
          p {{$n(inquiryItem.unit.basePrice, 'currency')}}
</template>

<style lang="sass" scoped>
@import '../../styles/variables'

.cp-form-logic-inquiry-rent
  &::v-deep
    .privacy-disclaimer
      .privacy-link,
      a
        color: $color-brand

    .md-radio
      .md-radio-container
        border-color: $color-brand !important

      &.md-checked
        .md-radio-container
          border-color: $color-brand !important
          background-color: $color-brand

    .actions
      background-color: $color-page-background

    .checkbox-wrapper
      .md-checkbox
        &.md-checked
          .md-checkbox-container
            background-color: $color-brand
            border-color: $color-brand

      .text
        color: $color-text-config-menu

        .label-checkbox .privacy-link-customer
          color: $color-brand !important
</style>

<script>
import {validationMixin} from 'vuelidate';
import {email, required, sameAs, requiredIf} from 'vuelidate/lib/validators';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {hideLayer, setAppUserData, setComplianceSettings, trackForm} from '@inreal/flatyfind-ui/src/js/tracking';
import CpInquiryItemsInfo from '@inreal/flatyfind-ui/src/vue/display/CpInquiryItemsInfo';
import CpFormLayoutInquiryRent from '@inreal/flatyfind-ui/src/vue/forms/CpFormLayoutInquiryRent';

const thisYear = new Date().getFullYear();

const isInPast = value => {
  if (value == null) {
    return false;
  }
  const parts = value.split('.');
  const yearValue = Number(parts[parts.length - 1]);
  return yearValue < thisYear;
};

export default {
  name: 'CpFormLogicInquiryRent',
  components: {
    CpFormLayoutInquiryRent,
    CpInquiryItemsInfo,
  },
  mixins: [validationMixin],
  data () {
    return {
      formname: 'Inquiry Modal',
      // Inquiry Schema
      // https://dev.azure.com/Inreal-Web/cloud-schemas/_git/cloud-inquiry-schema?path=%2Fschema%2Fcloud-inquiry-schema.json

      // example request as json
      // https://dev.azure.com/Inreal-Web/cloud-schemas/_git/cloud-inquiry-schema?path=%2Fschema%2Fcloud-inquiry-schema.example.json
      // payload shoud look like this
      // many fields are optional
      inquiry: {
        salutation: 'Herr',
        firstname: '',
        lastname: '',
        street: '',
        zip: '',
        city: '',
        phone: '',
        phoneMobile: '',
        email: '',
        birthday: `01.01.${new Date().getFullYear() - 18}`,
        message: '',
        dpp: false,
        partnerInfo: {
          salutation: this.$t('navigator.intro.gender.female'),
          firstname: '',
          lastname: '',
          phone: '',
          email: '',
        },
        movingInCount: 1,
        movingInCountChildren: 0,
        searchingFromDate: new Date().toLocaleDateString(),
        incomeDisclaimer: false,
        wbs: '',
        dateOfIssueWbs: new Date().toLocaleDateString(),
        wbsRooms: 1,
        wbsPersons: 1,
      },
      addPartner: false,
      inquirySuccess: null,
      isLoading: false,
    };
  },
  validations: {
    inquiry: {
      salutation: {},
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      street: {
        required,
      },
      zip: {
        required,
      },
      city: {
        required,
      },
      phone: {
        required,
      },
      phoneMobile: {},
      email: {
        required,
        email,
      },
      birthday: {
        required,
        isInPast,
      },
      message: {},
      dpp: {
        sameAs: sameAs(() => true),
      },
      partnerInfo: {
        firstname: {
          required: requiredIf(function () {
            return this.addPartner;
          }),
        },
        lastname: {
          required: requiredIf(function () {
            return this.addPartner;
          }),
        },
        salutation: {},
        phone: {},
        email: {},
      },
      movingInCount: {},
      movingInCountChildren: {},
      searchingFromDate: {
        required,
      },
      incomeDisclaimer: {
        required: requiredIf(function () {
          return this.hasPriceDisplay === true;
        }),
        sameAs: sameAs(() => true),
      },
      wbs: {},
      wbsRooms: {},
      wbsPersons: {},
      dateOfIssueWbs: {
        required: requiredIf(function () {
          return this.inquiry.wbs === this.$t('general.yes');
        }),
      },
    },
  },
  computed: {
    ...mapGetters('inreal/auth', [
      'getUser',
      'isLoggedIn',
    ]),
    ...mapGetters('inreal/projectSettings', [
      'hasLoginSystem',
      'isMultiLanguage',
      'hasPriceDisplay',
      'getLanguages',
    ]),
    ...mapGetters('inreal/inquiryItems', [
      'getInquiryItems',
    ]),
  },
  watch: {
    addPartner: {
      handler (newVal) {
        if (newVal === false) {
          this.$_resetPartnerInfo();
        }
      },
    },
    'inquiry.movingInCount': {
      handler (newVal) {
        if (this.inquiry.movingInCountChildren >= newVal) {
          this.inquiry.movingInCountChildren = newVal - 1;
        }
      },
      deep: true,
    },
  },
  created () {
    if (this.getUser != null) {
      this.$_fillFormWithUser();
    }
  },
  async mounted () {
    trackForm('view', this.formname, this.$store);
    await this.$nextTick();
  },
  methods: {
    ...mapActions([
      'sendInquiry',
    ]),
    ...mapMutations('inreal/inquiryItems', [
      'removeInquiryItem',
    ]),
    onFocus (event) {
      trackForm('focus', event.target.name, this.$store);
    },
    $_fillFormWithUser () {
      this.inquiry.salutation = this.getUser.salutation;
      this.inquiry.firstname = this.getUser.firstname;
      this.inquiry.lastname = this.getUser.lastname;
      this.inquiry.email = this.getUser.email;
      this.inquiry.phone = this.getUser.phone ?? '';
      this.inquiry.street = this.getUser.street ?? '';
      this.inquiry.zip = this.getUser.zip ?? '';
      this.inquiry.city = this.getUser.city ?? '';
    },
    $_onPrivacyClick () {
      this.$store.commit('inreal/consentManager/open', {mode: 'full-text'});
    },
    $_onAddParterChange ($event) {
      this.addPartner = $event;
    },
    $_validateForm () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      switch (this.hasLoginSystem) {
        case true:
          if (this.isLoggedIn) {
            this.$_postForm();
          }
          break;
        case false:
          this.$_postForm();
          break;
      }
    },
    async $_postForm () {
      if (this.isLoading === true) {
        return;
      }
      this.isLoading = true;
      this.$emit('submit');

      this.isMultiLanguage ? this.inquiry.locale = this.$i18n.locale : this.inquiry.locale = this.getLanguages[0];
      this.inquiry.inquiryItems = this.getInquiryItems;
      // let responseData = '';

      try {
        this.isLoading = false;
        // Inquiry Schema
        // https://dev.azure.com/Inreal-Web/cloud-schemas/_git/cloud-inquiry-schema?path=%2Fschema%2Fcloud-inquiry-schema.json

        // example request as json
        // https://dev.azure.com/Inreal-Web/cloud-schemas/_git/cloud-inquiry-schema?path=%2Fschema%2Fcloud-inquiry-schema.example.json
        // payload shoud look like this
        // many fields are optional

        // TODO: implement rent specific request handling
        // responseData = await this.sendInquiry({data: this.inquiry});
        // console.log({inquiry: this.inquiry});

        // trackConfig('send', responseData.data.inquiry.hash);
        // setConfigurationHashDimension(responseData.data.inquiry.hash, this.$store);
        // trackForm('send', this.formname);
      } catch (e) {
        this.isLoading = false;
        this.inquirySuccess = false;
        this.inquiry.dpp = false;
        this.$v.$reset();

      } finally {
        if (this.inquirySuccess) {
          await setComplianceSettings(this.$store);
          await setAppUserData(this.inquiry.email, this.inquiry.firstname, this.inquiry.lastname, this.inquiry.phone, this.inquiry.zip, this.inquiry.street, this.inquiry.city, this.inquiry.salutation, null, this.$store);
          hideLayer();
          this.$emit('submit:success');
        } else {
          this.$emit('submit:error');
        }
      }
    },
    /**
     * @param {InquiryItem} inquiryItem
     */
    $_onRemoveInquiryItem (inquiryItem) {
      this.removeInquiryItem({removeItem: inquiryItem});
    },
    async $_resetPartnerInfo () {
      this.inquiry.partnerInfo.firstname = '';
      this.inquiry.partnerInfo.lastname = '';
      this.inquiry.partnerInfo.phone = '';
      this.inquiry.partnerInfo.email = '';
      await this.$nextTick();
      this.$v.inquiry.partnerInfo.$reset();
    },
  },
};
</script>
