<template lang="pug">
  .cp-pdf
    .branding
      .logo
        include ../assets/img/signet-dww.svg
      .date(v-html="getDate")
    .subject
      .unit
        .copy
          template(v-for="(elm, index) in getUnitInfo")
            span(:class="{'first': index === 0}" v-html="elm")
            span.divider(v-if="index !== getUnitInfo.length - 1")
        .price
            span(v-html="getLabelPriceTotal")
            span(v-html="getPriceTotal")
      .client
        .copy
          template(v-for="(elm, index) in getUserInfo")
            span(:class="{'first': index === 0}" v-html="elm")
            span.divider(v-if="index !== getUnitInfo.length - 1")
    .order
      .headline(v-html="getTitlePdf")
      .copy
        // STYLE
        .style(v-if="getStylesOfUnit(getUnit.guid).length > 1")
          .thumb
            img(:src="getThumbnailUrl(getIdStyleSelected)")
          .text
            .label(v-html="getLabelStyle")
            .value(v-html="getValueStyleSelected")
        // MENU ENTRIES WITH VALUES
        .room(v-for="menuEntry in getMenuEntries(getUnit.guid, $i18n.locale)")
          .label.large(v-html="menuEntry.name")
          template(v-for="option in menuEntry.options")
            .variant
              .thumb
                img(:src="option.selectedVariant.thumbnailUrl")
              .text
                .label(v-html="option.name")
                .value(v-html="$_getLabelVariant(option.selectedVariant)")
                .description(v-html="option.selectedVariant.model.getMeta('description', $i18n.locale)")
                .price(v-html="getPriceFormattedOfVariant(option.selectedVariant.guid, $_formatPrice, 'default')")
      .footer
        .logo
          include ../assets/img/logo-dww.svg
        .copy(v-html="getCopyFooter")
</template>

<style lang="sass">
@media print
  @page
    size: A4
    margin: 5mm 5mm 15mm 5mm
</style>

<style lang="sass" scoped>
@import "../styles/variables"

.cp-pdf
  padding-top: 30px
  padding-left: 30px
  padding-right: 30px
  .headline
    text-transform: uppercase
    margin-bottom: 16px

  .branding
    position: relative
    margin: 0 0 32px 0
    .logo
      width: 60px
      svg
        path
          fill: black !important
    .date
      position: absolute
      right: 0
      top: 0
      &::v-deep
        p
          font-size: 8px
          line-height: 8px
          margin: 0
          padding: 0

  .footer
    border-top: 1px solid black
    padding-top: 24px
    display: flex
    flex-direction: row
    justify-content: space-between
    font-size: 9px
    line-height: 15px
    .logo
      width: 100px
      svg
        path, polygon
          fill: black !important

  .subject
    display: flex
    flex-direction: row
    .client, .unit
      width: 50%

  .unit
    padding: 16px
    padding-left: 0

    .price
      padding-top: 32px
      span
        display: block
        font-weight: 300 !important
        &:first-child
          font-size: 0.8em
          text-transform: uppercase
        &:nth-child(2)
          font-size: 1.2em

  .client
    padding: 16px
    padding-left: 0

    .title
      font-weight: 600
      line-height: 26px

  .order
    margin-top: 16px
    padding-top: 32px

    .room
      page-break-inside: avoid

    .thumb
      width: 100px
      min-width: 100px
      height: 100px

    .text
      padding-left: 32px

    .variant, .style
      display: flex
      margin-bottom: 32px

    .value
      font-weight: 600

    .title
      font-style: italic
      margin-bottom: 8px

    .price
      margin-top: 8px
      font-style: italic

    .label
      text-transform: uppercase
      font-weight: 300
      margin-bottom: 8px

      &.large
        position: relative
        display: inline-block
        margin-bottom: 24px
        margin-top: 32px
        margin-left: 132px
        &:after
          content: ''
          height: 3px
          position: absolute
          left: 0
          right: 0
          bottom: 0
          border-bottom: 2px solid black

  span
    display: inline-block
    font-weight: 600
    line-height: 26px

  span.prefix,
  span.first,
  span:nth-child(7)
    font-weight: 300 !important

  span.divider
    display: block

</style>

<script>
import CpButtonAction from '@inreal/flatyfind-ui/src/vue/buttons/CpButtonAction';
import {UNIT_ATTRIBUTE_GROUP_TYPE} from '@inreal/cockpit-library/src/js/enums';
import {mapGetters, mapMutations} from 'vuex';
import {PriceService} from '../common/ApiService';

export default {
  name: 'CpWelcome',
  components: {
    CpButtonAction,
  },
  props: {
  },
  data () {
    return {
    };
  },
  computed: {
    ...mapGetters('inreal/deeplink', [
      'getDeeplink',
    ]),
    ...mapGetters('inreal/property', [
      'getUnitByGuid',
      'getThumbnailUrl',
      'getReferenceUnitOfUnit',
      'getRoomsOfUnit',
      'getExtrasOfUnit',
      'getStylesOfUnit',
      'getVariantGroupForVariant',
      'getUnitAttributesOfUnitByGroupType',
    ]),
    ...mapGetters('inreal/auth', [
      'getUser',
    ]),
    ...mapGetters('inreal/prices', [
      'getPricesOfVariants',
      'getPriceValueOfVariant',
      'getPriceFormattedOfVariant',
    ]),
    ...mapGetters('inreal/configuration', [
      'getConfigurations',
      'getSelectedStyleInUnit',
    ]),
    ...mapGetters('inreal/configurationUi', [
      'getMenuEntries',
    ]),
    getDate () {
      return `<p>${this.$d(new Date(), 'shortDate')}</p>`;
    },
    getPriceTotal () {
      let total = 0;
      this.getMenuEntries(this.getUnit.guid, this.$i18n.locale).forEach(menuEntry => {
        menuEntry.options.forEach(option => {
          total += this.getPriceValueOfVariant(option.selectedVariant.guid);
        });
      });
      return this.$_formatPrice(total);
    },
    getLabelPriceTotal () {
      return this.$t('configurator.orderLabelPriceTotal');
    },
    getUserInfo () {
      const copy = [];
      copy.push(this.$t('pdf.titleClient').toUpperCase());
      copy.push(`${this.getUser.salutation} ${this.getUser.firstname} ${this.getUser.lastname}`);
      if (this.getUser.street) {
        copy.push(this.getUser.street);
      }
      if (this.getUser.zip && this.getUser.city) {
        copy.push(`${this.getUser.zip} ${this.getUser.city}`);
      } else if (this.getUser.city) {
        copy.push(this.getUser.city);
      }
      copy.push('<br>');
      if (this.getUser.phone) {
        copy.push(this.getUser.phone);
      }
      if (this.getUser.email) {
        copy.push(this.getUser.email);
      }
      return copy;
    },
    getUnitInfo () {
      const unitAttributeFloor = this.getUnitAttributesOfUnitByGroupType(this.getUnit, UNIT_ATTRIBUTE_GROUP_TYPE.FLOOR);
      const unitFloorName = unitAttributeFloor.length > 0 && unitAttributeFloor[0].getMeta('name') ? unitAttributeFloor[0].getMeta('name') : ''
      const copy = [];
      copy.push(this.$t('pdf.labelProject'));
      copy.push(this.getUnit.getMeta('project'));
      copy.push('');
      copy.push(this.$t('pdf.labelUnit'));
      copy.push(this.getUnit.getMeta('name'));
      copy.push(this.$t('pdf.labelUnitType', {rooms: this.getUnit.getMeta('numberOfRooms')}));
      copy.push(this.$t('pdf.labelUnitSizeValue', {size: this.getUnit.getMeta('areaSize')}));
      copy.push(this.$t('pdf.labelUnitFloorValue', {floor: unitFloorName}));
      return copy;
    },
    getUnit () {
      return this.getConfiguration ? this.getUnitByGuid(this.getConfiguration.unitGuid) : null;
    },
    getConfiguration () {
      return this.getConfigurations ? this.getConfigurations[0] : null;
    },
    getTitlePdf () {
      // TODO Add Date
      return this.$t('pdf.title');
    },
    getLabelStyle () {
      return this.$t('pdf.labelStyle');
    },
    getStyleSelected () {
      return this.getSelectedStyleInUnit(this.getUnit.guid);
    },
    getIdStyleSelected () {
      return this.getStyleSelected.guid;
    },
    getValueStyleSelected () {
      return `- ${this.getStyleSelected.getMeta('name')}`;
    },
    getCopyFooter () {
      return this.$t('pdf.footer');
    },
  },
  watch: {},
  created () {
  },
  mounted () {
    this.$_loadPricesOfVariants();
  },
  beforeDestroy () {
  },
  methods: {
    ...mapMutations('inreal/prices', [
      'setPricesOfVariants',
    ]),
    $_getLabelVariant (selectedVariant) {
      const groupOfSelectedVariant = this.getVariantGroupForVariant(selectedVariant.guid);
      return groupOfSelectedVariant ? `${groupOfSelectedVariant.getMeta('name', this.$i18n.locale)} : ${selectedVariant.name}` : selectedVariant.name;
    },
    $_stringToSlug (str) {
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      const from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
      const to = 'aaaaaaeeeeiiiioooouuuunc------';

      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes
        .replace(/^-+/, '') // trim - from start of text
        .replace(/-+$/, ''); // trim - from end of text

      return str;
    },
    $_loadPricesOfVariants () {
      const refUnit = this.getReferenceUnitOfUnit(this.getUnit.guid);
      const roomsOfRefUnit = this.getRoomsOfUnit(refUnit.guid);
      // Ref unit does not have extras.
      // const extrasOfRefUnit = this.getExtrasOfUnit(refUnit.guid);
      const roomsOfUnit = this.getRoomsOfUnit(this.getUnit.guid);
      const extrasOfUnit = this.getExtrasOfUnit(this.getUnit.guid);

      let variants = [];
      roomsOfRefUnit.forEach(room => {
        variants = variants.concat(room.variantGuids);
      });
      // extrasOfRefUnit.forEach(extra => {
      //   variants = variants.concat(extra.variantGuids);
      // });
      roomsOfUnit.forEach(room => {
        variants = variants.concat(room.variantGuids);
      });
      extrasOfUnit.forEach(extra => {
        variants = variants.concat(extra.variantGuids);
      });

      const unitKey = this.$_stringToSlug(`${this.getUnit.getMeta('project')}-${this.getUnit.getMeta('numberOfRooms')}-zimmer`);

      const priceRequest = {
        unitGuid: unitKey,
        variants,
        locale: this.$i18n.locale,
      };
      PriceService.requestPriceVariants(priceRequest).then(response => {
        const variants = {};
        if (response.data && response.data.length > 0) {
          response.data.forEach(item => {
            variants[item.variantGuid] = item;
            if (variants[item.variantGuid].label.default == null) {
              variants[item.variantGuid].label = JSON.parse(variants[item.variantGuid].label);
            }
          });
        }
        this.setPricesOfVariants({variants});
      }).catch(error => {
        // eslint-disable-next-line
        console.warn(error);
      });
    },
    $_formatPrice (price) {
      return this.$n(price / 100, 'currency');
    },
  },
};
</script>
