<template lang="pug">
  CpLayoutNotification.cp-logic-notification(
    v-on="$listeners"
    :type="type"
  )
    template(#header-text)
      | {{$t('ui.notificationModal.header')}}
    template(#modal-text)
      | {{getModalText}}
    template(#button-text)
      | {{$t('ui.notificationModal.btnText')}}
</template>

<style scoped lang="sass">
@import '../styles/variables'

.cp-logic-notification
  font-family: $font-main

  &::v-deep
    .header-text-container
      border-bottom: 1px solid $color-brand

      span
        color: $color-brand

    .modal-text
      color: $color-text-config-menu

    .modal-action-btn
      background-color: $color-brand
      border-top: 1px solid $color-brand

      &:hover
        color: $color-brand
</style>

<script>
import CpLayoutNotification from '@inreal/flatyfind-ui/src/vue/modals/CpLayoutNotification';
import {NOTIFICATION_TYPE} from '@inreal/flatyfind-ui/src/js/constants/notification-type';

export default {
  name: 'CpLogicNotification',
  components: {
    CpLayoutNotification,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  computed: {
    isPersistent () {
      return true;
    },
    getModalText () {
      let text = '';
      switch (this.type) {
        case NOTIFICATION_TYPE.UNREGISTER:
          text = this.$t('login.unregisterAppuserText');
          break;
        case NOTIFICATION_TYPE.UNREGISTER_INQUIRY:
          text = this.$t('login.unregisterInquiryAppuserText');
          break;
        case NOTIFICATION_TYPE.UNREGISTER_ERROR:
          text = this.$t('login.unregisterAppuserErrorText');
          break;
        case NOTIFICATION_TYPE.LOGOUT_USER:
          text = this.$t('login.logoutAppuserText');
          break;
      }
      return text;
    },
  },
};
</script>
