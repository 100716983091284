import CfConsentManager from './components/cf_consent_manager';
import CfConsentManagerI18n from './lang';
import store from './store';
import {applyI18nDefaults, namespaceI18n} from './utils/pluginI18n';

export function install (Vue, options) {
  if (options?.store == null) {
    throw new Error('Expected options.$store');
  }
  options.store.registerModule('inreal/consentManager', store);
}

export {CfConsentManager, CfConsentManagerI18n, applyI18nDefaults, namespaceI18n};
