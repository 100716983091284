<template lang="pug">
  .cp-app(:class="classes")
    CpHeader(
      v-if="getIsStandalone"
      :logo-src="require('./assets/img/signet-dww.svg')"
      :alignment="'left'"
      :href="$t('general.projectUrl')"
      :title="$t('general.projectUrlTitle')"
      :alt="$t('general.projectLogoAlt')"
      :show-user="true"
      :is-logged-in="isLoggedIn"
      @click:user="$_onClickUser"
    )
    .content(:class="classes")
      template(v-if="isLoading('route')")
        CfSpinner(preset="medium" :color="$color['color-brand']")
      template(v-else)
        router-view.view
        router-view.aside(name="aside")
    template(v-if="scrollToTopVisible")
      CpButtonScrollToTop.scroll-to-top(@click.native="$_onScrollToTopClick" :color-background="getColorBrand")
    CpFooter(
      v-if="getIsStandalone"
      :title-image-logo="$t('general.clientUrlTitle')"
      :alt-image-logo="$t('general.clientLogoAlt')"
      :cols="getColsFooter"
    )
    CfModalManager
    CfConsentManager(
      #default="{enableEditing}"
      :primary-color="$color['color-brand']"
    )
      CpPrivacyText(
        @edit-settings-click="enableEditing"
        :customer-imprint="$t('navigation.imprintLink')"
        :customer-privacy="$t('navigation.privacyLink')"
        :privacy-date="$t('navigation.privacyDate')"
        :live-url="$t('navigation.liveUrl')"
      )
</template>

<style lang="sass" scoped>
@import '~@inreal/core-library/src/sass/poly-fluid-sizing'
@import "~@inreal/flatyfind-ui/src/sass/components/app"
@import 'styles/variables'
@import 'styles/overrides'


.cf-modal-manager::v-deep
  .cf-modal-dialog
    background-color: $color-modal-overlay

.meta-element
  color: $color-text-meta-link !important

</style>

<script>
import {CfConsentManager} from '@inreal/consent-manager';
import '@inreal/consent-manager/dist/consent-manager.css';
import {throttle} from '@inreal/core-library/src/js/animation/rendering';
import CfSpinner from '@inreal/ui/src/vue/loader/cf_spinner';
import CfModalManager from '@inreal/ui/src/vue/modal/cf_modal_manager';
import CpHeader from '@inreal/flatyfind-ui/src/vue/layout/CpHeader';
import CpFooter from '@inreal/flatyfind-ui/src/vue/layout/CpFooter';
import CpSelectLanguage from '@inreal/flatyfind-ui/src/vue/selects/CpSelectLanguage';
import CpButtonPrivacy from '@inreal/flatyfind-ui/src/vue/buttons/CpButtonPrivacy';
import CpLinkMeta from '@inreal/flatyfind-ui/src/vue/links/CpLinkMeta';
import CpButtonScrollToTop from '@inreal/flatyfind-ui/src/vue/buttons/CpButtonScrollToTop';
import {ROUTE_NAMES} from '@inreal/flatyfind-ui/src/js/constants/routes';
import gsap from 'gsap';
import {mapActions, mapGetters} from 'vuex';
import CpPrivacyText from '@inreal/flatyfind-ui/src/vue/display/CpPrivacyText';
import {updatePageTitle, updateLocale} from './lang';
import {setCustomDimensionLanguage} from './common/TrackingService';

export default {
  name: 'CpApp',
  components: {
    CpFooter,
    CpButtonPrivacy,
    CpLinkMeta,
    CpButtonScrollToTop,
    CfSpinner,
    CpHeader,
    CpSelectLanguage,
    CfModalManager,
    CfConsentManager,
    CpPrivacyText,
  },
  data: () => ({
    scrollToTopVisible: false,
  }),
  computed: {
    classes () {
      return {
        [`route-${this.$store.state.route.name}`]: true,
        'standalone': this.getIsStandalone,
        'iframe': !this.getIsStandalone,
      };
    },
    ...mapGetters('inreal/projectSettings', [
      'isMultiLanguage',
      'getLanguages',
    ]),
    ...mapGetters('inreal/auth', [
      'isLoggedIn',
    ]),
    ...mapGetters([
      'isLoading',
      'getIsStandalone',
    ]),
    routeNames () {
      return ROUTE_NAMES;
    },
    getColsFooter () {
      return Object.values(this.$i18n.messages[this.$i18n.locale].general.footer.columns);
    },
    getColorBrand () {
      return this.$color('brand');
    },
  },
  watch: {
    '$i18n.locale' () {
      /**
       * Tracking: Set the Dimension (language)
       */
      setCustomDimensionLanguage(this.$i18n.locale);
      updatePageTitle(this.$route.name);
    },
  },
  created () {
    /**
     * Tracking: Set the Dimension (language)
     */
    setCustomDimensionLanguage(this.$i18n.locale);
  },
  mounted () {
    window.addEventListener('scroll', this.$_onScroll);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.$_onScroll);
  },
  methods: {
    ...mapActions('inreal/modalManager', [
      'openModal',
    ]),
    $_onScroll: throttle(function () {
      this.scrollToTopVisible = window.scrollY > 150;
    }),
    $_onScrollToTopClick () {
      gsap.to(window, {duration: .75, scrollTo: {y: 0}});
    },
    $_onLanguageUpdate (language) {
      updateLocale(language);
    },
    async $_onClickUser () {
      const userBox = await this.openModal({
        type: 'user-details',
      });
      userBox.onClose.then(data => {
        if (!this.isLoggedIn) {
          this.$router.push({name: ROUTE_NAMES.GOODBYE});
        }
      });
    },
  },
};
</script>
