import { render, staticRenderFns } from "./CpWelcome.vue?vue&type=template&id=657f9039&scoped=true&lang=pug&"
import script from "./CpWelcome.vue?vue&type=script&lang=js&"
export * from "./CpWelcome.vue?vue&type=script&lang=js&"
import style0 from "./CpWelcome.vue?vue&type=style&index=0&id=657f9039&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "657f9039",
  null
  
)

export default component.exports