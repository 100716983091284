<template lang="pug">
  CpModalBase.cp-logic-user-details(
    @close="$emit('close')"
    closeable
    scrollable
    :close-label="labelClose"
  )
    template(#header)
      | {{headerTitle}}
    template(#content)
      .details
        .grid
          template(v-for="elm in userCopy")
            span(v-html="elm")
        .inquiries
          .headline {{$t('userAccount.userInquiries')}}
          .list
            .item(v-for="inq in userInquiries")
              span.date(v-html="$_formatDate(inq.createdAt)")
              span.unit(v-html="$_unitName(inq.inquiryItems[0])")
              span.action(v-if="inq.inquiryItems[0].configuration" @click="$_loadConfiguration(inq.inquiryItems[0])") {{$t('userAccount.loadConfiguration')}}
    template(#actions)
      CpButtonAction.logout(
        :is-submit="true"
        :label="labelButtonLogout"
        :show-slot="true"
        @click.native="$_onClickLogout()"
      )
        include ../assets/img/long-arrow.svg

</template>

<style lang="sass" scoped>
@import '../styles/variables'

.cp-logic-user-details
  max-width: initial
  max-height: 100%
  height: auto

  @media (min-width: $size-tablet)
    width: $userbox-width

  .details
    padding: 16px
    margin-bottom: 32px

    .grid
      text-align: left
      display: block
      margin-bottom: 16px

      span
        display: inline-block
        font-weight: 600
        line-height: 26px
        width: 50%

      span:nth-child(odd)
        font-weight: 300
    .inquiries
      .headline
        font-weight: 600
        text-transform: uppercase
        padding-bottom: 8px
        padding-left: 16px
        padding-top: 16px
        margin-bottom: 8px
        margin-left: -16px
        margin-right: -16px
        border-bottom: 1px solid black
      .item
        margin-bottom: 4px
        margin-left: -8px
        margin-right: -8px
        background-color: rgba(245, 245, 245, 0.5)
        border: rgba(245, 245, 245, 0.5) 1px solid
        padding: 16px
        border-radius: 6px
        user-select: none
        cursor: pointer
        transition: all 0.45s
        &:hover
          background-color: rgba(240, 240, 240, 1)
          border: lightgrey 1px solid
      span
        display: block
      .date
        font-size: 0.9em
      .unit
        font-weight: 600
      .action
        cursor: pointer
        position: relative
        display: inline-block
        margin-top: 8px
        &:after
          display: block
          content: ''
          position: absolute
          left: 0
          right: 100%
          bottom: 0
          height: 1px
          line-height: 0
          border-bottom: 1px solid black
          transition: right 0.45s ease
        &:hover
          &:after
            right: 0%


  .logout
    width: 100%

    @media (min-width: $size-tablet)
      padding: 0
      width: 33.3333%
      margin: 0
      margin-left: 1px

.cp-logic-user-details
  &::v-deep
    .modal-actions
      display: flex
      flex-direction: column
      justify-content: flex-end
      @media (min-width: $size-tablet)
        flex-direction: row
</style>

<script>
import {mapActions, mapGetters} from 'vuex';
import CfIcon from '@inreal/ui/src/vue/gfx/cf_icon';
import CfImageLoader from '@inreal/ui/src/vue/loader/cf_image_loader';
import CfAspectRatio from '@inreal/ui/src/vue/container/cf_aspect_ratio';
import CpModalBase from '@inreal/flatyfind-ui/src/vue/modals/CpModalBase';
import CpButtonAction from '@inreal/flatyfind-ui/src/vue/buttons/CpButtonAction';
import CpInquiryFollowUp from '@inreal/flatyfind-ui/src/vue/display/CpInquiryFollowUp';
import {ROUTE_NAMES} from '@inreal/flatyfind-ui/src/js/constants/routes';

export default {
  name: 'CpLogicUserDetails',
  components: {
    CfIcon,
    CfAspectRatio,
    CfImageLoader,
    CpModalBase,
    CpButtonAction,
    CpInquiryFollowUp,
  },
  props: {
  },
  data () {
    return {
    };
  },
  computed: {
    ...mapGetters('inreal/auth', [
      'getUser',
      'isLoggedIn',
    ]),
    ...mapGetters('inreal/property', [
      'getUnitByGuid',
    ]),
    labelClose () {
      return this.$t('general.close');
    },
    headerTitle () {
      return this.$t('userAccount.userDetails');
    },
    labelButtonLogout () {
      return this.$t('userAccount.logout');
    },
    userCopy () {
      let userCopy = [];
      userCopy.push(this.$t('form.salutation'));
      userCopy.push(this.getUser?.salutation);
      userCopy.push(this.$t('form.firstName'));
      userCopy.push(this.getUser?.firstname);
      userCopy.push(this.$t('form.lastName'));
      userCopy.push(this.getUser?.lastname);
      userCopy.push(this.$t('form.eMail'));
      userCopy.push(this.getUser?.email);
      return userCopy;
    },
    userInquiries () {
      console.log(this.getUser);
      return this.getUser.inquiries;
    },
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'sendInquiry',
    ]),
    ...mapActions('inreal/auth', [
      'logout',
    ]),
    ...mapActions('inreal/configuration', [
      'setConfiguration',
    ]),
    $_formatDate (date) {
      let outputDate = new Date(date);
      return this.$d(outputDate, 'shortDate');
    },
    $_unitName (inquiryItem) {
      return `${this.$t('configurator.unitLabel')}: ${this.getUnitByGuid(inquiryItem.unit.guid).getMeta('project')}: ${this.getUnitByGuid(inquiryItem.unit.guid).getMeta('name')}`;
    },
    $_loadConfiguration (inquiryItem) {
      this.setConfiguration({unitGuid: inquiryItem.unit.guid, configuration: inquiryItem.configuration});
      this.$router.push({name: ROUTE_NAMES.UNIT, params: {guid: inquiryItem.unit.guid}});
      this.$emit('close');
    },
    async $_onClickLogout () {
      await this.logout();
      this.$emit('close');
    },
  },
};
</script>
