<template lang="pug">
  CpModalBase.cp-inquiry(
    ref="modalBase"
    @close="$emit('close')"
    closeable
    scrollable
    :closeLabel="$t('general.close')"
  )
    template(#header)
      | {{inquiryTitle}}
    template(#content)
      transition-group(name="fade" mode="out-in")
        template(v-if="!showFollowUp")
          CpInquiryGreeting(key="0")
            template(#copy) {{inquiryGreeting}}
            template(#subcopy) {{inquirySubcopy}}
          template(v-if="isSell")
            CpFormLogicInquiry(
              ref="form"
              key="1"
              @submit="$_onSubmit"
              @submit:success="$_onSubmitSuccess"
              @submit:error="$_onSubmitError"
              @scroll-to-element="$_scrollToElement"
            )
          template(v-else)
            CpFormLogicInquiryRent(
              ref="form"
              key="1"
              @submit="$_onSubmit"
              @submit:success="$_onSubmitSuccess"
              @submit:error="$_onSubmitError"
              @scroll-to-element="$_scrollToElement"
            )
        template(v-else)
          CpInquiryFollowUp(
            key="0"
            :success="inquirySuccess"
            :color="$color('brand')"
            :color-error="$color('form-error')"
            :text-success="$t('general.success')"
            :text-gratitude="$t('general.gratitude')"
            :text-error="$t('ui.errorHeading')"
            :text-submit-success="$tc('form.submitSuccess', getInquiryItemsNames.length, {unitName: getInquiryItemsNames.join(', ')})"
            :text-submit-error="$tc('form.submitError', getInquiryItemsNames.length, {unitName: getInquiryItemsNames.join(', ')})"
          )
    template(#actions)
      MdProgressBar.progress-bar(
        md-mode="indeterminate"
        class="md-primary"
        :class="{visible: isLoading}"
      )
      template(v-if="!showFollowUp")
        CpButtonAction.form-button.submit-button(
          :is-submit="true"
          :label="$t('form.submit')"
          :disabled="isLoading"
          form="inquiry-form"
          @click.native="$_onFormSubmitClick"
        )
      template(v-else-if="showFollowUp && inquirySuccess === false")
        CpButtonAction.form-button(
          :label="$t('navigator.backBtnTextShort')"
          @click.native="$_handleBack"
        )
</template>

<style lang="sass" scoped>
@import '~@inreal/flatyfind-ui/src/sass/variables'
@import "../styles/variables"

.cp-inquiry
  max-height: 100%
  height: auto

  @media (min-width: $size-desktop)
    height: auto

  &::v-deep
    .cp-inquiry-greeting
      .copy
        color: $color-brand
        font-family: $font-secondary

    .cp-inquiry-follow-up
      h1
        font-family: $font-secondary

.form-button
  width: 100%
  height: 44px

  @media (min-width: $size-desktop)
    width: 50%
    height: auto
    margin-left: auto

.progress-bar
  width: 80%
  margin-left: 100px
  margin-right: 100px
</style>

<script>
import gsap from 'gsap';
import {mapGetters, mapMutations} from 'vuex';
import CpButtonAction from '@inreal/flatyfind-ui/src/vue/buttons/CpButtonAction';
import CpModalBase from '@inreal/flatyfind-ui/src/vue/modals/CpModalBase';
import CpInquiryGreeting from '@inreal/flatyfind-ui/src/vue/display/CpInquiryGreeting';
import CpInquiryFollowUp from '@inreal/flatyfind-ui/src/vue/display/CpInquiryFollowUp';
import {DISTRIBUTION_TYPE} from '@inreal/cockpit-library/src/js/enums';
import CpFormLogicInquiry from '../components/form/CpFormLogicInquiry';
import CpFormLogicInquiryRent from '../components/form/CpFormLogicInquiryRent';
import {updatePageTitle} from '../lang';
import {trackCustomUrlByPath} from '../common/TrackingService';

export default {
  name: 'CpInquiry',
  components: {
    CpInquiryGreeting,
    CpFormLogicInquiry,
    CpFormLogicInquiryRent,
    CpModalBase,
    CpButtonAction,
    CpInquiryFollowUp,
  },
  data () {
    return {
      isLoading: false,
      showFollowUp: false,
      inquirySuccess: true,
    };
  },
  computed: {
    isPersistent () {
      return true;
    },
    ...mapGetters('inreal/projectSettings', [
      'getSettingByKey',
    ]),
    ...mapGetters('inreal/inquiryItems', [
      'getInquiryItems',
    ]),
    isSell () {
      return this.getSettingByKey('feature.distribution_type') === DISTRIBUTION_TYPE.SELL;
    },
    inquiryTitle () {
      return this.isSell ? this.$t('ui.inquiryModal.title') : this.$t('ui.inquiryModal.titleRent');
    },
    inquiryGreeting () {
      return this.isSell ? this.$t('ui.inquiryModal.greeting') : this.$t('ui.inquiryModal.greetingRent');
    },
    inquirySubcopy () {
      return this.isSell ? this.$t('ui.inquiryModal.subcopy') : this.$t('ui.inquiryModal.subcopyRent');
    },
    getInquiryItemsNames () {
      return this.getInquiryItems.map(inquiryItem => inquiryItem.unit.name);
    },
  },
  destroyed () {
    this.clearInquiryItems();
  },
  created () {
    updatePageTitle('inquiryForm');
    /**
     * Tracking: track custom url as page view
     */
    trackCustomUrlByPath(this.$route.path);
  },
  methods: {
    ...mapMutations('inreal/inquiryItems', [
      'clearInquiryItems',
    ]),
    $_onSubmit () {
      this.isLoading = true;
    },
    $_onSubmitSuccess () {
      this.inquirySuccess = true;
      this.isLoading = false;
      this.showFollowUp = true;
    },
    $_onSubmitError () {
      this.inquirySuccess = false;
      this.isLoading = false;
      this.showFollowUp = true;
    },
    $_handleBack () {
      this.showFollowUp = false;
    },
    $_onFormSubmitClick () {
      this.$refs.form.$el.querySelector('.fake-button').click();
    },
    $_scrollToElement (element) {
      if (this.$refs.modalBase.scrollable) {
        gsap.to(this.$refs.modalBase.$refs.scrollbar.scroller.getScrollElement(), {
          delay: .25,
          duration: .5,
          scrollTo: {y: element, offsetY: 30},
          ease: 'power1.out'},
        );
      }
    },
  },
};
</script>
