function isObject (item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

function defaults2 (first, second) {
  if (!isObject(first) || !isObject(second)) {
    return first;
  }
  return Object.keys(second).reduce((memo, key) => {
    if (isObject(memo[key]) && isObject(second[key])) {
      memo[key] = defaults2(memo[key], second[key]);
      // eslint-disable-next-line no-undefined
    } else if (memo[key] === undefined) {
      memo[key] = second[key];
    }
    return memo;
  }, first);
}

function defaultsDeep (head, ...tail) {
  return tail.reduce(defaults2, head);
}

/**
 * Recursively applies a set of defaults to a base i18n messages map.
 *
 * Mutates `base`.
 *
 * @param {Object} base The base configuration
 * @param {...Object} defaults Defaults that should be recursively applied to `base`
 * @returns {*}
 */
export function applyI18nDefaults (base, ...defaults) {
  return defaultsDeep(base, ...defaults);
}

/**
 * Sets up some helpers for namespacing i18n configurations (messages, date formats,
 * anything keyed by a locale such as `en` or `de`).
 *
 * The Vue mixin provides namespaced versions of `vue-i18n` helpers such as `$$t`, `$$d` and `$$n`.
 *
 * `addNamespace` adds a namespace prefix within all locales of an i18n configuration.
 *
 * @param {string} prefix The prefix to be used to namespace a i18n configuration.
 * @returns {Object}
 */
export function namespaceI18n (prefix) {
  const addNamespace = obj => Object.keys(obj).reduce((memo, current) => {
    memo[current] = {[prefix]: obj[current]};
    return memo;
  }, {});

  const addPrefix = str => `${prefix}.${str}`;

  const mixin = {
    methods: {
      $$t (key, ...args) {
        return this.$t(addPrefix(key), ...args);
      },
      $$tc (key, ...args) {
        return this.$tc(addPrefix(key), ...args);
      },
      $$te (key, ...args) {
        return this.$te(addPrefix(key), ...args);
      },
      // important: vue-i18n does *not* look up key paths for dateTimeFormats and numberFormats!
      // alias $$d to original $d, without adding any prefixes
      $$d (...args) {
        return this.$d(...args);
      },
      $$n (...args) {
        return this.$n(...args);
      },
    },
  };

  return {mixin, addNamespace};
}
