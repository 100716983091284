import {getSassVariables} from '@inreal/core-library/src/js/css';
import variables from '../styles/_export.sass';

// Get all exported sass variables that start with prefix and forward them to the color mixin.
const colorPrefix = 'color-';
const colors = getSassVariables(variables, colorPrefix, colorPrefix);

export const colorMixin = {
  computed: {
    $color () {
      return name => colors[name];
    },
  },
};
