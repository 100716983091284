<template lang="pug">
  .cp-config-variant(:class="getClasses")
    CfAspectRatio.ratio(:ratio="1")
      CfImageLoader(
        :src="imageSrc"
      )
      .indicator
    .content
      slot(name="default")
    button.info(
      v-if="infoButton"
      @click.stop="$emit('click:info')"
    )
      CfMdiSvgIcon.info-icon(
        :path="$options.icons.mdiInformationOutline"
        color="black"
        :color-hover="$color('button-background')"
        animate-color
        size="xs"
      )
</template>

<style lang="sass" scoped>
@import "../styles/variables"

.cp-config-variant
  display: flex
  align-items: center

  .ratio
    flex: none
    width: 100%
    max-width: 46px
    margin-right: 10px
    transition: box-shadow .25s ease-out

  .indicator
    opacity: 1
    pointer-events: none
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0

  &.selected .indicator
    box-shadow: inset 0 0 0 3px #000

  &:hover
    .ratio
      box-shadow: 0 0 15px rgba(black, .2)

  .content
    flex: 1 1 auto

  .info
    flex: none

    display: block
    width: 40px
    height: 40px
    cursor: pointer
    margin: 0 10px 0 4px
    text-align: center

    .info-icon
      display: inline-block
</style>

<script>
import CfAspectRatio from '@inreal/ui/src/vue/container/cf_aspect_ratio';
import CfMdiSvgIcon from '@inreal/ui/src/vue/gfx/cf_mdi_svg_icon';
import CfImageLoader from '@inreal/ui/src/vue/loader/cf_image_loader';
import {mdiInformationOutline} from '@mdi/js';

export default {
  name: 'CpConfigVariant',
  components: {
    CfMdiSvgIcon,
    CfAspectRatio,
    CfImageLoader,
  },
  icons: {
    mdiInformationOutline,
  },
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
    infoButton: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {};
  },
  computed: {
    getClasses () {
      return {
        selected: this.selected,
        default: this.isDefault,
      };
    },
  },
  watch: {},
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {},
};
</script>
