export default {
  title: 'Datenschutz',
  intro: 'Wir nutzen Cookies auf unserer Website. Mit Ihrer Einwilligung können Cookies uns dabei helfen, diese Website ' +
    'und Ihre Erfahrung zu verbessern. ' +
    'Ihre Privatsphäre ist dabei durch Einhaltung der Allgemeinen Datenschutzverordnung (DSGVO) geschützt.',
  consentTypes: {
    analytics: 'Analysetools',
    remarketing: 'Remarketing',
  },
  agreeToAll: 'Allen zustimmen',
  agreeToSelection: 'Auswahl zustimmen',
  agree: 'Zustimmen',
  declineAll: 'Alle ablehnen',
  editSelection: 'Anpassen',
  closeButtonTitle: 'Schließen',
  showDetailsLinkText: 'Mehr Informationen',
};
