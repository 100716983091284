const projectConfig = {
  imprintLink: 'https://inreal-tech.com/impressum',
  privacyLink: 'https://inreal-tech.com/datenschutz',
  privacyDate: '17.07.2020',
  liveUrl: 'ff-service.de',
  projectUrl: 'https://ff-service.de', // if unknown, make leave as empty string
  projectUrlTitle: 'Flatyfind-Homepage',
  projectLogoAlt: 'Projekct logo LOBU',
  clientUrl: 'https://ff-service.de',
  clientUrlTitle: 'Client-Homepage',
  clientLogoAlt: 'Clientlogo',
  hrefCustomerLabel: 'privacy policy Inreal',
  footerColumns: {
    col1: {
      title: 'CONCTACT & SALES',
      block: 'Inreal Technologies GmbH\n <br> Bahnhofplatz 12\n <br> 76137 Karlsruhe <br>',
    },
    col2: {
      title: '&nbsp;',
      block: '<a href="tel:0049721170293010">+49 721/170293–010</a> <br> <a href="mailto:info@inreal-tech.com">info@inreal-tech.com</a> <br> <a href="https://inreal-tech.com" target="_blank" rel="noopener">inreal-tech.com</a>',
    },
  },
};

export default {
  basePageTitle: 'Flat Finder | FlatyFind',
  pageTitles: {
    filter: 'Filter',
    home: 'FlatyFind',
    rooms: 'Rooms',
    extras: 'Extras',
    order: 'Summary',
    welcome: 'Welcome',
    buildingDetail: 'Building detail',
    inquiryForm: 'Inquiry form',
    surveyForm: 'Survey form',
    privacy: 'Privacy',
    turntable: 'FlatyFind',
    unitselection: 'FlatyFind',
  },
  general: {
    initApp: 'Loading apartments...',
    close: 'Close',
    success: 'Success',
    gratitude: 'Thank you!',
    areasize: '{size} m²',
    unitSoldOrReserved: 'already reserved or sold',
    unitSold: 'already sold',
    unitReserved: 'already reserved',
    projectUrl: projectConfig.projectUrl,
    projectUrlTitle: projectConfig.projectUrlTitle,
    projectLogoAlt: projectConfig.projectLogoAlt,
    clientUrl: projectConfig.clientUrl,
    clientUrlTitle: projectConfig.clientUrlTitle,
    clientLogoAlt: projectConfig.clientLogoAlt,
    yes: 'Yes',
    no: 'No',
    noComment: 'No comment',
    favorites: 'Favorites',
    noFavoritesText: 'No favorites selected',
    appointment: 'Appointment',
    removeUnit: 'Remove Unit',
    start: 'Start',
    footer: {
      columns: projectConfig.footerColumns,
    },
  },
  navigation: {
    configuration: 'Start',
    imprintLabel: 'Imprint',
    imprintLink: projectConfig.imprintLink,
    privacyLabel: 'Privacy Policy',
    privacyLink: projectConfig.privacyLink,
    privacyDate: projectConfig.privacyDate,
    liveUrl: projectConfig.liveUrl,
    prevImage: 'Previous image',
    nextImage: 'Next image',
    firstImageMessage: 'This is the first image',
    lastImageMessage: 'This is the last image',
  },
  login: {
    registerCaption: 'Get acces to our complete offerings.<br> <b>All floorplans and apartments are waiting for you:</b>',
    loginCaption: 'Please log in',
    passwordResetCaption: 'Please fill in your email address.',
    requestLoginLinkCaption: '<b>Receive a login link for your existing account.</b><br>Please fill in your email address.',
    linkRegisterText: 'Register an account',
    linkLoginText: 'Use login',
    requestLoginLinkText: 'Request login link',
    linkPasswordResetText: 'Forgot password?',
    requestLoginLinkButtonText: 'Request login link',
    button: {
      register: 'Register',
      login: 'Login',
      passwordReset: 'Send new password',
    },
    successBoxText: 'Please check your emails.',
    unregisterAppuserText: 'Your account has been deleted successfully.',
    unregisterInquiryAppuserText: 'Your inquiry has been deleted successfully.',
    unregisterAppuserErrorText: 'This account has already been deleted.',
    logoutAppuserText: 'Logged out successfully.',
  },
  navigator: {
    unitsLabel: 'Apartment',
    buildingInfoLabelPrefix: 'Floor',
    unitType: '{rooms}-rooms-apartment',
    unitRooms: 'rooms:',
    unitFloor: 'floor:',
    unitLabel: 'Apartment {name}',
    unitSize: 'living area:',
    unitSizeValue: '{size} m²:',
    unitPrice: 'price:',
    unitSizeDataTable: '{size} m²',
    selectUnit: 'Chose an apartment',
    requestUnit: 'Request an apartment',
    backBtnText: 'Back to overview',
    backBtnTextShort: 'back',
    instructionBuilding: '<strong>Interested?</strong> Click on a building for more details.',
    instructionUnit: '<strong>Interested?</strong> Click on an apartment for more details.',
    available: 'AVAILABLE',
    found: 'FOUND',
    intro: {
      salutation: {
        male: 'Dear',
        female: 'Dear',
        family: 'Dear',
        company: 'Dear',
        unknown: 'Dear',
        unknownMulti: 'Dear',
      },
      gender: {
        male: 'Sir',
        female: 'Madame',
        family: 'Family',
        misterAndMisses: 'Sir and Madam',
        missesAndMister: 'Madam and Sir',
        company: 'Company',
        unknown: 'Sir/Madam',
        unknownMulti: ' Sir or Madam',
      },
      title: {
        noTitle: 'No Title',
        dr: 'Dr.',
        prof: 'Prof.',
        profDr: 'Prof. Dr.',
      },
    },
  },
  configurator: {
    title: 'Configuration menu',
    styleSelect: 'Style selection',
    extras: 'Extras',
    configurationLink: 'Link to configuration',
    editConfiguration: 'Change configuration',
    action: 'Request Apartment',
    back: 'Back',
    selectedUnit: 'Selected Apartment:&nbsp;',
    jumpTo: 'Go to: {target}',
    refUnit: 'Die abgebildete Visualisierung zeigt die gewählte Konfiguration anhand einer Musterwohnung: {rooms} Zimmer mit ca. {size} m²',
    disclaimer: 'Die genaue Leistungs- und Ausstattungsbeschreibung entnehmen Sie bitte der Baubeschreibung. Alle Darstellungen sind nur beispielhaft. Einrichtungsgegenstände sind nicht Gegenstand des Angebots. Die Website dient zur Information und stellt kein vertragliches Angebot dar. Änderung und Gestaltung bleiben vorbehalten. Für Richtigkeit und Vollständigkeit wird keine Haftung übernommen. Mögliche behördliche Auflagen können die Planung und Quadratmeterzahlen noch verändern. Bei den Fotos, Skizzen, Zeichnungen und Visualisierungen handelt es sich um unverbindliche Illustrationen. Es gelten ausschließlich die Bestimmungen des Kaufvertrages sowie die notariell beurkundete Baubeschreibung und notariell beurkundeten Pläne. Stand: April 2021.',
  },
  unitTable: {
    nr: 'No.',
    building: 'Building',
    floor: 'Floor',
    areasize: 'Area',
    rooms: 'Rooms',
    unittype: 'Type',
    price: 'Price',
    favorites: 'Favorites ({count})',
  },
  filter: {
    heading: 'Apartment filter',
    roomsHeading: 'Number of rooms',
    sizeHeading: 'Size in m²',
    priceHeading: 'Price in €',
    parkingSpacesHeading: 'Parking Spaces',
    floorHeading: 'Floor',
    buildingsHeading: 'Building',
    all: 'All',
    reset: 'Reset filters',
  },
  form: {
    headline: 'Inquiry',
    addInfo: 'Send us your configuration with this form. We will contact you with a list of the costs and an expose for your requested apartment.<br><br>You can open the configuration at any time.',
    additionalInfo: 'Additional information',
    title: 'Title',
    salutation: 'Salutation',
    firstName: 'Firstname',
    lastName: 'Lastname',
    eMail: 'Email',
    birthday: 'Birthday',
    street: 'Street / Housenumber',
    zip: 'Zip',
    city: 'City',
    searchingFromDate: 'From what date on are you looking for an apartment?',
    comment: 'Comment',
    whyInterested: 'Why are you interested in this apartment?',
    phone: 'Phone no.',
    phoneMobile: 'Mobile no.',
    password: 'Password',
    addPartner: 'Add partner',
    movingInCount: 'Count persons moving in',
    movingInCountChildren: 'Count children',
    requestReservation: 'Request reservation',
    requestAppointment: 'Request appointment',
    hasFunding: 'Financing available',
    incomeDisclaimer: 'Hiermit bestätige/n ich/wir, dass das Haushaltsnettoeinkommen mindestens das Dreifache der Kaltmiete beträgt.',
    wbs: 'WBS',
    dateOfIssue: 'date of issue',
    wbsRooms: 'for how many rooms',
    wbsPersons: 'for how many persons',
    hrefCustomerLabel: projectConfig.hrefCustomerLabel,
    dpp: 'You have read and accepted our <a href="{hrefCustomer}" target="_blank" rel="noopener">privacy policy</a> *',
    privacyDisclaimer: 'When you submit this form, your data will be used by us to process your request. You can find general privacy informations under <a href="{hrefCustomer}" target="_blank" rel="noopener">{hrefCustomerLabel}</a>. Additional information and details regarding the revocation of consent given in the form and the implementation of data protection in the configurator can be found at <span class="privacy-link">privacy</span>.',
    submitError: 'There was an error sending your request for apartment <strong>{unitName}</strong>.<br> Please try again later.',
    submitSuccess: 'Your inquiry for apartment <strong>{unitName}</strong> was sent successfully.<br> Please check your emails.',
    submit: 'Submit',
  },
  validation: {
    missingGender: 'Please enter your title',
    missingFirstName: 'Please enter your first name',
    missingName: 'Please enter your last name',
    missingStreet: 'Bitte geben Sie hier Ihre Straße und Hausnummer an',
    missingZip: 'Please enter your ZIP code',
    missingCity: 'Please enter your city',
    missingPhone: 'Please enter your telephone number',
    missingEmail: 'Please enter your email address',
    wrongEmailFormat: 'The format of your email address is incorrect',
    missingPassword: 'Please enter your password',
    invalidBirthday: 'Invalid birthday',
    dpp: 'You must have read and accepted the privacy policy before submitting the form.',
    fieldRequired: 'Input required',
  },
  ui: {
    errorHeading: 'Error',
    errorMsg: {
      general: 'An error has occurred. Please try again later.',
      userNotFound: 'Unfortunately there is no user for this request.',
      unitNotFound: 'The selected building was not found.',
      configurationNotFound: 'The selected configuration was not found.',
      emailTaken: 'A user account with this email address already exists.',
      unauthorized: 'The given login data are incorrect.',
      invalidDeeplink: 'The requested link is invalid.',
      configurationLoginRequiredError: 'Please log in to view the configuration.',
      inputInvalid: 'An error occurred. Please check your inputs and try again.',
    },
    buttons: {
      requestBtnTextSingle: 'Send inquiry',
      requestBtnTextMultiple: '( {count} ) inquiries',
      changeView: 'Change view',
      showList: 'List',
      showBuilding: 'Building',
      pdfButtonText: 'PDF floorplan',
      walkthroughText: 'Walkthrough Sample Apartment',
      turnBuilding: 'Rotate Building',
    },
    unitDetails: {
      title: 'Apartment details',
      unitPrice: 'ca. {price} €',
      saveAsFavoriteBtnText: 'Add to favorites',
      configureUnitBtnText: 'Select equipment',
      configureUnitBtnTextShort: 'Furnishing',
      floorRange: '{floorStart} to {floorEnd}',
    },
    startFilter: {
      copy: 'Find your individual dream apartment:',
      subcopy: 'For what number of rooms or <br> area size are you looking for?',
    },
    inquiryModal: {
      title: 'Expose request',
      titleRent: 'Rent request',
      greeting: 'We would like to help you personally.',
      greetingRent: 'We are looking forward to your inquiry.',
      subcopy: 'If you are interested in this apartment or want to request its price, please use our contact form. We are looking forward to a consultation.',
      subcopyRent: 'If you are interested in this apartment or want to request its renting price, please use our contact form.',
      subcopyRentNoPrice: 'If you are interested in this apartment, please use our contact form.',
      selectedUnit: 'No Apartment selected | Selected Apartment: | Selected Apartments:',
    },
    sideMenu: {
      toggleTitle: 'Click to open or close',
    },
    notificationModal: {
      header: 'Information',
      btnText: 'Understood',
    },
    walkthroughModal: {
      title: 'Walkthrough Sample Apartment',
    },
  },
  // fields from the property API to translate
  // the dot character '.' has been replaced with an underscore '_'
  // Use to generate object:
  // JSON.stringify(Object.values(temp1).filter(x => x.length > 0).flatMap(arr => arr.map(element => element.name)).filter(name => name != null).reduce((o, key) => ({...o, [key.replace(/[.]/g, '_').replace(/[^a-zA-Z0-9_]/g, '')]: null}), {}))
  property: {
    'LotharBucherStr': 'Lothar-Bucher-Str',
    'EG': 'GF',
    '1_OG': '1F',
    '2_OG': '2F',
    '3_OG': '3F',
    '4_OG': '4F',
    'Balkon': 'Balcony',
    'Bauabschnit1': 'Section 1',
    'Gebude': 'Building',
    'Etage': 'Floor',
    'Wohnungstype': 'Unit type',
    'Bauabschnitte': 'Sections',
    'Verkaufsattribut': 'Unique Selling Point',
    'WE02': null,
    'WE03': null,
    'WE04': null,
    'WE05': null,
    'WE06': null,
    'WE07': null,
    'WE08': null,
    'WE09': null,
    'WE10': null,
    'WE11': null,
    'WE12': null,
    'WE13': null,
    'WE14': null,
    'WE15': null,
    'WE16': null,
    'WE17': null,
    'WE18': null,
    'WE19': null,
    'WE20': null,
    'WE21': null,
    'WE22': null,
    'WE23': null,
    'WE24': null,
    'WE25': null,
    'WE26': null,
    'WE27': null,
    'WE28': null,
    'WE29': null,
    'WE30': null,
    'WE31': null,
    'WE32': null,
    'WE33': null,
    'WE34': null,
    'WE35': null,
    'WE36': null,
    'WE37': null,
    'WE38': null,
    'WE39': null,
    'WE40': null,
    'WE41': null,
    'WE42': null,
    'WE43': null,
    'WE44': null,
    'WE45': null,
    'WE46': null,
    'WE47': null,
    'WE48': null,
    'WE49': null,
    'WE50': null,
    'WE51': null,
    'WE52': null,
    'WE53': null,
    'WE54': null,
    'WE55': null,
    'WE56': null,
    'WE57': null,
    'WE58': null,
    'Etagenwohnung': 'Apartment',
    'Erdgeschosswohnung': 'Ground level apartment',
    'Hochparterrewohnung': 'Mezzanine floor apartment',
    'Dachgeschosswohnung': 'Top floor apartment',
  },
};
