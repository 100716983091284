<template lang="pug">
  .cp-unit-filter(:class="sideMenuClasses")
    .wrapper-style
      button.filter-open-button(@click="$_onSideMenuToggleClick" :title="$t('ui.sideMenu.toggleTitle')")
        CpFilterHeader.filter-header
          template(v-slot:leading)
            CfIcon(
              id="tune"
              color="white"
            )
          template(v-slot:trailing)
            span.header-text-content {{$t('navigator.filter.heading')}}
          template(v-slot:header-action)
            transition(name="fade" appear)
              button.filter-reset(
                v-if="allDisplayedUnitsVisible === false"
                :title="$t('navigator.filter.reset')"
                @click.stop="$_onResetFilterClick"
              )
                CfMdiIcon(
                  size="xs"
                  color="white"
                  id="mdi-restore"
                )
          template(v-slot:close)
            transition(name="fade" mode="out-in")
              .close-btn-wrapper
                template(v-if="isSideMenuOpen")
                  .close-btn(:title="$t('general.close')")
                    CfIcon(id="close" color="white" size="xs")
                template(v-else-if="!isSideMenuOpen && !matcher.matching.includes('tablet-landscape')")
                  CfMdiIcon.up-arrow(
                    id="mdi-arrow-up"
                    size="xs"
                    color="white"
                  )
      CfScrollbar.filters-wrapper(
        @click="$_onFilterClosedClick($event)"
        :class="{'clickable': !isSideMenuOpen}"
      )
        template(v-for="filterDefinition of getAttributeFilterDefinitionValues")
          CpFilterContainer.filter(:is-menu-open="isSideMenuOpen" @click:leading-wrapper="$_onIconClick")
            template(#heading)
              | {{filterDefinition.unitAttributeGroup.getMeta('name')}}
            template(#label)
              CpUnitAttributeGroupFilterLabel(
                :filter-type="filterDefinition.unitAttributeGroup.filterType"
                :filter-value="getAttributeFilterValue(filterDefinition.unitAttributeGroup.guid)"
                :logical-label-provider="() => getLogicalLabel(filterDefinition.unitAttributeGroup.guid)"
                :number-formatter="value => getFormattedNumber(filterDefinition, value)"
              )
            template(v-slot:icon)
              CfIcon(:id="getFilterIcon(filterDefinition.unitAttributeGroup)")
            template(v-if="filterDefinition.unitAttributeGroup.filterType === $options.FILTER_TYPE.LOGICAL")
              .radio-wrapper
                CpFilterRadio(
                  :value="isLogicalAttributeFilterEmpty(filterDefinition.unitAttributeGroup.guid)"
                  :label="$t('navigator.filter.all')"
                  @click="$_onFilterRadioAllClick(filterDefinition.unitAttributeGroup)"
                )
                template(v-for="unitAttribute of getUnitAttributesInUnitAttributeGroupOfDisplayedUnits(filterDefinition.unitAttributeGroup.guid)")
                  CpFilterRadio(
                    :value="isLogicalAttributeFilterActive(filterDefinition.unitAttributeGroup.guid, unitAttribute.guid)"
                    :label="unitAttribute.getMeta('name')"
                    @click="$_onFilterRadioClick(filterDefinition.unitAttributeGroup, unitAttribute)"
                  )
            template(v-if="filterDefinition.unitAttributeGroup.filterType === $options.FILTER_TYPE.NUMERICAL_RANGE")
              CpRangeSlider(
                :value="getAttributeFilterValue(filterDefinition.unitAttributeGroup.guid)"
                @input="$_onFilterRangeInput(filterDefinition, $event)"
                :min="filterDefinition.min"
                :max="filterDefinition.max"
                :text-marks="filterDefinition.unitAttributeGroup.filterTargetField === 'numberOfRooms'"
                :force-tooltip="filterDefinition.unitAttributeGroup.filterTargetField === 'areaSize'"
                :value-formatter="value => getFormattedNumber(filterDefinition, value)"
              )
</template>

<style lang="sass" scoped>
@import "../../styles/variables"
@import "~@inreal/flatyfind-ui/src/sass/components/unit-filter"
</style>

<script>
import {FILTER_TYPE, UNIT_ATTRIBUTE_GROUP_TYPE} from '@inreal/cockpit-library/src/js/enums';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import CfIcon from '@inreal/ui/src/vue/gfx/cf_icon';
import CfMdiIcon from '@inreal/ui/src/vue/gfx/cf_mdi_icon';
import {MediaMatcher} from '@inreal/core-library/src/js/css/media_matcher';
import {keyFormatMixin} from '@inreal/flatyfind-ui/src/js/mixins/key-format';
import CpFilterRadio from '@inreal/flatyfind-ui/src/vue/filters/CpFilterRadio';
import CpRangeSlider from '@inreal/flatyfind-ui/src/vue/filters/CpRangeSlider';
import CpFilterContainer from '@inreal/flatyfind-ui/src/vue/filters/CpFilterContainer';
import CpFilterHeader from '@inreal/flatyfind-ui/src/vue/filters/CpFilterHeader';
import CfScrollbar from '@inreal/ui/src/vue/container/cf_scrollbar';
import CpUnitAttributeGroupFilterLabel from '../../components/CpUnitAttributeGroupFilterLabel';
import variables from '../../styles/_export.sass';
import {trackFilterEvent, trackFilterEventReset} from '../../common/TrackingService';

export default {
  name: 'CpUnitFilter',
  components: {
    CpUnitAttributeGroupFilterLabel,
    CfIcon,
    CfMdiIcon,
    CpFilterRadio,
    CpFilterHeader,
    CpRangeSlider,
    CpFilterContainer,
    CfScrollbar,
  },
  mixins: [
    keyFormatMixin,
  ],
  props: {},
  FILTER_TYPE,
  data () {
    return {
      variables,
      eventCategory: 'filter',
      matcher: new MediaMatcher([
        {
          id: 'tablet-landscape',
          query: '(min-width: 1024px)',
        },
      ]),
      radio: false,
    };
  },
  computed: {
    ...mapGetters('inreal/unitFilter', [
      'allDisplayedUnitsVisible',
      'getUnitAttributesInUnitAttributeGroupOfDisplayedUnits',
      'getAttributeFilterDefinitionValues',
      'isLogicalAttributeFilterActive',
      'getAttributeFilterValue',
      'isLogicalAttributeFilterEmpty',
      'getActiveLogicalAttributeFilterLabel',
    ]),
    ...mapGetters('inreal/property', [
      'getUnitAttributeByGuid',
    ]),
    ...mapGetters([
      'isSideMenuOpen',
    ]),
    getLogicalLabel () {
      return unitAttributeGroupGuid => {
        if (this.isLogicalAttributeFilterEmpty(unitAttributeGroupGuid)) {
          return this.$t('navigator.filter.all');
        } else {
          return this.getActiveLogicalAttributeFilterLabel(unitAttributeGroupGuid, 'name', ', ');
        }
      };
    },
    getFormattedNumber () {
      return (filterDefinition, value) => {
        const filterTargetField = filterDefinition.unitAttributeGroup.filterTargetField;

        switch (filterTargetField) {
          case 'basePrice': {
            return this.$n(value, 'currencyNoFraction');
          }

          default: {
            return this.$n(value, 'integer');
          }
        }
      };
    },
    getFilterIcon () {
      return unitAttributeGroup => {
        const {type, filterTargetField} = unitAttributeGroup;

        switch (type) {
          case UNIT_ATTRIBUTE_GROUP_TYPE.FLOOR: {
            return 'floors';
          }
          case UNIT_ATTRIBUTE_GROUP_TYPE.BUILDING: {
            return 'building';
          }
        }

        switch (filterTargetField) {
          case 'areaSize': {
            return 'size';
          }
          case 'numberOfRooms': {
            return 'floor-plan';
          }
          case 'basePrice': {
            return 'euro';
          }
        }

        return '';
      };
    },
    sideMenuClasses () {
      return this.isSideMenuOpen === false ? 'closed' : '';
    },
  },
  watch: {},
  beforeDestroy () {
  },
  methods: {
    ...mapMutations([
      'setSideMenuOpenStatus',
    ]),
    ...mapActions('inreal/unitFilter', [
      'toggleLogicalAttributeFilter',
      'resetAttributeFilters',
      'resetAttributeFilter',
    ]),
    ...mapMutations('inreal/unitFilter', [
      'setAttributeFilterValue',
    ]),

    $_onFilterRangeInput (filterDefinition, value) {
      this.setAttributeFilterValue({unitAttributeGroupGuid: filterDefinition.unitAttributeGroup.guid, value});
      /**
       * Tracking: track filter event on range input
       */
      trackFilterEvent(filterDefinition.unitAttributeGroup, `${value[0]}-${value[1]}`, this.eventCategory);
    },
    $_onFilterRadioClick (unitAttributeGroup, unitAttribute) {
      const isToggle = this.toggleLogicalAttributeFilter({
        unitAttributeGroupGuid: unitAttributeGroup.guid,
        unitAttributeGuid: unitAttribute.guid,
      });
      /**
       * Tracking: track filter event on radio click if radio is selected
       */
      if (isToggle) {
        trackFilterEvent(unitAttributeGroup, unitAttribute.getMeta('name'), this.eventCategory);
      }
    },
    $_onFilterRadioAllClick (unitAttributeGroup) {
      if (this.isLogicalAttributeFilterEmpty(unitAttributeGroup.guid) === false) {
        this.resetAttributeFilter({unitAttributeGroupGuid: unitAttributeGroup.guid});
      }
    },
    $_onResetFilterClick () {
      this.resetAttributeFilters();
      /**
       * Tracking: track filter event reset
       */
      trackFilterEventReset('filter', this.eventCategory);
    },
    $_onFilterClosedClick ($event) {
      if (this.$el.contains($event.target) && !this.isSideMenuOpen) {
        this.$_onSideMenuToggleClick();
      }
    },
    $_onSideMenuToggleClick () {
      this.setSideMenuOpenStatus(!this.isSideMenuOpen);
    },
    $_onIconClick () {
      this.$_onSideMenuToggleClick();
    },
  },
};
</script>
