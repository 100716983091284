import {UNIT_ATTRIBUTE_GROUP_TYPE} from '@inreal/cockpit-library/src/js/enums';
import {
  setCustomDimensions,
  setLanguageDimension,
  trackCustomUrl,
  trackFilter,
  trackMedia,
  trackUnit,
} from '@inreal/flatyfind-ui/src/js/tracking';
import store from '../store';


/**
 * Tracking: pushes lead event to dataLayer for TagBasedTrigger in Piwik Tag Manager
 */
export function pushLeadToDataLayer () {
  if (window.dataLayer) {
    window.dataLayer.push({'event': 'lead'});
  }
}

/**
 * Tracking: helper function to get event name based on unitAttributeGroup
 * @param {Object} unitAttributeGroup - The unitAttributeGroup Object.
 * @returns {string} eventName - The trackingEventName value.
 */
export function getTrackingEventNameByAttributeGroup (unitAttributeGroup) {
  let eventName = null;
  switch (unitAttributeGroup.type) {
    case UNIT_ATTRIBUTE_GROUP_TYPE.BASE_PRICE:
      eventName = 'price';
      break;
    case UNIT_ATTRIBUTE_GROUP_TYPE.AREA_SIZE:
      eventName = 'size';
      break;
    case UNIT_ATTRIBUTE_GROUP_TYPE.NUMBER_OF_ROOMS:
      eventName = 'rooms';
      break;
    default:
      eventName = unitAttributeGroup.type.toLowerCase();
  }
  // if UNIT_ATTRIBUTE_GROUP_TYPE is set to GENERAL (deprecated)
  if (unitAttributeGroup.type === UNIT_ATTRIBUTE_GROUP_TYPE.GENERAL) {
    switch (unitAttributeGroup.filterTargetField) {
      case 'basePrice':
        eventName = 'price';
        break;
      case 'areaSize':
        eventName = 'size';
        break;
      case 'numberOfRooms':
        eventName = 'rooms';
        break;
    }
  }
  return eventName;
}

/**
 * Tracking: set custom dimension by 'unit'
 * @param {Object} unit - The unit Object.
 */
export function setCustomDimensionsByUnit (unit) {
  console.log('setCustomDimensionsByUnit', unit);
  setCustomDimensions(
    unit.getMeta('name'),
    unit.basePrice,
    unit.getMeta('areaSize'),
    unit.getMeta('numberOfRooms'),
    store.getters['inreal/property/getUnitAttributesOfUnitByGroupType'](unit, UNIT_ATTRIBUTE_GROUP_TYPE.UNIT_TYPE)[0]?.getMeta('name'),
    store.getters['inreal/property/getUnitAttributesOfUnitByGroupType'](unit, UNIT_ATTRIBUTE_GROUP_TYPE.FLOOR)[0]?.getMeta('name'),
    store.getters['inreal/property/getUnitAttributesOfUnitByGroupType'](unit, UNIT_ATTRIBUTE_GROUP_TYPE.BUILDING)[0]?.getMeta('city'),
    unit.id,
    store.getters['inreal/property/getUnitAttributesOfUnitByGroupType'](unit, UNIT_ATTRIBUTE_GROUP_TYPE.SECTION)[0]?.getMeta('name'),
    store,
  );
}

/**
 * Tracking: set custom dimension language
 * @param {string} locale - The Language Locale.
 */
export function setCustomDimensionLanguage (locale) {
  setLanguageDimension(locale, store);
}

/**
 * Tracking: track custom url
 * @param {string} path - The unit Object.
 */
export function trackCustomUrlByPath (path) {
  trackCustomUrl(path, document.title, store);
}

/**
 * Tracking: track filter based on unitAttributeGroup and selected value
 * @param {Object} unitAttributeGroup - The unitAttributeGroup Object.
 * @param {string} eventValue - The event value.
 * @param {string} eventCategory - The event category (filter, filter-confirmed).
 */
export function trackFilterEvent (unitAttributeGroup, eventValue, eventCategory) {
  const eventAction = getTrackingEventNameByAttributeGroup(unitAttributeGroup);
  trackFilter(eventCategory, eventAction, eventValue, store);
}

/**
 * Tracking: track event reset
 * @param {string} eventValue - The event value.
 * @param {string} eventCategory - The event category (filter, filter-confirmed).
 */
export function trackFilterEventReset (eventValue, eventCategory) {
  const eventAction = 'reset';
  trackFilter(eventCategory, eventAction, eventValue, store);
}

/**
 * Tracking: set custom dimension to unit track event category 'unit' action 'select'
 * @param {Object} unit - The unit Object.
 * @param {boolean} fromSvg - if unit is selected from svg
 */
export function trackUnitBySelect (unit, fromSvg) {
  if (fromSvg) {
    trackUnitByAction(unit, 'select_overview');
  } else {
    trackUnitByAction(unit, 'select_list');
  }
}

/**
 * Tracking: set custom dimension and track event category 'unit'
 * @param {Object} unit - The unit Object.
 * @param {string} action - The action name.
 */
export function trackUnitByAction (unit, action) {
  setCustomDimensionsByUnit(unit);
  trackUnit(action, store);
}

/**
 * Tracking: track floorplan as media
 * @param {Object} unit - The unit Object.
 */
export function trackFloorplan (unit) {
  trackMedia('floorplan', `${unit.getMeta('name')}.pdf`, store);
}

/**
 * Tracking: track iFrame as media
 * @param {Object} unit - The unit Object.
 */
export function trackIframe (unit) {
  trackMedia('iframe', unit.getMeta('name'), store);
}


