<template lang="pug">
  CfScrollbar.cp-start-filter
    .greeting
      .copy(v-html="$t('ui.startFilter.copy')")
      .subcopy(v-html="$t('ui.startFilter.subcopy')")
    .filters
      template(v-for="filterDefinition of getStartAttributeFilterDefinitionValues")
        CpFilterContainer.filter
          template(#heading)
            | {{filterDefinition.unitAttributeGroup.getMeta('name')}}
          template(#label)
            CpUnitAttributeGroupFilterLabel(
              :filter-type="filterDefinition.unitAttributeGroup.filterType"
              :filter-value="getAttributeFilterValue(filterDefinition.unitAttributeGroup.guid)"
              :logical-label-provider="() => getLogicalLabel(filterDefinition.unitAttributeGroup.guid)"
              :number-formatter="value => getFormattedNumber(filterDefinition, value)"
            )
          template(#icon)
            CfIcon(:id="getFilterIcon(filterDefinition.unitAttributeGroup)")
          template(v-if="filterDefinition.unitAttributeGroup.filterType === $options.FILTER_TYPE.LOGICAL")
            .radio-wrapper
              CpFilterRadio(
                :value="isLogicalAttributeFilterEmpty(filterDefinition.unitAttributeGroup.guid)"
                :label="$t('navigator.filter.all')"
                @click="$_onFilterRadioAllClick(filterDefinition.unitAttributeGroup)"
              )
              template(v-for="unitAttribute of getUnitAttributesInUnitAttributeGroupOfDisplayedUnits(filterDefinition.unitAttributeGroup.guid)")
                CpFilterRadio(
                  :value="isLogicalAttributeFilterActive(filterDefinition.unitAttributeGroup.guid, unitAttribute.guid)"
                  :label="unitAttribute.getMeta('name')"
                  @click="$_onFilterRadioClick(filterDefinition.unitAttributeGroup, unitAttribute)"
                )
          template(v-if="filterDefinition.unitAttributeGroup.filterType === $options.FILTER_TYPE.NUMERICAL_RANGE")
            CpRangeSlider(
              :value="getAttributeFilterValue(filterDefinition.unitAttributeGroup.guid)"
              @input="setAttributeFilterValue({unitAttributeGroupGuid: filterDefinition.unitAttributeGroup.guid, value: $event})"
              :min="filterDefinition.min"
              :max="filterDefinition.max"
              :text-marks="filterDefinition.unitAttributeGroup.filterTargetField === 'numberOfRooms'"
              :force-tooltip="filterDefinition.unitAttributeGroup.filterTargetField === 'areaSize'"
              :value-formatter="value => getFormattedNumber(filterDefinition, value)"
            )
    .actions
      CpButtonAction.button(
        :label="$t('general.start')"
        @click.native="$_onButtonClick"
      )
</template>

<style lang="sass" scoped>
@import "../styles/variables"
@import '~@inreal/flatyfind-ui/src/sass/variables'
@import '~@inreal/flatyfind-ui/src/sass/components/start-filter'
</style>

<script>
import {FILTER_TYPE, UNIT_ATTRIBUTE_GROUP_TYPE} from '@inreal/cockpit-library/src/js/enums';
import {mapGetters, mapMutations, mapActions} from 'vuex';
import CfIcon from '@inreal/ui/src/vue/gfx/cf_icon';
import {MediaMatcher} from '@inreal/core-library/src/js/css/media_matcher';
import CfScrollbar from '@inreal/ui/src/vue/container/cf_scrollbar';
import CpButtonAction from '@inreal/flatyfind-ui/src/vue/buttons/CpButtonAction';
import CpFilterContainer from '@inreal/flatyfind-ui/src/vue/filters/CpFilterContainer';
import CpRangeSlider from '@inreal/flatyfind-ui/src/vue/filters/CpRangeSlider';
import CpFilterRadio from '@inreal/flatyfind-ui/src/vue/filters/CpFilterRadio';
import CpUnitAttributeGroupFilterLabel from '../components/CpUnitAttributeGroupFilterLabel';
import {updatePageTitle} from '../lang';
import {trackCustomUrlByPath, trackFilterEvent} from '../common/TrackingService';

export default {
  name: 'CpStartFilter',
  components: {
    CpUnitAttributeGroupFilterLabel,
    CpFilterRadio,
    CfScrollbar,
    CfIcon,
    CpButtonAction,
    CpRangeSlider,
    CpFilterContainer,
  },
  props: {},
  FILTER_TYPE,
  data () {
    return {
      eventCategory: 'filter-confirmed',
      matcher: new MediaMatcher([
        {
          id: 'tablet',
          query: '(min-width: 768px)',
        },
      ]),
    };
  },
  computed: {
    ...mapGetters('inreal/unitFilter', [
      'getStartAttributeFilterDefinitionValues',
      'getUnitAttributesInUnitAttributeGroupOfDisplayedUnits',
      'isLogicalAttributeFilterActive',
      'getAttributeFilterValue',
      'isLogicalAttributeFilterEmpty',
      'getActiveLogicalAttributeFilterLabel',
      'getAttributeFilterValues',
    ]),
    ...mapGetters('inreal/property', [
      'getUnitAttributeGroupByGuid',
      'getUnitAttributeByGuid',
    ]),
    isPersistent () {
      return true;
    },
    getLogicalLabel () {
      return unitAttributeGroupGuid => {
        if (this.isLogicalAttributeFilterEmpty(unitAttributeGroupGuid)) {
          return this.$t('navigator.filter.all');
        } else {
          return this.getActiveLogicalAttributeFilterLabel(unitAttributeGroupGuid, 'name', ', ');
        }
      };
    },
    getFormattedNumber () {
      return (filterDefinition, value) => {
        const filterTargetField = filterDefinition.unitAttributeGroup.filterTargetField;

        switch (filterTargetField) {
          case 'basePrice': {
            return this.$n(value, 'currencyNoFraction');
          }

          default: {
            return this.$n(value, 'integer');
          }
        }
      };
    },
    getFilterIcon () {
      return unitAttributeGroup => {
        const {type, filterTargetField} = unitAttributeGroup;

        switch (type) {
          case UNIT_ATTRIBUTE_GROUP_TYPE.BUILDING: {
            return 'building';
          }
          case UNIT_ATTRIBUTE_GROUP_TYPE.FLOOR: {
            return 'floors';
          }
        }

        switch (filterTargetField) {
          case 'areaSize': {
            return 'size';
          }
          case 'numberOfRooms': {
            return 'floor-plan';
          }
          case 'basePrice': {
            return 'euro';
          }
        }

        return '';
      };
    },
  },
  watch: {},
  created () {
    updatePageTitle('filter');
  },
  beforeMount () {
  },
  beforeDestroy () {
  },
  methods: {
    ...mapMutations('inreal/unitFilter', [
      'setAttributeFilterValue',
    ]),
    ...mapActions('inreal/unitFilter', [
      'toggleLogicalAttributeFilter',
      'resetAttributeFilter',
    ]),
    $_onFilterRadioClick (unitAttributeGroup, unitAttribute) {
      this.toggleLogicalAttributeFilter({
        unitAttributeGroupGuid: unitAttributeGroup.guid,
        unitAttributeGuid: unitAttribute.guid,
      });
    },
    $_onFilterRadioAllClick (unitAttributeGroup) {
      if (this.isLogicalAttributeFilterEmpty(unitAttributeGroup.guid) === false) {
        this.resetAttributeFilter({unitAttributeGroupGuid: unitAttributeGroup.guid});
      }
    },
    $_onButtonClick () {
      /**
       * Tracking: track event category filter-confirmed
       */
      this.getStartAttributeFilterDefinitionValues.forEach(def => {
        const filterValues = this.getAttributeFilterValues[def.unitAttributeGroup.guid];

        // Get logical values
        if (def.unitAttributeGroup.filterType === FILTER_TYPE.LOGICAL) {
          if (filterValues.length > 0) {
            filterValues.forEach(filterValue => {
              let eventValue = this.getUnitAttributeByGuid(filterValue).getMeta('name');
              trackFilterEvent(def.unitAttributeGroup, eventValue, this.eventCategory);
            });
          }
        }

        // Get range values
        if (def.unitAttributeGroup.filterType === FILTER_TYPE.NUMERICAL_RANGE) {
          let eventValue = `${filterValues[0]}-${filterValues[1]}`;
          trackFilterEvent(def.unitAttributeGroup, eventValue, this.eventCategory);
        }

        // TO DO FILTER_TYPE.NUMERICAL

      });

      updatePageTitle('unitselection');
      /**
       * Tracking: track custom url as page view
       */
      trackCustomUrlByPath(this.$route.path);
      this.$emit('close');
    },
  },
};
</script>
