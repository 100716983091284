<template lang="pug">
  CpModalBase.cp-logic-variant-details(
    @close="$emit('close')"
    closeable
    scrollable
    :close-label="closeLabel"
  )
    template(#header)
      | {{headerTitle}}
    template(#content)
      .details
        .image
          CfAspectRatio.ratio(:ratio="1")
            CfImageLoader(
              :src="imageSrc"
            )
        .description
          .title
            h2 {{getVariantOption}}
            h1 {{getVariantName}}
          .copy(v-html="getVariantDescription")
          .price(v-html="getVariantPrice")

</template>

<style lang="sass" scoped>
@import '../styles/variables'

.cp-logic-variant-details
  max-width: initial
  max-height: 100%
  height: auto

  @media (min-width: $size-tablet)
    width: $infobox-width--tablet

  @media (min-width: $size-tablet-landscape)
    width: $infobox-width--tablet-landscape

  @media (min-width: $size-desktop)
    width: $infobox-width

  @media (min-width: $size-desktop-wide)
    width: $infobox-width--desktop-wide

  @media (min-width: $size-desktop-4k)
    width: $infobox-width--desktop-4k

  .details
    display: flex
    flex-direction: row
    .image
      padding: 32px
      width: 450px
    .description
      padding-top: 68px
      padding-left: 32px
      flex: 1
      h1, h2
        font-weight: 300
        text-transform: uppercase
        margin: 0
        padding: 0
        margin-bottom: 16px
      h1
        font-size: 21px
      h2
        font-size: 16px
        margin-bottom: 8px
      .copy, .title
        max-width: 80%

</style>

<script>
import {mapGetters} from 'vuex';
import CfIcon from '@inreal/ui/src/vue/gfx/cf_icon';
import CfImageLoader from '@inreal/ui/src/vue/loader/cf_image_loader';
import CfAspectRatio from '@inreal/ui/src/vue/container/cf_aspect_ratio';
import CpModalBase from '@inreal/flatyfind-ui/src/vue/modals/CpModalBase';

export default {
  name: 'CpLogicVariantDetails',
  components: {
    CfIcon,
    CfAspectRatio,
    CfImageLoader,
    CpModalBase,
  },
  props: {
    variant: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
    };
  },
  computed: {
    ...mapGetters('inreal/property', [
      'getVariantByGuid',
      'getVariantImageUrl',
      'getOptionByGuid',
    ]),
    ...mapGetters('inreal/prices', [
      'getPricesOfVariants',
      'getPriceValueOfVariant',
      'getPriceFormattedOfVariant',
    ]),
    getVariantName () {
      return this.variant?.getMeta('name');
    },
    getVariantOption () {
      return this.variant ? this.getOptionByGuid(this.variant.optionGuid)?.getMeta('name') : '';
    },
    getVariantDescription () {
      return this.variant?.getMeta('description') ? this.variant.getMeta('description') : '';
    },
    getVariantPrice () {
      return this.getPriceFormattedOfVariant(this.variant?.guid, this.$_formatPrice, 'default');
    },
    closeLabel () {
      return this.$t('general.close');
    },
    headerTitle () {
      return this.$t('configurator.variantDetails');
    },
    imageSrc () {
      return this.getVariantImageUrl(this.variant.guid);
    },
  },
  methods: {
    $_formatPrice (price) {
      return this.$n(price / 100, 'currency');
    },
  },
};
</script>
