import {
  InquiryService,
} from '../../common/ApiService';

export default {
  state: () => ({}),
  actions: {
    sendInquiry ({commit}, {data}) {
      // Inquiry Schema
      // https://dev.azure.com/Inreal-Web/cloud-schemas/_git/cloud-inquiry-schema?path=%2Fschema%2Fcloud-inquiry-schema.json

      // example request as json
      // https://dev.azure.com/Inreal-Web/cloud-schemas/_git/cloud-inquiry-schema?path=%2Fschema%2Fcloud-inquiry-schema.example.json
      // payload shoud look like this
      // many fields are optional
      const payload = {
        salutation: data.salutation,
        // title: null,
        firstname: data.firstname,
        lastname: data.lastname,
        street: data.street,
        zip: data.zip,
        city: data.city,
        // state: null,
        // country: null,
        phone: data.phone,
        email: data.email,
        message: data.message,
        dpp: data.dpp,
        // requestReservation: null,
        // requestAppointment: null,
        // hasFunding: null,
        locale: data.locale,
        inquiryItems: data.inquiryItems,
      };

      return InquiryService.sendInquiry(payload);
    },
  },
  mutations: {},
  getters: {},
};
