import Vue from 'vue';
import Vuex from 'vuex';

import appuser from './modules/appuser.module';
import api from './modules/api.module';
import page from './modules/page.module';
import tracking from './modules/tracking.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    page,
    api,
    tracking,
    appuser,
  },
});
