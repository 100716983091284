import {getUserAttribute, hasPiwikLoaded, setComplianceSettings, setTracker} from '@inreal/flatyfind-ui/src/js/tracking';
import store from '../index';

export default {
  state: () => ({
    initialConsent: 0,
    unitSelectedFromSvg: false,
  }),
  actions: {
    async initTracking ({commit, dispatch, getters, rootGetters, state, rootState}, timeout = 5000) {
      const isLoaded = await hasPiwikLoaded(timeout, store);
      if (isLoaded) {
        dispatch('setDefaultTrackingValues');
      }
    },
    async setDefaultTrackingValues ({state}) {
      if (state.initialConsent === 1) {
        await setComplianceSettings(store);
      }
      await setTracker(store);
      await getUserAttribute(store);
    },
  },
  mutations: {
    setInitialConsent (state, value) {
      state.initialConsent = value;
    },
    setUnitSelectedFromSvg (state, status) {
      state.unitSelectedFromSvg = status == null ? false : status;
    },
  },
  getters: {
    getUnitSelectedFromSvg (state) {
      return state.unitSelectedFromSvg;
    },
  },
};

