import {
  getApi,
  getComplianceSettings,
  getComplianceTypes,
  getNewComplianceTypes,
  setComplianceSettings,
  setInitialComplianceSettings,
} from './index';

/**
 * Fetches all consent options that have been given, not given, or that have not been decided upon.
 * Converts Piwik API status types (-1, 0, 1) to null, false, and true, respectively.
 * @returns {Promise<Array<{type: string, status: (boolean|null)}>>}
 */
export async function getConsentOptions () {
  const api = await getApi();
  const [settingsResponse, typesResponse] = await Promise.all([
    getComplianceSettings(api),
    getComplianceTypes(api),
  ]);
  const consents = settingsResponse.consents ?? {};
  const allTypes = typesResponse ?? [];
  return allTypes.map(v => {
    const status = consents[v]?.status > -1 ? Boolean(consents[v].status) : null;
    return {type: v, status};
  });
}

/**
 * Sends consent options.
 * @param {Array<{type: string, status: boolean}>} settings
 * @returns {Promise<*>}
 */
export async function setConsentOptions (settings) {
  const api = await getApi();
  const consents = settings.reduce((memo, current) => {
    const status = Number(current.status);
    memo[current.type] = {status};
    return memo;
  }, {});
  return setComplianceSettings(api, {consents});
}

/**
 * Registers initial consent based on Piwik's `getNewComplianceTypes`.
 * Used when the user closes the consent form right away, without any further interaction.
 * @returns {Promise<*>}
 */
export async function registerInitialConsent () {
  const api = await getApi();
  const initialTypes = await getNewComplianceTypes(api);
  if (initialTypes.length === 0) {
    return;
  }
  return setInitialComplianceSettings(api, initialTypes);
}
