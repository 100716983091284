<template lang="pug">
  .cp-welcome
    .box.success(v-if="isValidGuid")
      .headline(v-html="getWelcomeHeadline")
      .copy(v-html="getWelcomeCopy")
      .list
        .item(v-for="(el, index) in getWelcomeToDos")
          span {{el}}
          span {{index + 1}}
      CpButtonAction(
        :label="getWelcomeActionLabel"
        @click.native="$_clickStart()"
        :show-slot="true"
      )
        include ../assets/img/long-arrow.svg
    .box(v-else)
      .headline(v-html="getWelcomeHeadline")
      .copy(v-html="getWelcomeCopy")

</template>

<style lang="sass" scoped>
@import "../styles/variables"

.cp-welcome
  display: flex
  padding-top: 32px

  .box
    margin: 0 auto
    @media (min-width: $size-desktop)
      position: absolute
      top: 50%
      left: 50%
      transform: translateX(-50%) translateY(-50%)
    max-width: 600px
    color: white
    border-top: 1px solid rgba(255, 255, 255, 0.25)
    padding-top: 21px
    text-align: right

  button
    display: inline-block

  .headline
    text-align: right
    font-size: 28px
    font-weight: 600
    text-transform: uppercase
  .copy
    text-align: right
    margin-top: 42px
    margin-bottom: 21px
    text-transform: uppercase
  .list
    margin-top: 42px
    margin-bottom: 42px
    .item
      display: flex
      flex-direction: row
      justify-content: flex-end
      margin-bottom: 16px
      span
        text-align: right
        padding-left: 12px
        &:nth-child(2)
          color: $color-brand
          font-weight: 600

</style>

<script>
import CpButtonAction from '@inreal/flatyfind-ui/src/vue/buttons/CpButtonAction';
import {ROUTE_NAMES} from '@inreal/flatyfind-ui/src/js/constants/routes';
import {mapGetters} from 'vuex';
import {updatePageTitle} from '../lang';

export default {
  name: 'CpWelcome',
  components: {
    CpButtonAction,
  },
  props: {
    guid: {
      type: String,
      default: '0',
    },
  },
  data () {
    return {
    };
  },
  computed: {
    ...mapGetters('inreal/auth', [
      'getUser',
    ]),
    ...mapGetters('inreal/property', [
      'getUnitByGuid',
    ]),
    isValidGuid () {
      return this.guid && this.guid !== '0';
    },
    getWelcomeHeadline () {
      return this.$t('welcome.title');
    },
    getWelcomeCopy () {
      if (this.getUser && this.isValidGuid) {
        const salutation = this.getUser.salutation;
        const customSalutation = this.getUser.customSalutation;
        const name = this.getUser.firstName ? `${this.getUser.firstname} ${this.getUser.lastname}` : this.getUser.lastname;
        const unit = this.getUnitByGuid(this.guid);
        const unitName = unit.getMeta('name');
        const projectName = unit.getMeta('project');
        if (customSalutation != null) {
          return this.$t('welcome.salutation', {salutation: customSalutation.replace(/(?:\r\n|\r|\n)/g, '<br>'), unitName, projectName});
        } else {
          if (salutation === 'Herr') {
            return this.$t('welcome.salutationMale', {name, unitName, projectName});
          } else if (salutation === 'Frau') {
            return this.$t('welcome.salutationFemale', {name, unitName, projectName});
          } else {
            return this.$t('welcome.salutation', {salutation, unitName, projectName});
          }
        }
      } else {
        return this.$t('welcome.error');
      }
    },
    getWelcomeToDos () {
      return this.$t('welcome.todos');
    },
    getWelcomeActionLabel () {
      return this.$t('welcome.start');
    },
  },
  watch: {},
  created () {
    updatePageTitle('welcome');
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    $_clickStart () {
      this.$router.push({name: ROUTE_NAMES.UNIT, params: {guid: this.guid}});
    },
  },
};
</script>
